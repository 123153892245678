/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from "react";
import AppBar from "../../components/AppBar/AppBar";
import { useEffect } from "react";
import { Flip } from "react-toastify";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import appstore from "../../assets/images/appstore.jpeg";
import playstore from '../../assets/images/googleplay.jpeg'
import cash from "../../assets/images/cash.jpeg";
import user2 from "../../assets/images/user2.svg";
import vs from "../../assets/images/vs.webp";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { createRoom } from "../../services/result.service";
import { useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import Loader from "../../components/Loader/Loader";
import { checkResult } from "../../services/challengeresult.service";
import PropTypes from 'prop-types';
import { useWallet } from "../../routes/AppRouter";

export default function BigChallengeResult({ socket }) {
  const [role, setRole] = useState("");
  const [roomcode, setRoomcode] = useState("");
  const [results, setResultAlready] = useState(false)
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingResult, setLoadingResult] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [prize, setPrize] = useState("");
  const [u_name, setUname] = useState("");
  const [o_name, setOname] = useState("");

  const [compressedFile, setCompressedFile] = useState(null);

  const { challenge_id } = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTagOption, setTagSelectedOption] = useState("");
  const data = useWallet()

  useEffect(() => {
    if (socket != undefined) {


      socket.emit("wallet:fetchWalletBalance", {
        type: "WALLET_BALANCE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),
        },
      });
    }
  }, [])

  const onFocus = () => {
    fetchResult()

    console.log("Tab is in focus");
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {

    console.log("Tab is blurred");
  };

  useEffect(() => {

    socket.emit("wallet:fetchWalletBalance", {
      type: "WALLET_BALANCE",
      params: "",
      data: {
        auth: localStorage.getItem("token"),
      },
    });
  }, [socket])
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    fetchResult()
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);




  const fetchResult = async () => {
    const response = await checkResult(challenge_id)

    if (response != null) {
      if (response.status == 200) {
        // setRoomcode(response.data.room_code == "0" ? "" : response.data.room_code)
        if (response.data.room_code != "0") {
          setReadOnly(true)
          setRoomcode(response.data.room_code)
        }
        if (response.data.result_uploaded == 1) {
          setResultAlready(true)
        }


      }
    }
  }


  useEffect(() => {
    try {
      socket.emit(`big:challenge:joinRoom`, {
        type: "CHALLENGE_REQUEST_DECLINE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),

          challenge_id: challenge_id,
        },
      });

      socket.emit(`big:role:fetchRole`, {
        type: "FETCH_ROLE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),

          challenge_id: challenge_id,
        },
      });

      socket.on(`big:challenge:receiveRoomCode`, (data) => {
        if (data !== null)
          setRoomcode(data.room_code);
      });

      socket.on(`big:challenge:sendMessage`, (data) => {
        if (data != null)
          notify(data);
      });
      socket.on(`big:role:readRole`, (data) => {

        if (data !== null) {
          setRole(data.role);
          setPrize(data.prize_money);
          setOname(data.o_name);
          setUname(data.u_name);

          if (data.o_name == null || data.o_name == undefined || data.o_name == "") {
            navigate(-1)
          }
        }

      });
    } catch (error) { /* empty */ }


  }, [socket, role, prize, o_name, u_name]);




  const navigate = useNavigate();
  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });
  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });

  const handleTagOptionChange = (event) => {
    setTagSelectedOption(event.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    let fileInfo = "File name: " + image.name + "\n";
    fileInfo += "File type: " + image.type + "\n";
    fileInfo += "File size: " + image.size + " bytes\n";
    fileInfo += "Last modified: " + image.lastModifiedDate;

    // Display the file properties in an alert dialog
    alert(fileInfo);
    setCompressedFile(image);
    // new Compressor(image, {
    //   quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
    //   success: (compressedResult) => {
    //     console.log(compressedResult);
    //     // compressedResult has the compressed file.
    //     // Use the compressed file to upload the images to your server.

    //     setCompressedFile(compressedResult);
    //   },
    // });
  };

  return (
    <>
      <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
        {/* nav bar */}
        <AppBar socket={socket} context={data}></AppBar>
      </div>
      <div className="p-3 mx-auto bg-white mt-[64px] h-full min-h-[100vh] ">
        <ToastContainer />

        {/* vs card */}

        <div className="border rounded my-2">
          <div className="p-4 ">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="bg-dark rounded-full mr-2 w-6 h-6 ">
                  <img src={user2} alt="" />
                </div>
                <span className="font-semibold">{u_name}</span>
              </div>
              <div>
                <img src={vs} alt="" className="w-8" />
              </div>
              <div className="flex flex-row-reverse items-center">
                <div className="bg-dark rounded-full ml-2 w-6 h-6 ">
                  <img src={user2} alt="" />
                </div>
                <span className="font-semibold">{o_name}</span>
              </div>
            </div>
            <div className="flex items-center justify-center pt-3">
              <span className="text-xl text-green-700 font-bold">
                Rs.{prize}
              </span>
            </div>
          </div>
        </div>

        <div className="border rounded  shadow-md text-center p-4 my-2">
          <p className="text-red-600 font-semibold text-[10px] mb-2">
            Important Notification
          </p>
          <p className="text-red-600 text-[10px] mb-2">
            रूम कोड केवल क्लासिक मोड में ही स्वीकार किया जाएगा।
          </p>
          <p></p>
          <p className="text-red-600 text-[10px] mb-2">
            लुडोकिंग पे जा के रूम कोड क्रिएट कर के निचे दिए ऑप्शन में डाले।
          </p>
          <p className="text-base font-semibold">Room Code</p>
          {loading ? (
            <center>
              <Loader></Loader>
            </center>
          ) : (
            <>
              {role == "creator" ? (
                <>
                  <input
                    type="text"
                    value={roomcode}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        setRoomcode(e.target.value);
                      }
                    }}
                    disabled={readOnly}
                    maxLength={8}
                    minLength={8}
                    className=" border border-e-gray-300 px-3 py-[6px] 
              rounded focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                  />

                  <div className="my-2">
                    {readOnly == true ? <><button
                      className=" mb-3 text-white px-4 py-2 rounded-md"
                      style={{ backgroundColor: "#157347" }}

                      // eslint-disable-next-line no-unused-vars
                      onClick={async (e) => {

                        notifySuccess("Room Code Copied")
                        await navigator.clipboard.writeText(roomcode);
                        return

                      }}
                    >

                      Copy Room Code

                    </button></> : <button
                      className=" mb-3 text-white px-4 py-2 rounded-md"
                      style={{ backgroundColor: "#157347" }}
                      disabled={readOnly}
                      // eslint-disable-next-line no-unused-vars
                      onClick={async (e) => {

                        if (roomcode.startsWith("0")) {
                          setLoading(true);
                          const data = {
                            challenge_id: challenge_id,
                            room_code: roomcode.toString(),
                          };

                          const result = await createRoom(data);
                          setLoading(false);

                          if (result.status == 401) {
                            localStorage.clear();
                            navigate("/login");
                          }
                          if (result.status != 200) {
                            notify(result.message);
                          }

                          if (result.status == 200) {
                            setReadOnly(true);
                            //notify(result.message);
                            socket.emit("challenge:sendRoomCode", result.data);
                          }
                        } else {
                          notify("Improper RoomCode Format");
                        }

                        // setReadOnly(true);
                      }}
                    >

                      Set Room Code

                    </button>}



                  </div>
                </>
              ) : (
                <>
                  <div className="my-2">
                    <p
                      className=" mb-3 text-white px-4 py-2 rounded-md"
                      style={{ backgroundColor: "#157347" }}
                    >
                      {roomcode == "" ? "Wating for Room Code   " : roomcode + "  "}
                      {roomcode != "" ? <span>
                        <i onClick={async () => {
                          notifySuccess("Refferal Code Copied")
                          await navigator.clipboard.writeText(roomcode);
                        }}
                          className="fa-regular fa-clipboard"
                          aria-hidden="true"
                        />
                      </span> : <></>}

                    </p>

                  </div>
                </>
              )}
            </>
          )}

          <p className="font-medium">Play Ludo Game Ludo King App</p>
          <div className="flex items-center justify-center gap-2 my-2">
            <img src={appstore} alt="" style={{ width: "30%" }} />
            <img src={playstore} alt="" style={{ width: "30%" }} />
          </div>
        </div>

        <div className="shadow-md border rounded-md mb-3">
          <div className="text-center border-b-2 px-4 py-2">Game Rules</div>
          <div className="p-4">
            <div>
              <div className="flex items-center  px-2">
                <div className="flex flex-col  ">
                  <p className="my-1">
                    Rooms codes will be accepted in classic mode only.
                  </p>
                  <p className="my-1">Record every game while playing</p>
                  <p className="my-1">
                    For cancellation of game, video proof is necessary.
                  </p>
                  <p className="text-red-600 font-semibold my-1">
                    Popular Room code के गेम play नही करने ह यदि कोई popular
                    room code का game play के loss ho jata h तो वो उसकी अपनी
                    गलती होगी उस game me हम कोई मदद नहीं कर सकते है।
                  </p>
                  <p className="text-red-600 font-semibold my-1">
                    Important:- popular Room code देने वाले usar की id 00 कर दी
                    जाएगी।
                  </p>
                  <p className="text-red-600 font-semibold my-1">
                    कोई भी गेम में रिजल्ट सावधानी से डाले गलत रिजल्ट डालने पर
                    गेम किलिर हो जायेगा तो उसकी जिमेदारी हमारी नही होगी।
                  </p>
                  <div className="flex items-center gap-2 my-1">
                    <img src={cash} alt="" className="w-6" />
                    <p>50 Penalty will be charged for updating wrong result.</p>
                  </div>
                  <div className="flex items-center gap-2 my-1">
                    <img src={cash} alt="" className="w-6" />
                    <p>25 Penalty will be charged for not updating result.</p>
                  </div>
                  <p className="text-red-600 font-normal my-1">
                    कृपया कैंसिल करने से पहले रूम से गेम लेफ्ट जरूर हो जाये
                    अन्थया ऑटो प्ले होकर, आप गेम लोस्स हो सकते है। उसमे एडमिन की
                    कोई जिम्मेदारी नही होगी।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {results ? <><p className="my-2">
          Result is declared or Uploaded by you
        </p></> : <> <div className="shadow-md border rounded-md mb-3">
          <div className="text-center border-b-2 px-4 py-2">Match Status</div>
          <div className="p-4">
            <div>
              <div className="flex items-center  px-2">
                {loadingResult ? <center><Loader></Loader></center> : <div className="flex flex-col  ">
                  <p className="my-2">
                    After completetion of your game , select the status of the
                    game and post your screenshot below.
                  </p>
                  <div>
                    <div className="flex justify-start gap-2 my-2">
                      <input
                        type="radio"
                        id="won"
                        name="result"
                        value={"won"}
                        onChange={handleOptionChange}
                        checked={selectedOption === "won"}
                        data-gtm-form-interact-field-id={1}
                      />
                      <label htmlFor="won">I Won</label>
                      <br />
                      <input
                        type="radio"
                        name="result"
                        value={"lost"}
                        onChange={handleOptionChange}
                        checked={selectedOption === "lost"}
                        data-gtm-form-interact-field-id={1}
                      />
                      <label htmlFor="lost">I Lost</label>
                      <br />
                      <input
                        type="radio"
                        id="cancel"
                        name="result"
                        value={"cancel"}
                        onChange={handleOptionChange}
                        checked={selectedOption === "cancel"}
                        data-gtm-form-interact-field-id={1}
                      />
                      <label htmlFor="cancel">Cancel</label>
                    </div>
                    <div
                      id="uploadDiv"
                      style={{
                        display: selectedOption == "won" ? "block" : "none",
                      }}
                      className="my-2"
                    >
                      <label htmlFor="file" hidden="">
                        Upload Image:
                      </label>
                      <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={(e) => handleCompressedUpload(e)}
                      />
                    </div>
                    <div
                      id="reasondiv"
                      className="my-2"
                      style={{
                        display: selectedOption == "cancel" ? "block" : "none",
                      }}
                    >
                      {/* <label for="reason">Please Select Any Reason</label> */}
                      <select
                        name="reason"
                        id="reason"
                        className="border"
                        value={selectedTagOption}
                        onChange={handleTagOptionChange}
                      >
                        <option value="Please Select Any Reason">
                          Please Select Any Reason
                        </option>
                        <option value="No Room Code">No Room Code</option>
                        <option value="Room Code in Popular Mode">
                          Room Code in Popular Mode
                        </option>
                        <option value="Game Not Started">
                          Game Not Started
                        </option>
                        <option value="Not Joined">Not Joined</option>
                        <option value="Not Playing">Not Playing</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <button
                      className=" mb-3 text-white px-4 py-1 rounded-md my-2"
                      style={{ backgroundColor: "#157347" }}
                      // eslint-disable-next-line no-unused-vars
                      onClick={async (e) => {
                        setLoadingResult(true);
                        const authToken = localStorage.getItem("token");

                        var formData = null;
                        if (selectedOption == "cancel") {

                          setCompressedFile(null);
                          if (selectedTagOption == "Please Select Any Reason") {
                            notify("Please Select Any Reason");
                            setLoadingResult(false);
                            return;
                          }

                          formData = new FormData();

                          formData.append("challenge_id", challenge_id);
                          formData.append("response", selectedOption);
                          formData.append("reason", selectedTagOption);

                        } else if (selectedOption == "lost") {
                          setCompressedFile(null);

                          formData = new FormData();
                          formData.append("challenge_id", challenge_id);
                          formData.append("response", selectedOption);

                        } else if (selectedOption == "won") {
                          formData = new FormData();
                          formData.append("challenge_id", challenge_id);
                          formData.append("response", selectedOption);
                          if (compressedFile == null) {
                            notify("Please Select Image if your is Won")
                            setLoadingResult(false);
                            return
                          }
                          formData.append("file", compressedFile);
                        }

                        try {
                          if (selectedOption == "won" && compressedFile == null) {
                            notify("Please Select Image if your is Won")
                            setLoadingResult(false);
                            return
                          }
                          console.log(formData)

                          const response = await fetch(
                            // eslint-disable-next-line no-undef
                            `${process.env.REACT_APP_CHALLENGE_CHECK_API}`,
                            {
                              method: "POST",
                              headers: {
                                Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
                              },
                              body: formData,
                            }
                          );
                          if (response.status == 200) {
                            socket.emit("view:removeChallenge", {
                              type: "REMOVE_CHALLENGE_VIEW",
                              params: "",
                              data: {
                                auth: localStorage.getItem("token"),
                                challenge_id: challenge_id
                              },
                            });
                            setReadOnly(true)
                          }
                          console.log(formData.get('file'))
                          let fileInfo = "File name: " + formData.get('file').name + "\n";
                          fileInfo += "File type: " + formData.get('file').type + "\n";
                          fileInfo += "File size: " + formData.get('file').size + " bytes\n";
                          fileInfo += "Last modified: " + formData.get('file').lastModifiedDate;
                          let formDataString = 'FormData:\n';
                          for (let pair of formData.entries()) {
                            formDataString += pair[0] + ': ' + pair[1] + '\n';
                          }

                          // Alert the FormData representation
                          alert(formDataString);
                          alert(fileInfo)
                        } catch (e) { /* empty */ }
                        setLoadingResult(false);
                        const response = await checkResult(challenge_id)

                        if (response != null) {
                          if (response.status == 200) {
                            socket.emit("wallet:fetchWalletBalance", {
                              type: "WALLET_BALANCE",
                              params: "",
                              data: {
                                auth: localStorage.getItem("token"),
                              },
                            });
                            navigate("/big-challenges")
                            // setRoomcode(response.data.room_code == "0" ? "" : response.data.room_code)
                            if (response.data.room_code != "0") {
                              setReadOnly(true)
                              setRoomcode(response.data.room_code)
                            }
                            if (response.data.result_uploaded == 1) {
                              setResultAlready(true)
                            }

                            notifySuccess(response.message)
                          }
                          if (response.status == 400) {
                            notify(response.message)
                          }
                        }
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>}

              </div>
            </div>
          </div>
        </div></>}

      </div>
    </>
  );
}
BigChallengeResult.propTypes = {
  socket: PropTypes.any
};
