import React from 'react';

const GameContainer = () => {
    return (
        <div>

            <div className="flex gap-2">
                <div className="w-[100vw] md:w-[480px] z-[100]  h-full">
                    <iframe className="w-full h-[90vh]"
                        // eslint-disable-next-line no-undef
                        src={process.env.REACT_APP_GAME_URL}
                        title="Ludo Game"
                        id="ludogame"
                        height={"700px"}
                        frameBorder="0"
                        allowFullScreen
                        
                    />
                </div>
            </div>

        </div>
    );
}

export default GameContainer;
