import PropTypes from 'prop-types';
import { Flip } from "react-toastify";
import { toast } from "react-toastify";
import React from 'react';


import cup from "../../assets/images/cup.png";

const notify = (message) =>
    toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
    });

export default function BigRoomList({ entry }) {


    // Check if the button was disabled previously



    return (
        <div className="border rounded mb-3">
            <div className="px-4 pb-2 bg-[#F7F7F7] flex justify-between items-center">
                <p className="mt-2 font-custom text-sm">Currently Playing: 0</p>


            </div>
            <div className="p-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <img src={cup} alt="" className="w-6" />
                    <span className="text-green-700 font-custom font-semibold text-md" >Rs {entry}</span>
                </div>
                <button
                    className="px-3 py-1 text-xs font-custom border rounded-md text-white bg-[#3b2c23]"

                    onClick={() => { notify("Under Maintenance") }}
                >
                    {'Play'}
                </button>
            </div>
        </div>
    );
}

BigRoomList.propTypes = {

    entry: PropTypes.any.isRequired,

};
