/* eslint-disable no-undef */
import React from "react";
import user from "../../assets/images/user.svg";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { checkToken } from "../../services/auth.service";

export default function StartBigChallengeCard({
  challenge_id,
  entry,
  username,
  socket,
}) {
  const navigate = useNavigate();
  return (
    <div className=" border rounded-md mb-3 ">
      <div className="border-b-2 px-4 pb-2 bg-[#F7F7F7] flex justify-between items-center ">
        <p className="mt-2">Challenge set by</p>
        <p className="text-green-700 text-xl font-bold">Rs.{entry}</p>
      </div>
      <div className="p-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <img src={user} alt="" className="w-6" />
          <span className=" font-semibold">{username}</span>
        </div>
        <button
          className="px-2 py-1 border rounded-md  text-white bg-[#0d6efd] "
          // eslint-disable-next-line no-unused-vars
          onClick={async (e) => {
            const response = await checkToken();
            if (response.status == 401) {
              navigate("/login")
              localStorage.clear()

            }
            if (response.status == 200) {

              socket.emit(`big:challenge:requestAcceptByOppnent`, {
                type: "CHALLENGE_REQUEST_DECLINE",
                params: "",
                data: {
                  auth: localStorage.getItem("token"),

                  challenge_id: challenge_id,
                },
              });

              socket.emit(`big:challenge:joinRoom`, {
                type: "CHALLENGE_REQUEST_DECLINE",
                params: "",
                data: {
                  auth: localStorage.getItem("token"),

                  challenge_id: challenge_id,
                },
              });

              navigate(`/big-challenge-result/${challenge_id}`);

            }
          }}
        >
          Start
        </button>
      </div>
    </div>
  );
}

StartBigChallengeCard.propTypes = {
  socket: PropTypes.any,
  challenge_id: PropTypes.string,
  username: PropTypes.string,
  entry: PropTypes.any,
};
