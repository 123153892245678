// eslint-disable-next-line no-undef
module.exports.sendAddharOTP = async (data) => {
    // Data to be sent in the POST request
    const postData = {
        aadhaar_number: data.aadhaar_number,
        email: data.email
    };

    const authToken = localStorage.getItem("token");
    // Configuration for the fetch request
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
            // Include auth token in the Authorization header
        },
        body: JSON.stringify(postData),
        // Convert data to JSON string
    };
    const apiRespone = {};
    try {
        const response = await fetch(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_CHECK_AADHAR}`,
            requestOptions
        );

        const data = await response.json();
        if (response.status === 401) {

            apiRespone["data"] = {};
            apiRespone["message"] = "";
            apiRespone["status"] = 401;

            return apiRespone;
        }
        if (response.status === 400) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
        if (response.status === 200) {

            apiRespone["data"] = data.data;
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
        if (response.status === 500) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
    } catch (error) {
        apiRespone["data"] = {};
        apiRespone["message"] = "Something Went Wrong";
        apiRespone["status"] = 300;
        return apiRespone;
    }
};



// eslint-disable-next-line no-undef
module.exports.verifyAddharOTP = async (data) => {
    // Data to be sent in the POST request
    const postData = {
        otp: data.otp,
        ref_id: data.ref_id,
        email: data.email,
        aadhar_no: localStorage.getItem('aadhar_no')
    };

    const authToken = localStorage.getItem("token");
    // Configuration for the fetch request
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
            // Include auth token in the Authorization header
        },
        body: JSON.stringify(postData),
        // Convert data to JSON string
    };
    const apiRespone = {};
    try {
        const response = await fetch(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_VERIFY_AADHAR}`,
            requestOptions
        );

        const data = await response.json();
        if (response.status === 401) {

            apiRespone["data"] = {};
            apiRespone["message"] = "";
            apiRespone["status"] = 401;

            return apiRespone;
        }
        if (response.status === 400) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
        if (response.status === 200) {
            apiRespone["data"] = data.data;
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
        if (response.status === 500) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
    } catch (error) {
        apiRespone["data"] = {};
        apiRespone["message"] = "Something Went Wrong";
        apiRespone["status"] = 300;
        return apiRespone;
    }
};





// 