import React from "react";
import AppBar from "../../components/AppBar/AppBar";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import refer from "../../assets/images/refer2.jpg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { userProfile } from "../../services/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader/Loader";
import PropTypes from 'prop-types';
import { useWallet } from "../../routes/AppRouter";
import { Flip } from "react-toastify";


export default function Refer({ socket }) {
  const data = useWallet()
  const navigate = useNavigate();
  const [profile_data, setProfileData] = useState(null);

  const [loading, setLoading] = useState(true);

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });

  useEffect(() => {
    socket.emit("wallet:fetchWalletBalance", {
      type: "WALLET_BALANCE",
      params: "",
      data: {
        auth: localStorage.getItem("token"),
      },
    });
    async function fetchProfile() {
      const results = await userProfile();
      if (results.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setProfileData(results.data);
    }
    fetchProfile();
    setLoading(false);
  }, []);

  return (
    <>
      <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
        {/* nav bar */}

        <AppBar socket={socket} context={data}></AppBar>
        <ToastContainer />
      </div>
      <ToastContainer></ToastContainer>
      {loading ? (
        <>
          <div className="p-3 mx-auto bg-white mt-16">
            <center>
              <Loader></Loader>
            </center>
          </div>
        </>
      ) : (
        <div className="p-3 mx-auto bg-white mt-16">
          <div className=" border rounded-md mb-4">
            <div className="text-center font-custom text-[0.8rem] border-b px-4 py-2  bg-[#F8F9FA]">
              Your Referral Earnings
            </div>
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div
                  className="flex flex-col grow items-center justify-center"
                  style={{ borderRight: "1px solid #dee2e6" }}
                >
                  <span className="capitalize font-custom text-[0.7rem] font-bold">
                    Referred Players
                  </span>
                  <span className="font-custom text-[0.8rem]">{profile_data == null ? 0 : profile_data.total_reffered_user}</span>
                </div>
                <div className="flex flex-col grow items-center justify-center">
                  <span className="capitalize font-custom text-[0.7rem] font-bold">
                    Referral Earning
                  </span>
                  <span className="font-custom text-[0.8rem]"> ₹{profile_data == null ? 0 : profile_data.referral_earning}</span>
                </div>
              </div>
            </div>
          </div>
          <div className=" border rounded-md mb-4">
            <div className="text-center font-custom text-[0.8rem] border-b px-4 py-2  bg-[#F8F9FA]">
              Referral Code
            </div>
            <div className="p-3">
              <div className="flex flex-col items-center">
                <div>
                  <img src={refer} alt="" />
                </div>
                <div className="w-full">
                  <div>
                    <div className=" flex flex-nowrap w-full ">
                      <input
                        type="text"
                        placeholder=""
                        defaultValue={
                          profile_data === null ? "" : profile_data.refer_code
                        }
                        className="w-full border border-e-gray-300 px-3 py-[6px] 
                                      rounded-l font-custom text-[0.9rem] focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                        disabled={true}
                      />
                      <button className="bg-[#554135] text-white w-1/4 px-3 py-1 rounded-r text-[0.9rem] font-custom" onClick={async () => {
                        notifySuccess("Refferal Code Copied")
                        await navigator.clipboard.writeText(profile_data.refer_code);
                      }}>
                        COPY
                      </button>

                    </div>
                  </div>
                </div>
                <p className="font-bold my-1 py-2 text-[1.1rem] font-custom">OR</p>
                <div className="w-full">
                  {/* <button
                    className="w-full mb-3 text-white px-4 py-2 rounded-md hover:bg-[#157347]"
                    style={{ backgroundColor: "#198754" }}
                  >
                    <span>
                      <i className="fa-brands fa-whatsapp" aria-hidden="true" />
                    </span>
                    <span>Share On Whatsapp</span>
                  </button> */}
                  <button
                    className="w-full text-white px-4 py-2 mb-2 rounded-md hover:bg-[#565e64]"
                    style={{ backgroundColor: "#198754" }}
                    onClick={async () => {
                      notifySuccess("Refferal Code Copied")
                      await navigator.clipboard.writeText(profile_data.refer_code);
                    }}
                  >
                    <span className="font-custom text-[0.8rem] flex items-center text-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-2"><path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path></svg> Share On Whatsapp</span>
                  </button>
                  <button
                    className="w-full text-white px-4 py-1 rounded-md hover:bg-[#565e64]"
                    style={{ backgroundColor: "#6c757d" }}
                    onClick={async () => {
                      notifySuccess("Refferal Code Copied")
                      await navigator.clipboard.writeText(profile_data.refer_code);
                    }}
                  >
                    <span className="px-2">
                      <i
                        className="fa-regular fa-clipboard"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="font-custom text-[0.8rem]"> Copy to Clipboard</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Refer.propTypes = {
  socket: PropTypes.any
};
