import React from "react";
import { Link } from "react-router-dom";

import PropTypes from 'prop-types';
export default function Game({ path, image }) {
  return (
    <div className="rounded-lg overflow-hidden w-full" >
      <Link to={path}>
        <img src={image} alt="" />
      </Link>
    </div>

  );
}

Game.propTypes = {
  path: PropTypes.string,
  image: PropTypes.string


};