import React from "react";
import AppBar from "../../components/AppBar/AppBar";


import PropTypes from 'prop-types';

import { useWallet } from "../../routes/AppRouter";
import RoomList from "../../components/Room List/RoomList";
import { useEffect } from "react";


import { ToastContainer } from "react-toastify"
import { useState } from "react";

import { checkGameOnly, createChallenge, submitGameChallenge, viewGameChallenge } from "../../services/challenge.service";

import { useNavigate } from "react-router-dom";

import WebViewChallengeCard from "../../components/WebChallenges/WebViewChallengeCard";
import BigRoomList from "../../components/Room List/BigRoomList";

export default function AutoPlay({ socket }) {

    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    // const [apiloader, setApiLoader] = useState(false)

    const data = useWallet();
    const [opengames, setOpenGames] = useState([]);
    const [gameCount, setGameCount] = useState(0);
    const [play, setPlay] = useState([]);

    const allOpenChallenges = async () => {
        //setApiLoader(true)


        const response = await checkGameOnly()
        // setApiLoader(false)
        if (response.status == 401) {
            localStorage.clear()
            navigate("/login")
        }
        if (response.status == 200) {
            console.log(response.data);
            console.log(opengames);
            console.log(play);
            setPlay(response.data.open_challenges)
            setOpenGames(response.data.user_open_challenges)

            console.log(play.length);
            console.log(opengames.length);
            // if (response.data.open_challenges == 0) {
            //     localStorage.removeItem("creator_token")
            //     localStorage.removeItem("opponent_token")
            //     localStorage.removeItem("challenge_id")

            // }


        }
    }

    useEffect(() => {
        allOpenChallenges()

    }, [])
    useEffect(() => {

        if (socket != undefined) {

            socket.emit("gameCount")
            socket.emit("wallet:fetchWalletBalance", {
                type: "WALLET_BALANCE",
                params: "",
                data: {
                    auth: localStorage.getItem("token"),
                },
            });
        }
        socket.on('connect', () => {
            console.log('Connected to server');
        });

        // Join the matchmaking queue
        socket.on('showCount', async (game) => {
            console.log("fdfddfdf");
            setGameCount(game.count * 2)
        })
        socket.on('challengeAccepted', async (challenges) => {

            if (challenges.role == "creator") {
                console.log("Challenge creator");
                console.log(challenges);

                // localStorage.removeItem("opponent_token")
                // localStorage.setItem("creator_token", challenges.creator_data.creator_token)
                // localStorage.setItem("challenge_id", challenges.challenge._id)
                // localStorage.setItem("creator_token", challenges.creator_data.creator_token)

                try {
                    //const challenge_data = { challenge_id: challenges.challenge._id }

                    // const challenge = await viewGameChallenge(challenge_data)

                    // console.log(challenge.data)

                    // const submitGameData = {
                    //     challenge_id: challenges.challenge._id,
                    //     type: "4token",
                    //     creator_token: localStorage.getItem("token"),
                    //     creator_username: challenge.data.u_name,
                    //     username: challenge.data.u_name,
                    //     opponent_token: "",
                    //     opponent_username: "",
                    //     room_code: challenge.data.room_code,
                    //     entry_fee: challenge.data.entry_fee,
                    //     bot_play: challenge.data.play,
                    //     is_bot: false,





                    // }
                    console.log("before api call");
                    console.log("creator");
                    //   console.log(submitGameData);
                    try {
                        // const response = await submitGameChallenge(submitGameData)


                        // console.log(response)

                        // if (response.status == 200 || response.status == 201)
                        localStorage.removeItem("opponent_token")
                        localStorage.setItem("creator_token", challenges.creator_data.creator_token)
                        localStorage.setItem("challenge_id", challenges.challenge._id)
                        //   window.location.href = `https://yogiludo.com/game3.html`;
                        window.location.href = `https://kheldost.com/game7.html`;


                        // navigate("/play-game")
                    } catch (e) {
                        console.log(e)

                    }
                    //             {
                    //               "_id": "662895e012ef1ef3d43e740d",
                    //     "user_id": "6617c02dc9b3477c9914acea",
                    //     "u_name": "Yogesh",
                    //     "lat": "N/A",
                    //     "lang": "N/A",
                    //     "o_name": "Testuser",
                    //     "entry_fee": 500,
                    //     "prize_money": 950,
                    //     "game_status": 3,
                    //     "status": "accepted",
                    //     "opponent_id": "6618cb5d4734b0c3d3524c18",
                    //     "game_id": "65bf89584628069304705ddc",
                    //     "room_code": "0",
                    //     "r_upload": 0,
                    //     "c_upload": 0,
                    //     "o_upload": 0,
                    //     "game_type": "web",
                    //     "challenge_tag": "small",
                    //     "createdAt": "2024-04-24T05:17:20.507Z",
                    //     "updatedAt": "2024-04-24T05:17:34.544Z",
                    //     "__v": 0
                    // }
                }
                catch (e) {
                    console.log(e)
                }
            }

            if (challenges.role == "opponent") {

                localStorage.removeItem("creator_token")
                localStorage.setItem("opponent_token", localStorage.getItem("token"))
                localStorage.setItem("challenge_id", challenges.challenge._id)
                // try {
                // const challenge_data = { challenge_id: challenges.challenge._id }

                // const challenge = await viewGameChallenge(challenge_data)



                // const submitGameData = {
                //     challenge_id: challenges.challenge._id,
                //     type: "4token",
                //     creator_token: "",
                //     creator_username: "",
                //     opponent_token: localStorage.getItem("token"),
                //     username: challenge.data.o_name,
                //     opponent_username: challenge.data.o_name,
                //     room_code: challenge.data.room_code,
                //     entry_fee: challenge.data.entry_fee,
                //     bot_play: challenge.data.play,
                //     is_bot: false,





                // }


                try {
                    //  const response = await submitGameChallenge(submitGameData)
                    // console.log(response)
                    console.log("Challenge opponents");
                    console.log(challenges);

                    localStorage.removeItem("creator_token")
                    localStorage.setItem("opponent_token", challenges.opponent_data.opponent_token)
                    localStorage.setItem("challenge_id", challenges.challenge._id)
                    // if (response.status == 200 || response.status == 201)
                    window.location.href = `https://kheldost.com/game7.html`;

                    //   window.location.href = `https://yogiludo.com/game3.html`;

                    // navigate("/play-game")
                } catch (e) {
                    console.log(e)

                }
                //             {
                //               "_id": "662895e012ef1ef3d43e740d",
                //     "user_id": "6617c02dc9b3477c9914acea",
                //     "u_name": "Yogesh",
                //     "lat": "N/A",
                //     "lang": "N/A",
                //     "o_name": "Testuser",
                //     "entry_fee": 500,
                //     "prize_money": 950,
                //     "game_status": 3,
                //     "status": "accepted",
                //     "opponent_id": "6618cb5d4734b0c3d3524c18",
                //     "game_id": "65bf89584628069304705ddc",
                //     "room_code": "0",
                //     "r_upload": 0,
                //     "c_upload": 0,
                //     "o_upload": 0,
                //     "game_type": "web",
                //     "challenge_tag": "small",
                //     "createdAt": "2024-04-24T05:17:20.507Z",
                //     "updatedAt": "2024-04-24T05:17:34.544Z",
                //     "__v": 0
                // }
            }
            // catch (e) {
            //     console.log(e)
            // }

        }





        );

        socket.on('challengeCreated', (challenge) => {
            // alert(`Challenge created: ${challenge._id}`);
            console.log(challenge);
        });

        socket.on('challengeTimeout', ({ challenge_id }) => {
            //alert(`Challenge timed out: ${challenge_id}`);
            console.log('challengeTimeout');
            console.log(challenge_id);
        });

        socket.on('challengeNotFound', ({ challenge_id }) => {
            console.log('challengeNotFound');
            console.log(challenge_id);

            // alert(`Challenge not found: ${challenge_id}`);
        });


        socket.on('waiting', () => {
            setLoader(true)
            console.log('Waiting for opponent...');
        });

        socket.on('matched', async (data) => {
            if (localStorage.getItem('token') != data.opponent_token) {
                console.log(data);
                console.log('Matched with opponent:', data.opponentId);
                const result = {
                    entry_fee: parseInt(10),
                    game_id: "65bf89584628069304705ddc",
                    game_type: "web"
                };
                const ress = await createChallenge(result)

                socket.emit("gameChallenge:request", {
                    type: "CHALLENGE_REQUESTED",
                    params: "",
                    data: {
                        auth: data.opponent_token,

                        challenge_id: ress.data.challenge._id.toString(),
                    },
                });

                socket.emit("gameChallenge:requestAccept", {
                    type: "CHALLENGE_REQUEST_ACCEPT",
                    params: "",
                    data: {
                        auth: localStorage.getItem("token"),

                        challenge_id: ress.data.challenge._id.toString(),
                    },
                });
                socket.emit("wallet:fetchWalletBalance", {
                    type: "WALLET_BALANCE",
                    params: "",
                    data: {
                        auth: localStorage.getItem("token"),
                    },
                });
                socket.emit("gameChallenge:joinRoom", {
                    type: "CHALLENGE_REQUEST_DECLINE",
                    params: "",
                    data: {
                        auth: localStorage.getItem("token"),

                        challenge_id: ress.data.challenge._id.toString(),
                    },
                });
                localStorage.removeItem("opponent_token")
                localStorage.setItem("creator_token", localStorage.getItem("token"))
                localStorage.setItem("challenge_id", ress.data.challenge._id.toString())

                try {

                    const challenge_data = { challenge_id: ress.data.challenge._id }
                    const challenge = await viewGameChallenge(challenge_data)

                    console.log(challenge.data)

                    const submitGameData = {
                        challenge_id: ress.data.challenge._id,
                        type: "4token",
                        creator_token: localStorage.getItem("token"),
                        creator_username: challenge.data.u_name,
                        opponent_token: "",
                        opponent_username: "",
                        room_code: challenge.data.room_code,
                        entry_fee: challenge.data.entry_fee,
                        bot_play: challenge.data.play,
                        is_bot: false,





                    }
                    try {

                        const response = await submitGameChallenge(submitGameData)
                        console.log(response)
                        socket.emit("connecttoOpponent", {
                            opponent_data: data, creator_data: {
                                auth: localStorage.getItem("token"),

                                challenge_id: ress.data.challenge._id.toString(),
                                challenge_data: challenge.data
                            }


                        })
                        // window.location.href = `https://yogiludo.com/game3.html`;
                        window.location.href = `https://kheldost.com/game7.html`;

                    } catch (e) {
                        console.log(e)

                    }
                } catch (e) {
                    console.log(e);
                }
            }

        });
        socket.on("connecttoOpponent:" + localStorage.getItem("token"), async (data) => {
            console.log(data);
            socket.emit("gameChallenge:requestAcceptByOppnent", {
                type: "CHALLENGE_REQUEST_DECLINE",
                params: "",
                data: {
                    auth: data.creator_data.auth,

                    challenge_id: data.challenge_data._id,
                },
            });



            localStorage.removeItem("creator_token")

            localStorage.setItem("opponent_token", localStorage.getItem("token"))
            localStorage.setItem("challenge_id", data.challenge_data._id)




            try {
                const challenge_data = { challenge_id: data.challenge_data._id }
                const challenge = await viewGameChallenge(challenge_data)

                console.log(challenge.data)

                const submitGameData = {
                    challenge_id: data.challenge_data._id,
                    type: "4token",
                    creator_token: "",
                    creator_username: "",
                    opponent_token: localStorage.getItem("token"),
                    opponent_username: challenge.data.o_name,
                    room_code: challenge.data.room_code,
                    entry_fee: challenge.data.entry_fee,
                    bot_play: challenge.play,
                    is_bot: false





                }
                try {
                    const response = await submitGameChallenge(submitGameData)
                    console.log(response)
                    window.location.href = `https://kheldost.com/game7.html`;

                    //window.location.href = `https://yogiludo.com/game3.html`;
                } catch (e) {
                    console.log(e)

                }
            }
            catch (e) {
                console.log(e)
            }
            //  navigate(`/challenge-result/${challenge_id}`);

        }
        )


        socket.on('disconnect', () => {
            console.log('Disconnected from server');
        });
        return () => {
            socket.off("matched");
            socket.off("challengeAccepted")
            socket.off("challengeCreated")
            socket.off("challengeTimeout")
            socket.off("challengeNotFound")
        }

    }, [socket])

    const handleCancel = () => {
        if (socket) {
            console.log("test");
            socket.emit('cancelRequest'); // Emit cancelRequest event when user cancels
        }
    };
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                handleCancel(); // Call cancel logic when tab or window is hidden
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [socket]);




    return (
        <>
            <ToastContainer></ToastContainer>
            <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
                {/* nav bar */}
                <AppBar socket={socket} context={data}></AppBar>
            </div>



            <div className="p-3 mx-auto bg-white mt-[64px] h-full min-h-[100vh] ">

                {/* card 1 */}

                {opengames.length > 0 ? <>


                    {opengames.map((value, index) => {



                        if (value.status == "started") {
                            return (
                                <>
                                    <WebViewChallengeCard
                                        key={index}
                                        prize={value.prize_money}
                                        challenge_id={value._id}
                                        entry={value.entry_fee}
                                        user_id={value.user_id._id}
                                        status={value.status}
                                        username={value.u_name}
                                        socket={socket}
                                        gameCount={gameCount}
                                    ></WebViewChallengeCard>
                                    <BigRoomList entry={20} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={30} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={50} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={100} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={500} available={false} socket={socket} loader={loader}></BigRoomList>

                                </>
                            );
                        }




                    })}

                    {/* {play.map((value, index) => {



                            if (value.status == "started") {
                                return (
                                    <WebViewChallengeCard
                                        key={index}
                                        prize={value.prize_money}
                                        challenge_id={value._id}
                                        entry={value.entry_fee}
                                        user_id={value.user_id._id}
                                        status={value.status}
                                        username={value.u_name}
                                        socket={socket}
                                    ></WebViewChallengeCard>
                                );
                            }




                        })} */}
                </> : <>

                    <RoomList entry={10} gameCount={gameCount} available={true} socket={socket} loader={loader}></RoomList>
                    <BigRoomList entry={20} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={30} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={50} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={100} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={500} available={false} socket={socket} loader={loader}></BigRoomList>


                </>}

            </div>



        </>
    );
}

AutoPlay.propTypes = {
    socket: PropTypes.any
};