// eslint-disable-next-line no-undef
const routes = require("./routes");
// eslint-disable-next-line no-undef
module.exports.userProfile = async () => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.profile}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};


// eslint-disable-next-line no-undef
module.exports.getGameHistory = async (page) => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.gameHistory}${page}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};


// eslint-disable-next-line no-undef
module.exports.getPenaltyHistory = async (page) => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.penaltyHistory}${page}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};


// eslint-disable-next-line no-undef
module.exports.getRefferalHistory = async (page) => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.refferalHistory}${page}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};

// eslint-disable-next-line no-undef
module.exports.fetchWallet = async () => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.fetchWallet}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};



// eslint-disable-next-line no-undef
module.exports.getWalletHistory = async (page) => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.walletHistory}${page}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};



// eslint-disable-next-line no-undef
module.exports.checkGameStatus = async () => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.checkGameStatus}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};

// eslint-disable-next-line no-undef
module.exports.getWalletHistory = async (page) => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.walletHistory}${page}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};


// eslint-disable-next-line no-undef
module.exports.fetchKYC = async () => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.fetchKYC}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};



// eslint-disable-next-line no-undef
module.exports.updateUsername = async (data) => {
  // Data to be sent in the POST request
  const postData = {
    username: data.username,
  };

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },
    body: JSON.stringify(postData),
    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.update_username}`,
      requestOptions
    );

    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};


// eslint-disable-next-line no-undef
module.exports.sendBonus = async (data) => {
  // Data to be sent in the POST request
  const postData = {
    amount: data.amount,
  };

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },
    body: JSON.stringify(postData),
    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.sendBonus}`,
      requestOptions
    );

    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};


module.exports.userStatement = async (startDate, endDate) => {
  // Data to be sent in the POST request
  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ token: authToken, startDate: startDate, endDate: endDate }),
  };

  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_USER_API}${routes.statement}`,
      requestOptions
    );

    const apiRespone = {
      data: {},
      message: '',
      status: response.status
    };

    if (response.status === 401) {
      apiRespone.message = "Unauthorized access.";
      return apiRespone;
    }

    if (response.status === 400) {
      const data = await response.json();
      apiRespone.message = data.message;
      return apiRespone;
    }

    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'statement.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();

      apiRespone.message = "Download successful.";
      return apiRespone;
    }

    if (response.status === 500) {
      const data = await response.json();
      apiRespone.message = data.message;
      return apiRespone;
    }

    return apiRespone;

  } catch (error) {
    return {
      data: {},
      message: "Something Went Wrong",
      status: 300,
    };
  }
};

// eslint-disable-next-line no-undef
module.exports.userBiil = async (transaction_id) => {
  // Data to be sent in the POST request
  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ token: authToken, transaction_id: transaction_id }),
  };

  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_GET_BILL,
      requestOptions
    );

    const apiRespone = {
      data: {},
      message: '',
      status: response.status
    };

    if (response.status === 401) {
      apiRespone.message = "Unauthorized access.";
      return apiRespone;
    }

    if (response.status === 400) {
      const data = await response.json();
      apiRespone.message = data.message;
      return apiRespone;
    }

    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'statement.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();

      apiRespone.message = "Download successful.";
      return apiRespone;
    }

    if (response.status === 500) {
      const data = await response.json();
      apiRespone.message = data.message;
      return apiRespone;
    }

    return apiRespone;

  } catch (error) {
    return {
      data: {},
      message: "Something Went Wrong",
      status: 300,
    };
  }
};