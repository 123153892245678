import React, { useState, useEffect } from "react";
import AppBar from "../../components/AppBar/AppBar";
import { Link, useNavigate } from "react-router-dom";
import FullFilledButton from "../../components/Button/FullFilledButton";
import { verifyAddharOTP } from "../../services/kyc.sercvice";
import PropTypes from 'prop-types';
// import { useWallet } from "../../routes/AppRouter";
import { Flip } from "react-toastify";

import { ToastContainer, toast } from "react-toastify";
export default function KycOTP({ socket }) {
    const notify = (message) =>
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Flip,
        });
    // const data = useWallet()
    useEffect(() => {
        if (socket != undefined) {


            socket.emit("wallet:fetchWalletBalance", {
                type: "WALLET_BALANCE",
                params: "",
                data: {
                    auth: localStorage.getItem("token"),
                },
            });
        }
    }, [])
    const [OTP, setOTP] = useState("")

    const navigate = useNavigate();
    return (
        <>
            <div className="w-[100vw] fixed top-0  md:w-[38%]">
                {/* nav bar */}
                <AppBar></AppBar>

            </div>
            <div className="p-3 mx-auto bg-white mt-16 h-[100vh]">
                <div>
                    <div className="flex items-center justify-between mt-4 mb-6">
                        <button
                            className="text-white bg-[#0d6efd] rounded-md  px-4 py-[6px]"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Back
                        </button>

                        <button
                            className="flex items-center border text-[#0d6efd]  rounded-md border-[#0d6efd]
                      px-3 py-[6px] hover:bg-[#0d6efd] hover:text-white"
                        >
                            <span>Guide</span>
                        </button>
                    </div>
                    {/* Deposit Chips */}
                    <div className="shadow-lg border rounded-md mb-3">
                        <ToastContainer></ToastContainer>
                        <div className="text-center border-b-2 px-4 py-2 font-bold bg-[#F8F9FA]">
                            Submit OTP
                        </div>
                        <div className="p-4">

                            <div className="mb-4 flex flex-col items-start">
                                <label htmlFor="" className="mb-2">
                                    OTP
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter Aadhar Card Number"
                                    maxLength={6}
                                    value={OTP}
                                    onChange={(event) => {
                                        if (/^\d*$/.test(event.target.value)) {
                                            setOTP(event.target.value);
                                        }
                                    }}
                                    className="border rounded-md
                           text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                                />
                            </div>


                            <p className="mb-3 text-center text-[0.9rem]">
                                By Continuing, you agree to our{" "}
                                <Link to={localStorage.getItem('token') != null ? "/legal" : "/legaloffline"} className="text-[#0d6efd] underline">
                                    Legal Terms
                                </Link>{" "}
                                and you are 18 years or older.
                            </p>
                            <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                                <FullFilledButton onClick={async () => {
                                    const data = {
                                        otp: OTP,
                                        ref_id: localStorage.getItem('ref_id'),
                                        email: localStorage.getItem('email')
                                    }
                                    const response =

                                        await verifyAddharOTP(data)
                                    if (response.status == 200) {
                                        navigate("/")
                                    }
                                    if (response.status != 200) {
                                        notify(response.message)
                                    }

                                }}
                                    buttonCaption={"Request for KYC"}
                                ></FullFilledButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

KycOTP.propTypes = {
    socket: PropTypes.any
};