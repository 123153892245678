import React from "react";
import AppBar from "../../components/AppBar/AppBar";

import user from "../../assets/images/avatar-m-5.eccc495fab8bd00363e88468877746f6.svg";
import box from "../../assets/images/box.png";
import sword from "../../assets/images/sword.png";
import money from "../../assets/images/money.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {

  updateUsername,
  userProfile,
} from "../../services/user.service";
import { userLogout } from "../../services/auth.service";
import { ToastContainer, toast } from "react-toastify";
import { Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader/Loader";
import PropTypes from 'prop-types';
import { useWallet } from "../../routes/AppRouter";


const notify = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Flip,
  });

const notifySuccess = (message) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Flip,
  });
export default function Profile({ socket }) {
  const navigate = useNavigate();
  const [profile_data, setProfileData] = useState(null);
  const [buttonStatus, setButtonStatus] = useState("Edit");
  const [usernameStatus, setUsernameStatus] = useState(true);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingUserName, setUserNameLoading] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);

  const data = useWallet()

  useEffect(() => {
    if (socket != undefined) {


      socket.emit("wallet:fetchWalletBalance", {
        type: "WALLET_BALANCE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),
        },
      });
    }
    async function fetchProfile() {
      const results = await userProfile();
      //await getGameHistory(40);


      if (results.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setProfileData(results.data);
      setUsername(results.data === null ? "" : results.data.username);
    }
    fetchProfile();
    setLoading(false);
  }, []);

  return (
    <>
      <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
        {/* nav bar */}
        <AppBar socket={socket} context={data}></AppBar>
      </div>
      <ToastContainer></ToastContainer>
      {profile_data == null && loading == true ? (
        <>
          <div className="p-3 mx-auto bg-white mt-16">
            <center>
              <Loader></Loader>
            </center>
          </div>
        </>
      ) : (
        <div className="p-3 mx-auto bg-white mt-16">
          <div>
            {/* Profile card */}
            <div className="shadow-lg border rounded-md mb-3 border-1">
              <div className="text-center font-custom text-[0.9rem] border-b-[1px] px-4 py-2 border-[#c7c7c7] bg-[#F8F9FA]">
                Profile
              </div>
              <div className="p-4">
                <div className="flex items-center justify-center">
                  <div className="h-20 w-20 flex justify-center">
                    <div
                      className={`w-[50px] h-[50px] relative drop-shadow-lg`}
                    >
                      <img src={user} alt="user" />
                      <div className="absolute shadow rounded-full bg-white w-5 h-5 bottom-0 right-0 cursor-pointer flex items-center justify-center">
                        <i className="fa-solid fa-pencil text-[0.6rem]" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center mb-4">
                  <label htmlFor="" className="mb-1 font-custom text-sm">
                    Username
                  </label>
                  <div className="self-stretch flex items-center">
                    <input
                      type="text"
                      className="mr-2 bg-[#e9ecef] font-custom opacity-100 border border-[#ced4da] rounded-sm text-[0.8rem] text-[#212529] px-3 py-[0.4rem] px-3 py-1 w-full "
                      disabled={usernameStatus}
                      value={username}
                      onChange={(event) => {
                        if (/^[a-zA-Z0-9_]*$/.test(event.target.value)) {
                          setUsername(event.target.value);
                        }
                      }}
                      defaultValue={
                        profile_data === null ? "" : profile_data.username
                      }
                    />
                    {loadingUserName ? (
                      <>
                        <div className="self-stretch px-2 py-1 w-[20px] h-[20px]">
                          <Loader></Loader>{" "}
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          className="self-stretch bg-[#3b2c23] text-[0.8rem] text-white rounded-sm px-2 py-1 w-[60px]"
                          // eslint-disable-next-line no-unused-vars
                          onClick={async (e) => {

                            setButtonStatus("Update");
                            setUsernameStatus(false);
                            if (buttonStatus == "Update") {

                              if (username.length < 6) {
                                setButtonStatus("Edit");
                                notify("Name should be 6 character long")
                                setUsernameStatus(true);
                                return
                              }
                              let data = { username: username };
                              setUserNameLoading(true);
                              const result = await updateUsername(data);
                              localStorage.setItem("token", result.data.token)
                              setUserNameLoading(false);
                              if (result.status !== 200) {
                                notify(result.message);
                                setUsername(profile_data.username);
                              } else {
                                setButtonStatus("Edit");
                                notifySuccess(result.message);
                                setUsernameStatus(true);
                              }
                            }
                          }}
                        >
                          {buttonStatus}
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center mb-4">
                  <label htmlFor="" className="mb-1 font-custom text-sm">
                    Mobile Number
                  </label>
                  <div className="self-stretch flex items-center">
                    <input
                      type="text"
                      className="mr-2 bg-[#e9ecef] font-custom opacity-100 border border-[#ced4da] rounded-sm text-[0.8rem] text-[#212529] px-3 py-[0.4rem] w-full "
                      disabled={true}
                      defaultValue={
                        profile_data === null ? "" : profile_data.mobile
                      }
                    />
                  </div>
                </div>
                {profile_data === null ? (
                  ""
                ) : profile_data.is_kyc ? (
                  <>
                    <div className="flex items-center justify-between p-3 border border-[#DC3545] rounded-md">
                      <div className="flex flex-col items-start justify-center">
                        <span className="text-xs font-custom">KYC status</span>

                        <span className="font-bold font-custom">Completed </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex items-center justify-between p-3 border border-[#DC3545] rounded-md">
                      <div className="flex flex-col items-start justify-center">
                        <span className="text-[0.7rem] font-custom">KYC status</span>

                        <span className="font-bold font-custom text-sm flex items-center">
                          Pending{" "} <svg xmlns="http://www.w3.org/2000/svg" className="ml-1" viewBox="0 0 16 16" width="16" height="16" fill="red"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg>
                          <img
                            src="../assets/info-sign.png"
                            className="w-6 inline"
                            alt=""
                          />
                        </span>
                      </div>
                      <button className="border border-[#DC3545] text-[0.7rem] font-custom rounded-md text-[#DC3545] px-3 py-1 hover:bg-[#DC3545] hover:text-white" onClick={() => {
                        navigate("/kyc")
                      }}>
                        Complete KYC
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Metrics card */}
            <div className="shadow-lg border rounded-md mb-3 border-[#c7c7c7]">
              <div className="text-center font-custom text-[0.9rem] px-4 py-2 rounded-t border-[#c7c7c7] bg-[#F8F9FA]">
                Metrics
              </div>
              <div className="p-4">
                <div className="flex gap-2 ">
                  <div className="flex flex-col flex-1">
                    {/* card1 */}
                    <div className="border rounded-md border-[#c7c7c7] mb-2">
                      <div className=" border-b-[1px] px-2 py-2 border-[#c7c7c7] bg-[#F8F9FA]">
                        <div className="flex gap-2 items-center">
                          <img src={sword} alt="" className="w-3 h-3" />
                          <span className="font-custom text-[0.8rem]">Games Played</span>
                        </div>
                      </div>
                      <div className="px-4 py-1">
                        <h2 className="font-bold font-custom text-[1rem]">
                          {profile_data === null
                            ? ""
                            : profile_data.games_played}
                        </h2>
                      </div>
                    </div>
                    {/* card2 */}
                    <div className="border rounded-md border-[#c7c7c7]">
                      <div className=" border-b-[1px] px-2 py-2 border-[#c7c7c7] bg-[#F8F9FA]">
                        <div className="flex gap-2 items-center">
                          <i className="fa-solid fa-user-group text-xs" />
                          <span className="font-custom text-[0.8rem]">Referral Earning</span>
                        </div>
                      </div>
                      <div className="px-4 py-1">
                        <h2 className="font-bold font-custom text-[1rem]">
                          {profile_data === null
                            ? ""
                            : profile_data.referral_earning}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col flex-1">
                    {/* card1 */}
                    <div className="border rounded-md border-[#c7c7c7] mb-2">
                      <div className=" border-b-[1px] px-4 py-2 border-[#c7c7c7] bg-[#F8F9FA]">
                        <div className="flex gap-2 items-center">
                          <img src={money} alt="" className="w-4 h-4" />
                          <span className="font-custom text-[0.8rem]">Chips Won</span>
                        </div>
                      </div>
                      <div className="px-4 py-1">
                        <h2 className="font-bold font-custom text-[1rem]">
                          {" "}
                          {profile_data === null ? "" : profile_data.win_wallet}
                        </h2>
                      </div>
                    </div>
                    {/* card2 */}
                    <div className="border rounded-md border-[#c7c7c7]">
                      <div className=" border-b-[1px] px-4 py-2 border-[#c7c7c7] bg-[#F8F9FA]">
                        <div className="flex gap-2 items-center">
                          <img src={box} alt="" className="w-4 h-4" />
                          <span className="font-custom text-[0.8rem]">Penalty</span>
                        </div>
                      </div>
                      <div className="px-4 py-1">
                        <h2 className="font-bold font-custom text-[1rem]">
                          {" "}
                          {profile_data === null ? "" : profile_data.penalty}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loadingLogout ? (
              <center>
                <div className="py-2">
                  <div
                    className="
                  w-full  px-3 py-[6px] "
                  >
                    <Loader></Loader>
                  </div>
                </div>
              </center>
            ) : (
              <div className="py-2">
                <button
                  onClick={async () => {
                    setLoadingLogout(true);
                    const result = await userLogout();
                    setLoadingLogout(false);
                    //  e.currentTarget.disabled = false;
                    if (result.status == 200) {
                      localStorage.clear();
                      navigate("/login");
                    }

                  }}
                  className="border border-[#DC3545] rounded-sm
                  w-full text-[#DC3545] px-3 py-[4px] text-sm font-custom hover:bg-[#DC3545] hover:text-white"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

Profile.propTypes = {
  socket: PropTypes.any
};
