import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { checkOrderStatus } from '../../services/order.service';

export default function Order() {
    const navigate = useNavigate()
    const { order_id } = useParams();
    const checkOrder = async (order_id) => {
        const response = await checkOrderStatus(order_id)
        if (response.status == 200) {
            navigate('/')
        }
        else {
            navigate('/')
        }
    }
    useEffect(() => {
        checkOrder(order_id)
    }, [])

    return (
        <div>Order</div>
    )
}
