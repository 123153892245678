/* eslint-disable no-undef */
import React from "react";
import user from "../../assets/images/user.svg";
import user2 from "../../assets/images/user2.svg";
import vswep from "../../assets/images/vs.webp";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { checkToken } from "../../services/auth.service";

export default function BigChallengeOnevsOneCard({
  socket,
  challenge_id,
  prize,
  o_name,
  username,
}) {
  const navigate = useNavigate()
  return (
    <div className="border rounded my-2">
      <div className="p-4 pb-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="bg-dark rounded-full mr-2 w-6 h-6 ">
              <img src={user2} alt="" />
            </div>
            <span className="font-semibold">{username}</span>
          </div>
          <div>
            <img src={vswep} alt="" className="w-8" />
          </div>
          <div className="flex flex-row-reverse items-center">
            <div className="bg-dark rounded-full ml-2 w-6 h-6 ">
              <img src={user} alt="" />
            </div>
            <span className="font-semibold">{o_name}</span>
          </div>
        </div>
        <div className="flex items-center justify-center pt-3">
          <span className="text-xl text-green-700 font-bold">Rs.{prize}</span>
        </div>
      </div>
      <div className="flex justify-end p-2 gap-2">
        <button
          className="px-2 py-1 border rounded-md  text-white bg-green-500 "
          // eslint-disable-next-line no-unused-vars
          onClick={async (e) => {

            const response = await checkToken();
            if (response.status == 401) {
              navigate("/login")
              localStorage.clear()

            }
            if (response.status == 200) {

              socket.emit(`big:challenge:requestAccept`, {
                type: "CHALLENGE_REQUEST_ACCEPT",
                params: "",
                data: {
                  auth: localStorage.getItem("token"),

                  challenge_id: challenge_id,
                },
              });
              socket.emit("wallet:fetchWalletBalance", {
                type: "WALLET_BALANCE",
                params: "",
                data: {
                  auth: localStorage.getItem("token"),
                },
              });

            }
          }
          }
        >
          Start
        </button>
        <button
          className="px-2 py-1 border rounded-md  text-white bg-red-500 "
          // eslint-disable-next-line no-unused-vars
          onClick={(e) => {
            socket.emit("big:challenge:requestDecline", {
              type: "CHALLENGE_REQUEST_DECLINE",
              params: "",
              data: {
                auth: localStorage.getItem("token"),

                challenge_id: challenge_id,
              },
            });
          }}
        >
          Decline
        </button>
      </div>
    </div>
  );
}

BigChallengeOnevsOneCard.propTypes = {
  socket: PropTypes.any,
  challenge_id: PropTypes.string,
  prize: PropTypes.any,
  o_name: PropTypes.string,
  username: PropTypes.string,
};
