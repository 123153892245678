import React from 'react';
import PropTypes from 'prop-types';
import InfoAlert from './InfoAlert';
import FullFilledButton from '../Button/FullFilledButton';

const Offcanvas = ({ isOpen, onClose }) => {
  return (
    <div>
      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 z-40 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        onClick={onClose}
      ></div>

      {/* Offcanvas Content */}
      <div
        className={`fixed bottom-0 left-0 right-0 transform transition-transform duration-300 bg-white shadow-lg p-5 z-50 ${isOpen ? 'translate-y-0' : 'translate-y-full'
          }`}
      >
        <div className='flex flex-row justify-between'>
          <h2 className='font-custom'>Convert To Deposit (5% Cashback)</h2>
          <button className="text-gray-600 font-custom" aria-label="Close" onClick={onClose}>
            X
          </button>
        </div>
        <div className="mt-5">
          <label htmlFor="amount" className="form-label w-100 text-start font-custom text-[0.8rem] text-gray-700">Enter Amount</label>
          <form className='mt-2'>
            <div className="flex flex-row justify-between">
              <span className="bg-gray-100 px-2 border w-[8%] h-8 py-1">₹</span>
              <input
                placeholder="Amount"
                min="50"
                max="100000"
                type="number"
                id="amount"
                className="w-[67%] border px-2"
                value=""
              />
              <button type="button" className="w-[25%] border text-[0.9rem] bg-gray-100">Use Max</button>
            </div>
          </form>
          <div className="flex flex-row justify-between">
            <span className='text-[0.7rem]'>Min: 50</span>
            <span className='text-[0.7rem]'>Max: 1,00,000</span>
          </div>
          <div className='summary'>
            <div className="shadow-md border rounded-md mb-3 mt-4">
              <div className="text-center border-b-2 px-4 py-2 bg-gray-100 font-custom text-[0.8rem]">
                Summary
              </div>
              <div className="flex flex-row justify-between px-4 mt-3">
                <span className='text-[0.8rem]'>Deposit Amount <span className='bg-orange-200 px-1 rounded-sm'>A</span></span>
                <span className='text-[0.8rem] text-green-600 font-medium'>₹ 5000.00</span>
              </div>
              <div className="flex flex-row justify-between px-4 mt-1">
                <span className='text-[0.8rem]'>+5 Cash Bonus <span className='bg-orange-200 px-1 rounded-sm'>B</span></span>
                <span className='text-[0.8rem] text-green-600 font-medium'>₹ 250.00</span>
              </div>
              <div className='separator w-full h-[2px] bg-gray-200 mt-4'></div>
              <div className="flex flex-row justify-between px-4 mt-3">
                <span className='text-[0.7rem] font-bold'>Add To Wallet Balance (Total) <span className='bg-orange-200 px-1 rounded-sm'>A</span> + <span className='bg-orange-200 px-1 rounded-sm'>B</span> </span>
                <span className='text-[0.8rem] text-green-600 font-medium'>₹ 5250.00</span>
              </div>
              <div className="p-4">
                <div>
                  <InfoAlert
                    message="* आपकी Winning Chips को 5% Bonus के साथ Deposite Chips में डाल दिया जायेगा जिससे आप सिर्फ़ गेम खेल सकते है ! ध्यान रहे की आप फिर से बिना गेम खेले Deposites को Winning में नहीं बदल सकते है *"
                  ></InfoAlert>
                </div>
              </div>
            </div>
            <div className="pt-1">
              <FullFilledButton
                buttonCaption={"Convert"}
              ></FullFilledButton>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Offcanvas.propTypes = {
  isOpen: PropTypes.any,
  onClose: PropTypes.any
};

export default Offcanvas;

