import React from "react";
import AppBar from "../../components/AppBar/AppBar";
import PaginationComponent from "./Pagination";
import PropTypes from 'prop-types';
import { useEffect } from "react";
import { useWallet } from "../../routes/AppRouter";
export default function PenaltyHistory({ socket }) {

  const data = useWallet()
  useEffect(() => {
    if (socket != undefined) {


      socket.emit("wallet:fetchWalletBalance", {
        type: "WALLET_BALANCE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),
        },
      });
    }
  }, [])
  return (
    <>
      <div className="w-[100vw] fixed top-0  md:w-[38%]">
        {/* nav bar */}
        <AppBar socket={socket} context={data}></AppBar>
      </div>
      <div className="p-3 mx-auto bg-white mt-16 h-full">

        {/* wallet button */}
        <PaginationComponent></PaginationComponent>
        <div id="walletList">

        </div>
        {/* All button */}
        <div id="allList" style={{ display: "none" }}>
          <div className="flex mx-auto">
            <input
              type="text"
              className="mr-2 rounded-md w-full px-3 py-1 focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
              placeholder="Enter Room code or UTR number"
              style={{ border: "1px solid #ced4da" }}
            />
            <button className="bg-[#0d6efd] text-white px-3 rounded-md">
              Search
            </button>
          </div>
          <div className="flex flex-col items-stretch px-0 py-3 overflow-auto">
            <ul className="mb-0 pl-0">
              <div
                className="px-1 py-2"
                style={{ borderBottom: "1px solid #d2d2d2ee" }}
              >
                <div className="flex items-stretch">
                  <div
                    className="flex flex-col items-start justify-center"
                    style={{ borderRight: "1px solid #d2d2d2ee", width: 80 }}
                  >
                    <img src="../assets/see.png" alt="" />
                    <span className="text-[0.8rem]">14 Dec</span>
                    <span className="text-[0.8rem]">1:56 pm</span>
                  </div>
                  <div className="flex flex-col items-stretch justify-center flex-1 w-full px-2">
                    <div className="flex items-stretch justify-between">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span className="font-semibold text-start mr-2 text-[0.9rem] w-[220px]">
                            Lost Against Takliya
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-center">
                        <span className="text-red-600 text-end font-bold my-1 text-[0.8rem]">
                          (-) 10
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-xs">Room Code: 01547172</span>
                      <span className="text-start text-xs">
                        Closing Balance: 4.75
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <div className="flex  border-b-2 py-2 ">
            <div className="flex flex-col items-start justify-center ">
              <img src="../assets/folder.png" alt="" />
              <span className="text-xs">11 Dec</span>
              <span className="text-xs">12:53 pm</span>
            </div>
            <div
              className="flex flex-col items-stretch justify-center flex-1 w-full px-2"
              style={{ borderLeft: "2px !important" }}
            >
              <div className="flex items-stretch justify-between">
                <div>
                  <span className="font-semibold text-[0.9rem]">
                    Chips Added Via UPI
                  </span>
                </div>
                <div>
                  <span className="my-1 font-bold text-[0.8rem] text-green-900">
                    (+) 10
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-xs">UTR: 334586345157</span>
                <span className="text-xs">Closing Balance: 10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PenaltyHistory.propTypes = {
  socket: PropTypes.any
};