import React from 'react'
import PropTypes from 'prop-types';

export default function FilledButton({ buttonCaption, bg, textColor, onClick }) {
  return (
    <button className={`${textColor} ${bg === true ? 'bg-[#3b2c23]' : ''} rounded-2xl border text-xs font-custom px-4 py-[6px]`} onClick={onClick}>
      {buttonCaption}
    </button>
  );
}

FilledButton.propTypes = {
  buttonCaption: PropTypes.string,
  bg: PropTypes.string,
  textColor: PropTypes.string,
  onClick: PropTypes.func,
};
