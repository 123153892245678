// eslint-disable-next-line no-undef
module.exports.checkResult = async (challenge_id) => {

    const authToken = localStorage.getItem("token");

    // Configuration for the fetch request
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
        },
        // Convert data to JSON string
    };
    const apiRespone = {};
    try {
        const response = await fetch(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_CHALLENGE_CHECK_RESULT_API}` + challenge_id,
            requestOptions
        );

        const data = await response.json();
        if (response.status === 401) {

            apiRespone["data"] = {};
            apiRespone["message"] = "";
            apiRespone["status"] = 401;

            return apiRespone;
        }
        if (response.status == 400) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        } else if (response.status == 200) {
            apiRespone["data"] = data.data;
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        } else if (response.status == 500) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
    } catch (error) {
        apiRespone["data"] = {};
        apiRespone["message"] = "Something Went Wrong";
        apiRespone["status"] = 300;
        return apiRespone;
    }
};
