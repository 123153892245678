/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

import React from "react"
import AppBar from "../../components/AppBar/AppBar"
import RightContainer from "../../components/Common/RightContainer"
import WarningAlert from "../../components/Alert/WarningAlert";
import supportImg from '../../assets/images/support.webp'
// import HollowButton from "../../components/Button/HollowButton";



export default function PrivacyPolicy({ socket }) {
    return <>
        <div className="flex gap-2">
            <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
                {/* nav bar */}
                <AppBar socket={socket}></AppBar>

                <div className="p-3 mx-auto bg-white min-h-[100vh] ">
                    <div className="paizepool p-0 w-100">
                        <WarningAlert />
                        <div className="flex justify-center">
                            <img src={supportImg} className="p-8 lg:w-9/12 w-full" />
                        </div>
                        {/* <h4><b>Support</b></h4>
                        <p>For any kind of queries, please contact us on the below mention details
                            www.kheldost.com info@kheldost.com
                            To submit your game write to us:
                            <b> info@kheldost.com</b></p>
                        <p style={{ textAlign: 'center' }}><b>or</b></p>
                        <br></br> */}
                    </div>
                    <center>
                        <button

                            className="w-full text-white px-4 py-2 mb-3 rounded-md hover:bg-[#0d6efd]"
                            style={{ backgroundColor: "#0d6efd" }}
                        // onClick={async () => {
                        //     window.open("https://www.whatsapp.com/channel/0029VafmkljGpLHJDTibLA1j", '_blank', 'noopener,noreferrer');
                        // }}
                        >
                            <span className="font-custom text-[0.8rem] flex items-center text-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"><path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg>
                                Live Chat
                            </span>
                        </button>
                        <button

                            className="w-full text-white px-4 py-2 mb-3 rounded-md bg-custom-gradient"
                        // onClick={async () => {
                        //     window.open("https://www.whatsapp.com/channel/0029VafmkljGpLHJDTibLA1j", '_blank', 'noopener,noreferrer');
                        // }}
                        >
                            <span className="font-custom text-[0.8rem] flex items-center text-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"><path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path></svg>
                                Connect On Instagram
                            </span>
                        </button>
                        <button

                            className="w-full text-white px-4 py-2 rounded-md hover:bg-[#157347]"
                            style={{ backgroundColor: "#157347" }}
                        // onClick={async () => {
                        //     window.open("https://www.whatsapp.com/channel/0029VafmkljGpLHJDTibLA1j", '_blank', 'noopener,noreferrer');
                        // }}
                        >
                            <span className="font-custom text-[0.8rem] flex items-center text-center justify-center">
                                <i
                                    className="fa fa-whatsapp mr-2"
                                    aria-hidden="true"
                                />
                                Connect on Whatsapp</span>
                        </button>
                    </center>
                </div>

            </div>
            <RightContainer></RightContainer>
        </div>
    </>

}