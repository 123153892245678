// eslint-disable-next-line no-undef
module.exports = {
  login: "/auth/signin",
  verifyotp: "/auth/verify-otp",


  profile: "/user/get-profile",
  gameHistory: "/user/get-game-history/",
  walletHistory: "/user/get-wallet-history/",
  fetchKYC: "/user/fetch-kyc",
  refferalHistory: "/user/get-game-refferal-history/",
  penaltyHistory: "/user/get-penalty-history/",
  checkToken: "/user/check-token",
  fetchWallet: "/user/fetch-Wallet",
  appSettings: "/auth/get-app-settings",
  sendBonus: "/bonus/payBonus",
  checkGameStatus: '/user/check-game-status',
  register: "/auth/register",
  statement: "/statement/getStatement",

  logout: "/auth/logout",
  update_username: "/user/update-username",
};
