import React from "react";
import AppBar from "../../components/AppBar/AppBar";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import refer from "../../assets/images/closedSign.webp";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { userProfile } from "../../services/user.service";


import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader/Loader";
import PropTypes from 'prop-types';
import { useWallet } from "../../routes/AppRouter";



export default function StoreClosed({ socket }) {
  const data = useWallet()
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);



  useEffect(() => {
    socket.emit("wallet:fetchWalletBalance", {
      type: "WALLET_BALANCE",
      params: "",
      data: {
        auth: localStorage.getItem("token"),
      },
    });
    async function fetchProfile() {
      const results = await userProfile();
      if (results.status == 401) {
        localStorage.clear();
        navigate("/login");
      }

    }
    fetchProfile();
    setLoading(false);
  }, []);

  return (
    <>
      <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
        {/* nav bar */}

        <AppBar socket={socket} context={data}></AppBar>

      </div>

      {loading ? (
        <>
          <div className="p-3 mx-auto bg-white mt-16">
            <center>
              <Loader></Loader>
            </center>
          </div>
        </>
      ) : (
        <div className="p-8 mx-auto bg-white mt-14 h-screen">
          <div className=" border rounded-md mb-4">
            <div className="text-center px-4 py-2 font-custom text-[0.8rem] bg-[#3b2c23] text-white rounded-t">
              Buy Chips Closed
            </div>
            <div className="p-4">

              <p className="text-center font-custom text-[0.8rem] mb-4">Buying Chips is Currently Unavailable because of Security Reasons. Please Come Back Later. It will be Available Soon</p>

              <div className="flex flex-col items-center">
                <div>
                  <img height={'200px'} src={refer} alt="" />
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

StoreClosed.propTypes = {
  socket: PropTypes.any
};
