import React, { useEffect } from "react";
// import { useEffect } from "react";
import { useState } from "react";
import AppBar from "../../components/AppBar/AppBar";
import Loader from "../../components/Loader/Loader";
import RightContainer from "../../components/Common/RightContainer";
import FullFilledButton from "../../components/Button/FullFilledButton";
import { loginUser } from "../../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notify = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Flip,
  });

export default function Login() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  // const [sendOtpVisible, setSendOtpVisible] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, [])
  // useEffect(() => {
  //   if (mobile.length === 10) {
  //     setSendOtpVisible(true);
  //   } else {
  //     setSendOtpVisible(false);
  //   }
  // }, [mobile]);

  return (
    <>
      <ToastContainer />
      <div className="flex gap-2">
        <div className="w-[100vw] fixed top-0  md:w-[38%]">
          <div className="w-[100vw] fixed top-0  md:w-[38%]">
            {/* nav bar */}
            <AppBar></AppBar>
          </div>
          <div className="p-3 mx-auto bg-white mt-16 h-[100vh]">
            <div className="rounded bg-[#fff3cd] p-[0.9rem] text-center mb-3">
              <p className="font-bold text-[0.85rem] text-[#664d03] font-custom">Register Now & Get ₹20 Free Signup Bonus ⭐️</p>
            </div>
            <div>
              {/* login*/}
              <div className=" border rounded-md mb-3">
                <div className="text-center border-b-2 px-4 py-2 bg-[#F8F9FA] font-custom text-[0.9rem]">
                  Login
                </div>
                <div className="p-4">
                  <div className="mb-4 flex flex-col items-start">
                    <label htmlFor="" className="mb-2 text-[0.9rem] font-custom">
                      Mobile Number
                    </label>
                    <div className="self-stretch flex items-center">
                      {/* <input
                        type="text"
                        value="📞"
                        disabled={true}
                        className="border 
                           text-[#664d03] py-1 w-10 rounded-l-md"
                      /> */}
                      <div className="bg-gray-100 p-2 border rounded-l-[0.5rem]">
                        <span className="input-group-text bg-light text-dark">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="0.9em" height="1em" fill="currentColor">
                            <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z">
                            </path>
                          </svg>
                        </span>
                      </div>
                      <input
                        type="tel"
                        value={mobile}
                        onChange={(event) => {
                          if (/^\d*$/.test(event.target.value)) {
                            setMobile(event.target.value);
                          }
                        }}
                        maxLength={10}
                        pattern="\d"
                        className="border rounded-r-md
                           text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                      />
                    </div>
                  </div>

                  <p className="text-center text-[0.7rem] font-custom">
                    By Continuing, you agree to our{" "}
                    <Link to={localStorage.getItem('token') != null ? "/legal" : "/legaloffline"} className="text-[#0d6efd] underline">
                      Legal Terms
                    </Link>{" "}
                    and you are 18 years or older.
                  </p>
                  {loginLoader ? (
                    <>
                      {" "}
                      <center>
                        <div className="py-2">
                          <div
                            className="
                  w-full  px-3 py-[6px] "
                          >
                            <Loader></Loader>
                          </div>
                        </div>
                      </center>
                    </>
                  ) : (
                    <>
                      <div style={{ paddingTop: 18, paddingBottom: 18 }}>
                        <FullFilledButton
                          buttonCaption={"GET OTP"}
                          onClick={async () => {
                            let data = { mobile: mobile };
                            setLoginLoader(true);
                            const result = await loginUser(data);
                            setLoginLoader(false);
                            localStorage.setItem("mobile", data.mobile);
                            //  e.currentTarget.disabled = false;
                            if (result.status !== 200) notify(result.message);
                            else navigate("/verifyOtp", { replace: true });
                          }}
                        ></FullFilledButton>
                      </div>
                    </>
                  )}

                  <p className="mb-3 text-center text-[0.8rem] font-custom">
                    {"Don't have an account?"}
                    <Link to={"/register"} className="text-[#0d6efd] underline">
                      Register
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightContainer></RightContainer>
      </div>
    </>
  );
}
