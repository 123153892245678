/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import navigationBar from "../../assets/images/navigation-bar.png";
import HollowButton from "../../components/Button/HollowButton";
import IconButton from "../../components/Button/IconButton";
import appLogo from "../../assets/images/khel-dost-logo.webp";
import helllo from "../../assets/images/helllo.png";
import user from "../../assets/images/user.svg";
import history from "../../assets/images/history.svg";
import play from "../../assets/images/play.2f22f88bac8acca85f6a.webp";
import Drawer from "react-modern-drawer";
import socketIO from "socket.io-client";
import user_nav from "../../assets/images/profile.svg";
import gift from "../../assets/images/gift.svg";
import wallet from "../../assets/images/wallet.svg";
import support from "../../assets/images/liveChatOffcanvas.4db8ac024d1cc6d424a3.webp";
import legal from '../../assets/images/legal.svg'
import ress from "../../assets/images/cash.jpeg";
import waving from '../../assets/images/hello.a512d06e9ef9c85276f6.webp'
import avatar from '../../assets/images/avatar-m-5.eccc495fab8bd00363e88468877746f6.svg'

import { Link } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import { useSyncExternalStore } from "react";
import { decodeToken } from "react-jwt";
import { useReactPWAInstall } from "react-pwa-install";
import NavItems from "../NavItems/NavItems";
import PropTypes from 'prop-types';
import RefferaIconButton from "../Button/RefferalIconButton";

const isJWT = (token) => {
  try {
    const decodedToken = decodeToken(token);
    if (decodedToken) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const AppBar = ({ socket, context, walletBalance }) => {

  const [localWalletBalance, setLocalWalletBalance] = useState(walletBalance);

  const [refferalWallet, setRefferalWallet] = useState(() => {
    // Initialize with value from localStorage if available
    return Number(localStorage.getItem('refferalWallet')) || 0;
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const socketConnection = socketIO.connect(`${process.env.REACT_APP_CHALLENGE_SOCKET}`, {
      transports: ['websocket'],
    });

    // Fetch the wallet balance when component mounts
    socketConnection.emit('wallet:fetchWalletBalance', {
      type: 'WALLET_BALANCE',
      params: '',
      data: { auth: localStorage.getItem('token') },
    });

    socketConnection.on('wallet:readWalletBalance', (data) => {
      if (data) {
        const totalBalance = data.wallet + data.win_wallet;
        localStorage.setItem('walletBalance', totalBalance);
        localStorage.setItem('refferalWallet', data.refferal_wallet);
        setLocalWalletBalance(totalBalance);
        setRefferalWallet(data.refferal_wallet);
      }
    });

    return () => {
      socketConnection.off('wallet:readWalletBalance');
      socketConnection.disconnect();
    };
  }, []);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const subscribe = (listener) => {
    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    };
  };

  const getSnapShot = () => {
    try {
      const token = localStorage.getItem('token');
      return token ? isJWT(token) : false;
    } catch {
      return false;
    }
  };

  useSyncExternalStore(subscribe, getSnapShot);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        className="bg-white h-[100vh] w-96 fixed z-20 max-w-full left-[-400px] top-0 "
        size={"93vw"}
      >
        <>
          <div className="bg-[#212529] flex items-center justify-between p-4">
            <div className="text-white">
              <h5 className="text-1xl font-semibold font-custom">Khel Dost</h5>
            </div>
            <button type="button" onClick={toggleDrawer}>
              <i className="fa-solid fa-xmark text-white" />
            </button>
          </div>
          <div className="flex flex-col items-stretch justify-start p-0 grow overflow-y-auto">

            <div className="flex flex-col items-stretch justify-start overflow-y-scroll" style={{ maxHeight: "90vh" }}>
              <div className="flex flex-row px-5 py-5 items-center">
                <div className="flex items-center gap-1 flex-grow">
                  <h2 className="text-[26px] font-bold">Welcome</h2>
                  <img src={waving} className="w-8 h-8" />
                </div>
                <div>
                  <img src={avatar} className="w-9 h-9" />
                </div>
              </div>

              <NavItems
                to={"/"}
                image={play}
                name={"Play"}
                onClick={toggleDrawer}
                style={"text-start bg-[#f8f9fa] p-4 flex items-center justify-between "}
              ></NavItems>
              <NavItems
                to={"/wallet"}
                image={wallet}
                name={"Wallet"}
                style={
                  "text-start bg-white p-4 flex items-center justify-between h-47px"
                }
                onClick={toggleDrawer}
              ></NavItems>
              <NavItems
                to={"/game-history"}
                image={history}
                name={"History"}
                style="text-start bg-[#f8f9fa] p-4 flex items-center justify-between h-47px"
                onClick={toggleDrawer}
              ></NavItems>
              <NavItems
                to={"/profile"}
                image={user_nav}
                name={"Profile"}
                style={
                  "text-start bg-white p-4 flex items-center justify-between h-47px"
                }
                onClick={toggleDrawer}
              ></NavItems>
              {/* <NavItems
                to={"/wallet-history"}
                image={history}
                name={"Wallet History"}
                style="text-start bg-[#f8f9fa] p-4 flex items-center justify-between h-47px"
                onClick={toggleDrawer}
              ></NavItems> */}
              {/* <NavItems
                to={"/penalty-history"}
                image={history}
                name={"Penalty History"}
                style="text-start bg-[#f8f9fa] p-4 flex items-center justify-between h-47px"
                onClick={toggleDrawer}
              ></NavItems> */}

              {/* <NavItems
                to={"/refferal-history"}
                image={history}
                name={"Referral History"}
                style="text-start bg-white p-4 flex items-center justify-between h-47px"
                onClick={toggleDrawer}
              ></NavItems> */}

              <NavItems
                to={"/refer"}
                image={gift}
                name={"Refer & Earn"}
                style="text-start bg-[#f8f9fa] p-4 flex items-center justify-between h-47px"
                onClick={toggleDrawer}
              ></NavItems>
              <NavItems
                to={localStorage.getItem('token') != null ? "/support" : "/supportoffline"}
                image={support}
                name={"Support"}
                style="text-start bg-white p-4 flex items-center justify-between h-47px"
                onClick={toggleDrawer}
              ></NavItems>
              <NavItems
                to={localStorage.getItem('token') != null ? "/legal" : "/legaloffline"}
                image={legal}
                name={"Legal Terms"}
                style="text-start bg-white p-4 flex items-center justify-between h-47px"
                onClick={toggleDrawer}
              ></NavItems>
              <br></br>
              <br></br>

              <br></br>
              <br></br>

            </div>
          </div>
        </>
      </Drawer>
      <div className="w-full bg-white drop-shadow-md py-2 px-4 h-16 flex justify-between items-center">
        <div className="flex">
          <button
            onClick={() => {
              if (getSnapShot()) {
                toggleDrawer();
              }
            }}
          >
            {/* <img className="w-6 h-6" src={navigationBar} alt="" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="24" height="24" fill="currentColor">
              <path fillRule="evenodd" d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"></path>
            </svg>
          </button>
          <Link to={"/"}>
            <span>
              <img src={appLogo} alt="kheldost" className="w-10 ml-2" />
            </span>
          </Link>
        </div>
        <div className="flex items-center gap-2">
          {getSnapShot() ? (
            <>
              <RefferaIconButton value={refferalWallet} />
              <IconButton value={localWalletBalance} />
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

AppBar.propTypes = {
  socket: PropTypes.any,
  context: PropTypes.any,
  walletBalance: PropTypes.number.isRequired,
};

export default AppBar;
