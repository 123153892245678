/* eslint-disable no-unused-vars */
import React from "react";
import AppBar from "../../components/AppBar/AppBar";
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from 'prop-types';



import { useEffect } from "react";


import { ToastContainer } from "react-toastify"

import { useState } from "react";

import { checkAllChallenges } from "../../services/challenge.service";

import { useNavigate } from "react-router-dom";


import BigRoomList from "../../components/Room List/BigRoomList";
import AppRoomList from "../../components/App Room List/AppRoomList";
import AutoViewChallengeCard from "../../components/Challenges/AutoViewChallengeCard";
import { Helmet } from 'react-helmet';
export default function AppAutoPlay({ socket, description, keywords, title, canonicalUrl }) {

    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    // const [apiloader, setApiLoader] = useState(false)

    const [opengames, setOpenGames] = useState([]);
    const [gameCount50, setGameCount50] = useState(0);
    const [gameCount100, setGameCount100] = useState(0);
    const [gameCount150, setGameCount150] = useState(0);
    const [gameCount200, setGameCount200] = useState(0);
    const [gameCount250, setGameCount250] = useState(0);
    const [gameCount300, setGameCount300] = useState(0);
    const [gameCount350, setGameCount350] = useState(0);
    const [gameCount400, setGameCount400] = useState(0);
    const [gameCount450, setGameCount450] = useState(0);
    const [gameCount500, setGameCount500] = useState(0);









    const [play, setPlay] = useState([]);

    const allOpenChallenges = async () => {
        //setApiLoader(true)


        const response = await checkAllChallenges()
        // setApiLoader(false)
        if (response.status == 401) {
            localStorage.clear()
            navigate("/login")
        }
        if (response.status == 200) {
            console.log(response.data);
            console.log(opengames);
            console.log(play);
            setPlay(response.data.open_challenges)
            setOpenGames(response.data.user_open_challenges)

            console.log(play.length);
            console.log(opengames.length);
            // if (response.data.open_challenges == 0) {
            //     localStorage.removeItem("creator_token")
            //     localStorage.removeItem("opponent_token")
            //     localStorage.removeItem("challenge_id")

            // }


        }
    }

    useEffect(() => {
        // eslint-disable-next-line no-undef

        allOpenChallenges()

    }, [])
    useEffect(() => {

        if (socket != undefined) {


            socket.emit("wallet:fetchWalletBalance", {
                type: "WALLET_BALANCE",
                params: "",
                data: {
                    auth: localStorage.getItem("token"),
                },
            });
        }
        socket.on('connect', () => {
            console.log('Connected to server');
        });

        // Join the matchmaking queue
        socket.on('showSmallCount', async (game) => {
            console.log("fdfddfdf");
            console.log(game);
            setGameCount50(game.count50 * 2)
            setGameCount100(game.count100 * 2)
            setGameCount150(game.count150 * 2)
            setGameCount200(game.count200 * 2)
            setGameCount250(game.count250 * 2)
            setGameCount300(game.count300 * 2)
            setGameCount350(game.count350 * 2)
            setGameCount400(game.count400 * 2)
            setGameCount450(game.count450 * 2)
            setGameCount500(game.count500 * 2)









        })
        socket.on('smallChallengeAccepted', async (challenges) => {

            if (challenges.role == "creator") {
                console.log(challenges);
                try {
                    navigate(`/challenge-result/${challenges.challenge._id}`);




                }
                catch (e) {
                    console.log(e)
                }
            }

            if (challenges.role == "opponent") {



                try {
                    navigate(`/challenge-result/${challenges.challenge._id}`);

                } catch (e) {
                    console.log(e)

                }

            }
            // catch (e) {
            //     console.log(e)
            // }

        }





        );

        socket.on('challengeCreated', (challenge) => {
            // alert(`Challenge created: ${challenge._id}`);
            console.log(challenge);
        });

        socket.on('challengeTimeout', ({ challenge_id }) => {
            //alert(`Challenge timed out: ${challenge_id}`);
            console.log('challengeTimeout');
            console.log(challenge_id);
        });

        socket.on('challengeNotFound', ({ challenge_id }) => {
            console.log('challengeNotFound');
            console.log(challenge_id);

            // alert(`Challenge not found: ${challenge_id}`);
        });


        socket.on('waiting', () => {
            setLoader(true)
            console.log('Waiting for opponent...');
        });



        socket.on('disconnect', () => {
            console.log('Disconnected from server');
        });
        return () => {
            socket.off("matched");
            socket.off("challengeAccepted")
            socket.off("challengeCreated")
            socket.off("challengeTimeout")
            socket.off("challengeNotFound")
            socket.off("showSmallCount")
        }

    }, [socket])

    const handleCancel = () => {
        if (socket) {
            console.log("test");
            socket.emit('smallCancelChallenge', {
                type: "WALLET_BALANCE",
                params: "",
                data: {
                    auth: localStorage.getItem("token"),
                    amount: 10
                },
            }); // Emit cancelRequest event when user cancels
        }
    };
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                handleCancel(); // Call cancel logic when tab or window is hidden
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [socket]);




    return (
        <>
            <ToastContainer></ToastContainer>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta httpEquiv="content-language" content="en" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>

            <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
                {/* nav bar */}
                <AppBar socket={socket} context={""} ></AppBar>
            </div>



            <div className="p-3 mx-auto bg-white mt-[64px] h-full min-h-[100vh] ">

                {/* card 1 */}

                {opengames.length > 0 ? <>


                    {opengames.map((value, index) => {



                        if (value.status == "started") {
                            return (
                                <>
                                    <AutoViewChallengeCard
                                        key={index}
                                        prize={value.prize_money}
                                        challenge_id={value._id}
                                        entry={value.entry_fee}
                                        user_id={value.user_id._id}
                                        status={value.status}
                                        username={value.u_name}
                                        socket={socket}
                                        gameCount={gameCount50}
                                    ></AutoViewChallengeCard>
                                    <BigRoomList entry={100} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={200} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={300} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={350} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={400} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={450} available={false} socket={socket} loader={loader}></BigRoomList>
                                    <BigRoomList entry={500} available={false} socket={socket} loader={loader}></BigRoomList>

                                </>
                            );
                        }




                    })}

                    {/* {play.map((value, index) => {



                            if (value.status == "started") {
                                return (
                                    <WebViewChallengeCard
                                        key={index}
                                        prize={value.prize_money}
                                        challenge_id={value._id}
                                        entry={value.entry_fee}
                                        user_id={value.user_id._id}
                                        status={value.status}
                                        username={value.u_name}
                                        socket={socket}
                                    ></WebViewChallengeCard>
                                );
                            }




                        })} */}
                </> : <>

                    <AppRoomList entry={50} gameCount={gameCount50} available={true} socket={socket} loader={loader}></AppRoomList>
                    <BigRoomList entry={100} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={150} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={200} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={250} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={300} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={350} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={400} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={450} available={false} socket={socket} loader={loader}></BigRoomList>
                    <BigRoomList entry={500} available={false} socket={socket} loader={loader}></BigRoomList>






                </>}

            </div>



        </>
    );
}

AppAutoPlay.propTypes = {
    socket: PropTypes.any,
    description: PropTypes.any,
    keywords: PropTypes.any,
    title: PropTypes.any,
    canonicalUrl: PropTypes.any

};