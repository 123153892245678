/* eslint-disable no-unused-vars */
import React from "react";
import cup from "../../assets/images/cup.png";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { checkToken } from "../../services/auth.service";
import { viewGameChallenge } from "../../services/challenge.service";

export default function AutoViewChallengeCard({
    // eslint-disable-next-line no-unused-vars
    challenge_id,
    entry,
    gameCount,
    username,
    socket,
}) {
    const navigate = useNavigate();
    return (
        <div className=" border rounded-md mb-3 ">
            <div className="border-b-2 px-4 pb-2 bg-[#3b2c23] flex justify-between items-center ">
                <p className="mt-2 font-custom">Current Playing: {gameCount}</p>

            </div>
            <div className="p-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <img src={cup} alt="" className="w-6" />
                    <span className="text-green-700 text-xl font-bold">Rs.{entry}</span>
                    {/* <span className=" font-semibold">{username}</span> */}
                </div>
                <button
                    className="px-2 py-1 border rounded-md  text-white bg-green-500"
                    // eslint-disable-next-line no-unused-vars
                    onClick={async (e) => {
                        const response = await checkToken();
                        if (response.status == 401) {
                            navigate("/login")
                            localStorage.clear()

                        }
                        socket.emit("challenge:joinRoom", {
                            type: "CHALLENGE_REQUEST_DECLINE",
                            params: "",
                            data: {
                                auth: localStorage.getItem("token"),

                                challenge_id: challenge_id,
                            },
                        });

                        navigate(`/challenge-result/${challenge_id}`);
                    }}
                >
                    View
                </button>
            </div>
        </div>
    );
}

AutoViewChallengeCard.propTypes = {
    socket: PropTypes.any,
    challenge_id: PropTypes.string,
    username: PropTypes.string,
    entry: PropTypes.any,
    gameCount: PropTypes.any
};
