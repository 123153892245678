import React from 'react'
import PropTypes from 'prop-types';

export default function InfoAlert({ message }) {
  return (
    <div className="p-4 bg-[#cfe2ff] text-[#084298] border rounded-md border-[#b6d4fe] text-[0.7rem] text-center mb-4 font-custom">
      {message}
    </div>
  );
}

InfoAlert.propTypes = {
  message: PropTypes.string
};

