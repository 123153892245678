import React from "react";
import user from "../../assets/images/user.svg";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { checkToken } from "../../services/auth.service";
import { submitGameChallenge, viewGameChallenge } from "../../services/challenge.service";
import Cookies from "universal-cookie";
import { checkGameStatus } from "../../services/user.service";


export default function WebStartChallengeCard({
  challenge_id,
  entry,
  username,
  socket,
}) {
  const navigate = useNavigate();
  return (
    <div className=" border rounded-md mb-3 ">
      <div className="border-b-2 px-4 pb-2 bg-[#F7F7F7] flex justify-between items-center ">
        <p className="mt-2">Challenge set by...</p>
        <p className="text-green-700 text-xl font-bold">Rs.{entry}</p>
      </div>
      <div className="p-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <img src={user} alt="" className="w-6" />
          <span className=" font-semibold">{username}</span>
        </div>
        <button
          className="px-2 py-1 border rounded-md  text-white bg-[#0d6efd] "
          // eslint-disable-next-line no-unused-vars
          onClick={async (e) => {
            const response = await checkToken();
            if (response.status == 401) {
              navigate("/login")
              localStorage.clear()

            }
            if (response.status == 200) {
              socket.emit("gameChallenge:requestAcceptByOppnent", {
                type: "CHALLENGE_REQUEST_DECLINE",
                params: "",
                data: {
                  auth: localStorage.getItem("token"),

                  challenge_id: challenge_id,
                },
              });

              socket.emit("gameChallenge:joinRoom", {
                type: "CHALLENGE_REQUEST_DECLINE",
                params: "",
                data: {
                  auth: localStorage.getItem("token"),

                  challenge_id: challenge_id,
                },
              });

              localStorage.removeItem("creator_token")

              localStorage.setItem("opponent_token", localStorage.getItem("token"))
              localStorage.setItem("challenge_id", challenge_id)
              const cookies = new Cookies(null, { path: '/' });

              cookies.set('game_data', JSON.stringify({ creator_token: localStorage.getItem("token"), challenge_id: challenge_id }));
              console.log(cookies.get('game_data')); // Pacman

              try {
                const challenge_data = { challenge_id: challenge_id }
                const challenge = await viewGameChallenge(challenge_data)
                const checkbot = await checkGameStatus()
                console.log(challenge.data)

                const submitGameData = {
                  challenge_id: challenge_id,
                  type: "2token",
                  creator_token: "",
                  creator_username: "",
                  opponent_token: localStorage.getItem("token"),
                  opponent_username: challenge.data.o_name,
                  room_code: challenge.data.room_code,
                  entry_fee: challenge.data.entry_fee,
                  bot_play: challenge.play,
                  is_bot: checkbot.game





                }
                try {
                  const response = await submitGameChallenge(submitGameData)
                  console.log(response)
                  navigate("/play-game")
                } catch (e) {
                  console.log(e)

                }
              }
              catch (e) {
                console.log(e)
              }
              //  navigate(`/challenge-result/${challenge_id}`);

            }
          }}
        >
          Start
        </button>
      </div>
    </div>
  );
}

WebStartChallengeCard.propTypes = {
  socket: PropTypes.any,
  challenge_id: PropTypes.string,
  username: PropTypes.string,
  entry: PropTypes.any,
};
