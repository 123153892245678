import React, { useState, useEffect } from 'react';
import { getRefferalHistory } from '../../services/user.service';
import { useNavigate } from 'react-router-dom';
import folder from "../../assets/images/folder.png";
const PaginationComponent = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    useEffect(() => {
        fetchData();
    }, [currentPage]); // Fetch data when currentPage changes

    const fetchData = async () => {
        try {
            const response = await getRefferalHistory(currentPage)
            if (response.status == 401) {
                localStorage.clear()
                navigate("/login")
            }
            if (response.status == 200)
                setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const goToNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    return (
        <div>
            {data.length == 0 ? <center> No Records</center> : <>            {/* Render data */}
                <ul>
                    {data.map(item => (

                        <li key={item._id}><div className="flex  border-b-2 py-2 ">
                            <div className="flex flex-col w-[15%] items-start justify-center ">
                                <img src={folder} className='w-5 h-5' alt="" />
                                <span className="text-[0.6rem] font-custom">{new Date(item.createdAt).toDateString()}</span>
                            </div>
                            <div className='vertical-separator w-[1px] ml-2 bg-gray-300'></div>
                            <div
                                className="flex flex-col items-stretch justify-center flex-1 w-full px-2"
                                style={{ borderLeft: "2px !important" }}
                            >
                                <div className="flex items-stretch justify-between">
                                    <div>
                                        <span className="font-semibold font-custom text-[0.8rem]">
                                            {item.remark}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="my-1 font-bold text-[0.7rem] font-custom text-green-900">
                                            {item.type == 2 ? `(-) ${item.amount}` : `(+) ${item.amount}`}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">

                                    <span className="text-[0.6rem] font-custom">Closing Balance: {item.close_balance}</span>
                                </div>
                            </div>
                        </div></li>
                    ))}
                </ul>

                {/* Pagination controls */}
                <div className="flex items-center justify-between">
                    <button className='px-2 py-1 w-[40%] mt-3 rounded-l text-white bg-[#3b2c23] text-sm' onClick={goToPreviousPage} disabled={currentPage === 1}>Previous</button>
                    <button className='px-2 py-1 w-[40%] mt-3 text-white bg-[#554135] text-sm'>1</button>
                    {data.length == 0 ? <></> :
                        <button className='px-2 py-1 w-[40%] mt-3 rounded-r disabled text-white bg-[#3b2c23] text-sm' onClick={goToNextPage}>{"Next >"}</button>
                    }
                </div>
            </>}

        </div>

    );
};

export default PaginationComponent;
