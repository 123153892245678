import React, { useEffect, useRef, useState } from "react";
import RightContainer from "../../components/Common/RightContainer";
import AppBar from "../../components/AppBar/AppBar";
import FullFilledButton from "../../components/Button/FullFilledButton";
import BackButton from "../../components/Button/BackButton";
import { Flip } from "react-toastify";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { loginUser, verifyUser } from "../../services/auth.service";
import PropTypes from 'prop-types';
import { useWallet } from "../../routes/AppRouter";

const notify = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Flip,
  });

export default function VerifyOtp({ socket }) {
  const data = useWallet();
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const [updatedOtp, setUpdatedOTP] = useState("");
  const [visibleOtp, setVisibleOTP] = useState(false);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const navigate = useNavigate()
  const inputRefs = useRef([]);

  const [otpSent, setOtpSent] = useState(false);
  const [cooldown, setCooldown] = useState(0);

  useEffect(() => {
    setOtpSent(true);
    setCooldown(30);
  }, [otpSent]);

  useEffect(() => {
    let timer;

    // Start countdown when OTP is sent
    if (otpSent && cooldown > 0) {
      timer = setTimeout(() => {
        setCooldown(cooldown - 1);
      }, 1000); // Countdown every second
    }

    // Reset cooldown when it reaches 0
    if (cooldown === 0) {
      setOtpSent(false);
    }

    // Clear timer when component unmounts
    return () => clearTimeout(timer);
  }, [otpSent, cooldown]); // Dependency array ensures effect runs when otpSent or cooldown changes

  const handleResendOTP = async () => {
    // Simulate sending OTP (set your actual OTP sending logic here)
    let data = { mobile: localStorage.getItem('mobile') };

    const result = await loginUser(data);


    //  e.currentTarget.disabled = false;
    if (result.status !== 200) notify(result.message);
    else {
      setOtpSent(true);
      setCooldown(30);
    }

    // Set cooldown to 30 seconds
  };

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (e.nativeEvent.inputType === 'insertText' && e.nativeEvent.data === ' ') {
      e.preventDefault();
      return;
    }
    if (isNaN(value)) {
      return;
    } else {
      const newOtp = [...otp];
      newOtp[index] = value.substring(value.length - 1);
      setOtp(newOtp);

      // submit trigger
      const combinedOtp = newOtp.join("");
      if (combinedOtp.length === 6) {
        onOtpSubmit(combinedOtp);
        setVisibleOTP(true);
      } else {
        setVisibleOTP(false);
      }

      // Move to next input if current field is filled
      if (value && index < 6 - 1 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const onOtpSubmit = (value) => {
    setUpdatedOTP(value);
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n        .otp-button{\n            padding: 4px 8px;\n            display: flex;\n            align-items: center;\n            border: 1px solid #212529;\n            border-radius: 0.375rem;\n            color: #212529;\n        }\n        .otp-button:hover{\n            background-color: #212529;\n            color: #fff;\n        }\n    ",
        }}
      />

      <div className="flex gap-2">
        <ToastContainer></ToastContainer>

        <div className="w-[100vw] fixed top-0  md:w-[38%]">
          <div className="w-[100vw] fixed top-0  md:w-[38%]">
            {/* nav bar */}
            <AppBar socket={socket} context={data}></AppBar>
          </div>
          <div className="p-3 mx-auto bg-white mt-16 h-[100vh]">

            <div>

              {/* login*/}
              <div className=" border rounded-md mb-3">
                <div className="text-center border-b-2 px-4 py-2 bg-[#F8F9FA] text-[0.9rem] font-custom">
                  Verify Otp
                </div>
                <div className="p-4">
                  <div className="flex items-center justify-start py-3">
                    <BackButton></BackButton>
                  </div>
                  {/* otp input */}
                  <div>
                    <div className="justify-between py-6 flex gap-2">
                      {otp.map((value, index) => {
                        return (
                          <div className="flex items-center" key={index}>
                            <input
                              type="tel"
                              value={value}
                              ref={(input) =>
                                (inputRefs.current[index] = input)
                              }
                              onChange={(e) => handleChange(index, e)}
                              maxLength={1}
                              onClick={() => handleClick(index)}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              pattern="\d"
                              required=""
                              style={{ flex: "1 1 0%", textAlign: "center" }}
                              className="otp-input border border-[#ced4da] rounded-md text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="flex items-center justify-between mt-4 mb-4">
                    <button className="px-2 py-1 rounded text-[0.8rem] bg-[#3b2c23] text-[#fff]" disabled={otpSent} onClick={handleResendOTP}>Resend OTP</button>
                    {otpSent && (
                      <center><p className="text-[0.6rem] font-custom">Resend OTP in <span className="text-[#37c816]">{cooldown}</span> seconds</p></center>
                    )}
                    <button className="px-2 py-1 rounded text-[0.8rem] border">OTP via call</button>
                  </div>
                  <p className="text-center text-[0.7rem] font-custom">
                    By Continuing, you agree to our{" "}
                    <Link to={localStorage.getItem('token') != null ? "/legal" : "/legaloffline"} className="text-[#0d6efd] underline">
                      Legal Terms
                    </Link>{" "}
                    and you are 18 years or older.
                  </p>
                  {loadingOTP ? (
                    <center>
                      <div className="py-2">
                        <div
                          className="
                  w-full  px-3 py-[6px] "
                        >
                          <Loader></Loader>
                        </div>
                      </div>
                    </center>
                  ) : (
                    <>
                      {visibleOtp ? (
                        <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                          <FullFilledButton
                            onClick={async () => {
                              let data = {
                                mobile: localStorage.getItem("mobile"),
                                otp: updatedOtp,
                              };
                              setLoadingOTP(true);
                              const result = await verifyUser(data);
                              setLoadingOTP(false);

                              //  e.currentTarget.disabled = false;
                              if (result.status !== 200) notify(result.message);
                              else {
                                localStorage.setItem(
                                  "token",
                                  result.data.token
                                );
                                navigate('/', { replace: true });



                              }
                            }}
                            buttonCaption={"Verify OTP"}
                          ></FullFilledButton>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightContainer></RightContainer>
      </div>
    </>
  );
}

VerifyOtp.propTypes = {
  socket: PropTypes.any
};
