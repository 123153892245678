import React from "react";
import AppBar from "../../components/AppBar/AppBar";

import { Flip } from "react-toastify";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { checkAllChallenges, createChallenge } from "../../services/challenge.service";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';

import { useWallet } from "../../routes/AppRouter";
import WebViewChallengeCard from "../../components/WebChallenges/WebViewChallengeCard";
import WebOnevsOneCard from "../../components/WebChallenges/WebOnevsOneCard";
import WebWatingCard from "../../components/WebChallenges/WebWatingCard";
import WebRequestedCard from "../../components/WebChallenges/WebRequestedCard";
import WebChallengesCard from "../../components/WebChallenges/WebChallengesCard";

import WebVScard from "../../components/WebChallenges/WebVScard";
import WebStartChallengeCard from "../../components/WebChallenges/WebStartChallengeCard";

function isAmountInRange(amount) {
  return amount >= 50 && amount <= 10000;
}
export default function WebGameChallenges({ socket }) {
  const navigate = useNavigate();
  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });
  const [opengames, setOpenGames] = useState([]);
  const [play, setPlay] = useState([]);
  const [amount, setAmount] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const data = useWallet();
  // useEffect(() => {
  //   
  //   if (socket != undefined) {
  //     // socket.emit("wallet:fetchWalletBalance", {
  //     //   type: "WALLET_BALANCE",
  //     //   params: "",
  //     //   data: {
  //     //     auth: localStorage.getItem("token"),
  //     //   },
  //     // });
  //   }
  // }, [])

  useEffect(() => {
    allOpenChallenges()

  }, [])
  function customSort(a, b) {

    // New condition: if status is "requested" and opponent_token matches localStorage token
    if (a.status === "requested" && a.opponent_token === localStorage.getItem("token")) {
      return -1;  // a should come first
    }
    if (b.status === "requested" && b.opponent_token === localStorage.getItem("token")) {
      return 1;   // b should come first
    }
    if (a.status === "accepted" && a.opponent_token === localStorage.getItem("token")) {
      return -1;  // a should come first
    }
    if (b.status === "accepted" && b.opponent_token === localStorage.getItem("token")) {
      return 1;   // b should come first
    }
    // New condition: if status is "requested" and opponent_token matches localStorage token
    if (a.status === "started" && a.opponent_token === localStorage.getItem("token")) {
      return -1;  // a should come first
    }
    if (b.status === "started" && b.opponent_token === localStorage.getItem("token")) {
      return 1;   // b should come first
    }

    if (a.status === "created" && b.status !== "created") {
      return -1;
    }
    if (a.status !== "created" && b.status === "created") {
      return 1;
    }
    return 0;
  }
  const allOpenChallenges = async () => {
    if (socket != undefined) {


      socket.emit("wallet:fetchWalletBalance", {
        type: "WALLET_BALANCE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),
        },
      });
    }
    const response = await checkAllChallenges()
    if (response.status == 401) {
      localStorage.clear()
      navigate("/login")
    }
    if (response.status == 200) {

      setPlay(response.data.open_challenges)
      setOpenGames(response.data.user_open_challenges)

    }
  }

  useEffect(() => {
    socket.on("view:removeChallengeFromPlay", (data) => {
      console.log("data to removed");
      console.log(data);
      console.log(play);
      for (var i = 0; i < play.length; ++i) {
        if (play[i]._id == data) {
          play.splice(i, 1)
        }
      }
      setPlay([...play])

    })

    socket.on("gameChallenge:rejectRequestByAuthor", (data) => {
      console.log("dddsdddsfsdff");
      console.log(play);



      if (data.owner_token == localStorage.getItem("token")) {
        console.log("yahhoo");
        setOpenGames([data]);
      }

      console.log("Data item");
      console.log(data)

      const updatedItems = [...play];
      for (var i = 0; i < play.length; ++i) {
        if (play[i]._id == data._id) {
          console.log("Checked");
          updatedItems[i] = data;
        }
      }
      console.log(updatedItems);
      updatedItems.sort(customSort)
      setPlay(updatedItems);

      if (data.owner_token != localStorage.getItem("token")) {
        setPlay(updatedItems);
        console.log("jjjj");
      }
      updatedItems.sort(customSort)
      setPlay(updatedItems);
      //setOpenGames([data]);
    });
    socket.on("gameChallenge:requestAcceptedByAuthor:" + localStorage.getItem('token'), (data) => {
      socket.emit("gameChallenge:joinRoom", {
        type: "CHALLENGE_REQUEST_DECLINE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),

          challenge_id: data._id,
        },
      });

      navigate(`/challenge-result/${data._id}`);

    })
    socket.on("gameChallenge:rejectRequestByOpponent " + localStorage.getItem('token'), (data) => {
      console.log("dddsdddsfsdff");
      console.log(play);




      // if (data.owner_token == localStorage.getItem("token")) {
      //   console.log(data);
      //   console.log("yahhoo");
      //   setOpenGames([data]);

      // }

      // console.log("Data item");
      // console.log(data)
      setOpenGames([])
      play.unshift(data)
      play.sort(customSort)
      setPlay(play)



      //setOpenGames([data]);
    });
    socket.on("gameChallenge:rejectRequest", (data) => {
      console.log("dddsdddsfsdff");
      console.log(data);



      if (data.owner_token == localStorage.getItem("token")) {
        console.log("yahhoo");
        setOpenGames([data]);
      }

      console.log("Data item");
      console.log(data)

      const updatedItems = [...play];
      for (var i = 0; i < play.length; ++i) {
        if (play[i]._id == data._id) {
          console.log("Checked");
          updatedItems[i] = data;
        }
      }

      updatedItems.sort(customSort)
      setPlay(updatedItems);
      if (data.owner_token != localStorage.getItem("token")) {
        setPlay(updatedItems);
        console.log("jjjj");
      }
      try {
        if (opengames[0]._id == data._id && opengames[0].opponent_token == localStorage.getItem("token")) {

          console.log("jjj...j");
          updatedItems.unshift(data)
          setPlay(updatedItems)
          setOpenGames([])
        }
        console.log("Data iss");
        console.log(data);
        console.log(opengames);

      } catch (error) {
        console.log(error);
      }


      console.log(updatedItems);

      //setOpenGames([data]);
    });





    socket.on("gameChallenge:read", (data) => {




      if (data.token == localStorage.getItem("token")) {
        const isDuplicate = opengames.some(game => game._id === data.challenge._id);

        if (!isDuplicate) {
          // If it's not a duplicate, add it to opengames
          opengames.unshift(data.challenge);
          setOpenGames([...opengames]);
        }


      }
      else {

        const isPlayDuplicate = play.some(game => game._id === data.challenge._id);
        if (!isPlayDuplicate) {
          play.push(data.challenge)
          //  play.sort(customSort)
          play.sort(customSort)
          setPlay([...play])

        }

      }


    });

    socket.on("gameChallenge:requestAcceptedByAuthor", (data) => {

      try {
        const updatedItems = [...play];
        for (var i = 0; i < play.length; ++i) {
          if (play[i]._id == data._id) {
            updatedItems[i] = data;
          }
        }


        updatedItems.sort(customSort)
        setPlay(updatedItems);
        if (opengames[0]._id == data._id && opengames[0].opponent_token == localStorage.getItem("token")) {

          console.log("jjj...j");
          updatedItems.unshift(data)
          setPlay(updatedItems)
          setOpenGames([])
          console.log("Request Accepted by Author");
          console.log(play);
        }

      } catch (error) {
        console.log(error);

      }

    });
    socket.on("gameChallenge:remove", (data) => {



      const updatedData = opengames.filter((item) => item._id !== data);
      setOpenGames(updatedData);

      const playData = play.filter((item) => item._id !== data);
      playData.sort(customSort)
      setPlay(playData);
    });

    socket.on("gameChallenge:sendRequest", (data) => {


      // Create a copy of the original array
      // Update the specific eleme   nt

      console.log("Request Accepted by Author");
      console.log(play);


      if (data.owner_token == localStorage.getItem("token")) {
        setOpenGames([data]);
      } else {

        const updatedItems = [...play];

        for (var i = 0; i < play.length; ++i) {
          if (play[i]._id == data._id) {

            updatedItems[i] = data;
          }
        }
        updatedItems.sort(customSort)


        setPlay(updatedItems);






        //socket.emit("gameChallenge:read", data);
      }



    });
  }, [socket, opengames, play]);
  // useEffect(() => {
  //   socket.emit("wallet:fetchWalletBalance", {
  //     type: "WALLET_BALANCE",
  //     params: "",
  //     data: {
  //       auth: localStorage.getItem("token"),
  //     },
  //   });


  // }, [])

  return (
    <>
      <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
        {/* nav bar */}
        <AppBar socket={socket} context={data}></AppBar>
      </div>
      <div className="p-3 mx-auto bg-white mt-[64px] h-full min-h-[100vh] ">
        <ToastContainer />
        <div className="bg-[#F8F9FA] p-3 mb-3">
          <div className=" flex flex-nowrap w-full ">
            <input
              type="text"
              placeholder="Amount "
              value={amount}
              onChange={(event) => {
                if (/^\d*$/.test(event.target.value)) {
                  setAmount(event.target.value);
                }
              }}
              className="w-full border border-e-gray-300 px-3 py-[6px] 
                  rounded-l-lg focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
            />
            <button
              className="bg-[#0d6efd] text-white w-1/4 px-3 py-1 rounded-r-lg "
              style={{}}
              disabled={isDisabled}
              // eslint-disable-next-line no-unused-vars
              onClick={async (e) => {
                if (amount == "") {
                  notify("Amount Should be greater than 0");
                  return;
                }
                if (isAmountInRange(parseInt(amount)) == false) {
                  notify("Amount Should be greater than 50 but below 1000");
                  return;
                }
                if (parseInt(amount) % 50 == 0) {
                  const data = {
                    game_type: 'web',
                    entry_fee: parseInt(amount),
                    game_id: "65bf89584628069304705ddc",
                  };
                  setIsDisabled(true);
                  const result = await createChallenge(data);

                  //  e.currentTarget.disabled = false;
                  if (result.status == 401) {
                    localStorage.clear();
                    navigate("/login");
                  }
                  if (result.status != 200) notify(result.message);
                  setIsDisabled(false);

                  if (result.status == 200) {
                    socket.emit("gameChallenge:create", result.data);
                    setAmount("")
                  }

                  return;
                }

                notify("Amount Should be Valid");
              }}
            >
              Set
            </button>
          </div>
        </div>
        {/* card 1 */}

        {opengames.map((value, index) => {

          if (value.status == "requested" && value.type == "opponent")
            return (
              <WebRequestedCard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id._id}
                status={value.status}
                username={value.u_name}
                socket={socket}
                type={"open"}
              ></WebRequestedCard>
            );
          if (value.status == "accepted") {
            return (
              <WebStartChallengeCard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id._id}
                status={value.status}
                username={value.u_name}
                socket={socket}
              ></WebStartChallengeCard>
            );
          }
          if (value.status == "started") {
            return (
              <WebViewChallengeCard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id._id}
                status={value.status}
                username={value.u_name}
                socket={socket}
              ></WebViewChallengeCard>
            );
          }
          if (value.opponent_id != null) {
            return (
              <WebOnevsOneCard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id}
                status={value.status}
                o_name={value.o_name}
                username={value.u_name}
                socket={socket}
              ></WebOnevsOneCard>
            );
          }


          return (
            <WebWatingCard
              key={index}
              prize={value.prize_money}
              challenge_id={value._id}
              entry={value.entry_fee}
              user_id={value.user_id._id}
              status={value.status}
              username={value.u_name}
              socket={socket}
            ></WebWatingCard>
          );
        })}
        {play.map((value, index) => {
          console.log(value)
          if (value.status == "created")
            return (
              <WebChallengesCard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id}
                status={value.status}
                username={value.u_name}
                socket={socket}
              ></WebChallengesCard>
            );
          if (value.status == "requested" && value.opponent_token == localStorage.getItem("token"))
            return (
              <WebRequestedCard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id}
                status={value.status}
                username={value.u_name}
                socket={socket}
                type="play"
              ></WebRequestedCard>
            );

          if (value.status == "requested" && value.opponent_token != localStorage.getItem("token"))
            return (
              <WebVScard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id}
                status={value.status}
                o_name={value.o_name}
                username={value.u_name}
                socket={socket}
              ></WebVScard>
            );
          if (value.status == "accepted" && value.opponent_token == localStorage.getItem("token"))
            return (
              <WebStartChallengeCard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id}
                status={value.status}
                username={value.u_name}
                socket={socket}
              ></WebStartChallengeCard>
            );
          if (value.status == "accepted" && value.opponent_token != localStorage.getItem("token"))
            return (
              <WebVScard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id}
                status={value.status}
                o_name={value.o_name}
                username={value.u_name}
                socket={socket}
              ></WebVScard>
            );
          if (value.status == "started")
            return (
              <WebVScard
                key={index}
                prize={value.prize_money}
                challenge_id={value._id}
                entry={value.entry_fee}
                user_id={value.user_id}
                status={value.status}
                o_name={value.o_name}
                username={value.u_name}
                socket={socket}
              ></WebVScard>
            );
        })}
      </div>
    </>
  );
}

WebGameChallenges.propTypes = {
  socket: PropTypes.any
};
