import Spinner from "react-spinner-material";
import React from "react";

export default function Loader() {
  return (
    <div>
      <Spinner radius={12} color={"#333"} stroke={2} visible={true} />
    </div>
  );
}
