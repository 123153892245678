/* eslint-disable no-undef */
// eslint-disable-next-line no-undef
const CryptoJS = require("crypto-js")

const encryptData = (data, key) => {
    const encryptedData = CryptoJS.AES.encrypt(data, key).toString();
    return encryptedData;
};



// module.exports.createPhonePeOrder = async (amount) => {


//     // Data to be sent in the POST request

//     const postData = {
//         order_amount
//             : amount
//     };
//     const authToken = localStorage.getItem("token");

//     // Configuration for the fetch request
//     const requestOptions = {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
//         },
//         body: JSON.stringify(postData), // Convert data to JSON string
//     };
//     const apiRespone = {};
//     try {
//         const response = await fetch(
//             // eslint-disable-next-line no-undef
//             `${process.env.REACT_APP_CREATE_ORDER_PHONE_PE}`,
//             requestOptions
//         );
//         if (response == undefined) {
//             apiRespone["data"] = {};
//             apiRespone["message"] = "";
//             apiRespone["status"] = 500;

//             return apiRespone;
//         }

//         const data = await response.json();
//         if (response.status === 401) {

//             apiRespone["data"] = {};
//             apiRespone["message"] = "";
//             apiRespone["status"] = 401;

//             return apiRespone;
//         }
//         if (response.status == 400) {
//             apiRespone["data"] = {};
//             apiRespone["message"] = data.message;
//             apiRespone["status"] = response.status;

//             return apiRespone;
//         } else if (response.status == 200) {
//             apiRespone["data"] = data.data;
//             apiRespone["message"] = data.message;
//             apiRespone["status"] = response.status;

//             return apiRespone;
//         } else if (response.status == 500) {
//             apiRespone["data"] = {};
//             apiRespone["message"] = data.message;
//             apiRespone["status"] = response.status;

//             return apiRespone;
//         }
//     } catch (error) {
//         apiRespone["data"] = {};
//         apiRespone["message"] = "Something Went Wrong";
//         apiRespone["status"] = 300;
//         return apiRespone;
//     }
// };


module.exports.createUPIOrder = async (amount) => {


    // Data to be sent in the POST request

    const postData = {
        order_amount
            : amount
    };
    const authToken = localStorage.getItem("token");

    // Configuration for the fetch request
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
        },
        body: JSON.stringify(postData), // Convert data to JSON string
    };
    const apiRespone = {};
    try {
        const response = await fetch(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_CREATE_ORDER_UPIGATEWAY}`,
            requestOptions
        );
        if (response == undefined) {
            apiRespone["data"] = {};
            apiRespone["message"] = "";
            apiRespone["status"] = 500;

            return apiRespone;
        }

        const data = await response.json();
        if (response.status === 401) {

            apiRespone["data"] = {};
            apiRespone["message"] = "";
            apiRespone["status"] = 401;

            return apiRespone;
        }
        if (response.status == 400) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        } else if (response.status == 200) {
            apiRespone["data"] = data.data;
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        } else if (response.status == 500) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
    } catch (error) {
        apiRespone["data"] = {};
        apiRespone["message"] = "Something Went Wrong";
        apiRespone["status"] = 300;
        return apiRespone;
    }
};

// eslint-disable-next-line no-undef
module.exports.createOrder = async (amount) => {
    const dataToEncrypt = amount.toString();
    const encryptionKey = '123';
    const encryptedData = encryptData(dataToEncrypt, encryptionKey)
    // Data to be sent in the POST request
    const postData = {
        data: encryptedData
    };
    const authToken = localStorage.getItem("token");

    // Configuration for the fetch request
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
        },
        body: JSON.stringify(postData), // Convert data to JSON string
    };
    const apiRespone = {};
    try {
        const response = await fetch(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_CREATE_ORDER}`,
            requestOptions
        );
        if (response == undefined) {
            apiRespone["data"] = {};
            apiRespone["message"] = "";
            apiRespone["status"] = 500;

            return apiRespone;
        }

        const data = await response.json();
        if (response.status === 401) {

            apiRespone["data"] = {};
            apiRespone["message"] = "";
            apiRespone["status"] = 401;

            return apiRespone;
        }
        if (response.status == 400) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        } else if (response.status == 200) {
            apiRespone["data"] = data.data;
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        } else if (response.status == 500) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
    } catch (error) {
        apiRespone["data"] = {};
        apiRespone["message"] = "Something Went Wrong";
        apiRespone["status"] = 300;
        return apiRespone;
    }
};

// eslint-disable-next-line no-undef
module.exports.checkOrderStatus = async (order_id) => {
    // Data to be sent in the POST request

    const authToken = localStorage.getItem("token");

    // Configuration for the fetch request
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
        },
        // Convert data to JSON string
    };
    const apiRespone = {};
    try {
        // eslint-disable-next-line no-undef

        const response = await fetch(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_CHECK_ORDER}${order_id}`,
            requestOptions
        );
        if (response == undefined) {
            apiRespone["data"] = {};
            apiRespone["message"] = "";
            apiRespone["status"] = 500;

            return apiRespone;
        }

        const data = await response.json();
        if (response.status === 401) {

            apiRespone["data"] = {};
            apiRespone["message"] = "";
            apiRespone["status"] = 401;

            return apiRespone;
        }
        if (response.status == 400) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        } else if (response.status == 200) {
            apiRespone["data"] = data.data;
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        } else if (response.status == 500) {
            apiRespone["data"] = {};
            apiRespone["message"] = data.message;
            apiRespone["status"] = response.status;

            return apiRespone;
        }
    } catch (error) {
        apiRespone["data"] = {};
        apiRespone["message"] = "Something Went Wrong";
        apiRespone["status"] = 300;
        return apiRespone;
    }
};