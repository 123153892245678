import { createBrowserRouter } from "react-router-dom";
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import socketIO from "socket.io-client";
import ProtectedRoutes from "./ProtectedRoutes";
// import { isJWT } from "../utils/checkJWT";
// import Home from "../pages/Home/Home";
import Wallet from "../pages/Wallet/Wallet";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import Profile from "../pages/Profile/Profile";
import Kyc from "../pages/Kyc/Kyc";
import VerifyOtp from "../pages/Verify OTP/VerifyOtp";
import Refer from "../pages/Refer/Refer";
import ChallengeResult from "../pages/Challenge Result/ChallengeResult";
// import Challenges from "../pages/Challenges/Challenges";
import Order from "../pages/Order/Order";
import WalletRecharge from "../pages/Wallet Recharge/WalletRecharge";
import GameHistory from "../pages/Game History/GameHistory";
import RefferalHistory from "../pages/Refferal History/RefferalHistory";
import PenaltyHistory from "../pages/Penalty History/PenaltyHistory";
import KycOTP from "../pages/KycOtp/KycOtp";
import WebGameChallenges from "../pages/WebGameChallenges/WebGameChallenges";
import GameContainer from "../pages/Game Container/GameContainer";
import BigChallengeResult from "../pages/Big Challenge Result/BigChallengeResult";
import BigChallenges from "../pages/BigChallenges/BigChallenges";
import PrivacyPolicy from "../pages/Privacy Policy/PrivacyPolicy";
import RefundPolicy from "../pages/Refund Policy/RefundPolicy";
// import Landing from "../pages/Landing Page/Landing";
import AutoPlay from "../pages/AutoPlay/AutoPlay";
import UPIVerify from "../pages/UPI Verify/upiVerify";
import StoreClosed from "../pages/Store Closed/StoreClosed";
import AutoPlayBig from "../pages/Auto Play Big/AutoPlayBig";
import AppAutoPlay from "../pages/AppAutoPlay/AppAutoPlay";
import AuthWrapper from "../context/AuthWrapper";
// eslint-disable-next-line no-undef
const socket = socketIO.connect(`${process.env.REACT_APP_CHALLENGE_SOCKET}`, {
  transports: ["websocket"],
});

// Define initial state for WebSocket and wallet balance
const initialState = {
  socket: null,
  isConnected: false,
  balance: 0,
  previousBalance: 0,
};

// Define actions for WebSocket and wallet balance
const actionTypes = {
  CONNECT: 'CONNECT',
  DISCONNECT: 'DISCONNECT',
  UPDATE_BALANCE: 'UPDATE_BALANCE',
};

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CONNECT:
      return {
        ...state,
        socket: action.payload,
        isConnected: true,
      };
    case actionTypes.DISCONNECT:
      return {
        ...state,
        socket: null,
        isConnected: false,
        previousBalance: state.balance,
      };
    case actionTypes.UPDATE_BALANCE:
      return {
        ...state,
        balance: action.payload,
        refferal_wallet: action.refferal_wallet
      };
    default:
      return state;
  }
};

// Context
const WalletContext = createContext();

// Custom hook to use wallet context
export const useWallet = () => useContext(WalletContext);

// Context provider
// eslint-disable-next-line react/prop-types
export const WalletProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    socket.on('connect', () => dispatch({ type: actionTypes.CONNECT, payload: socket }));
    socket.on('disconnect', () => dispatch({ type: actionTypes.DISCONNECT }));

    socket.on("wallet:readWalletBalance", (data) => {
      if (data !== null) {
        dispatch({ type: actionTypes.UPDATE_BALANCE, payload: data.wallet + data.win_wallet, refferal_wallet: data.refferal_wallet });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return (
    <WalletContext.Provider value={state}>
      {children}
    </WalletContext.Provider>
  );
};

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AuthWrapper socket={socket} />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/legaloffline",
    element: <RefundPolicy socket={socket} />,
  },
  {
    path: "/supportoffline",
    element: <PrivacyPolicy socket={socket} />,
  },
  {
    path: "/verifyOtp",
    element: <VerifyOtp socket={socket} />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/",
        element: <AuthWrapper socket={socket} />,
      },
      {
        path: "/kyc-otp",
        element: <KycOTP socket={socket} />,
      },
      {
        path: "/support",
        element: <PrivacyPolicy socket={socket} />,
      },
      {
        path: "/legal",
        element: <RefundPolicy socket={socket} />,
      },
      {
        path: "/auto-big",
        element: <AutoPlayBig socket={socket} />,
      },
      {
        path: "/storeclosed",
        element: <StoreClosed socket={socket} />,
      },
      {
        path: "/autoplay",
        element: <AutoPlay socket={socket} />,
      },
      {
        path: "/upi-verify",
        element: <UPIVerify socket={socket} />,
      },
      // {
      //   path: "/home",
      //   element: <Home socket={socket} />,
      // },
      {
        path: "/refer",
        element: <Refer socket={socket} />,
      },
      {
        path: "/wallet-recharge",
        element: <WalletRecharge socket={socket} />,
      },
      {
        path: "/wallet",
        element: <Wallet socket={socket} />,
      },
      {
        path: "/profile",
        element: <Profile socket={socket} />,
      },
      {
        path: "/order/:order_id",
        element: <Order socket={socket} />,
      },
      // {
      //   path: "/challenges",
      //   element: </Challenges socket={socket} />,
      // },
      {
        path: "/challenges",
        element: <AppAutoPlay socket={socket} />,
      },
      {
        path: "/big-challenges",
        element: <BigChallenges socket={socket} />,
      },
      {
        path: "/web-game-challenges",
        element: <WebGameChallenges socket={socket} />,
      },
      {
        path: "/game-container",
        element: <GameContainer socket={socket} />,
      },
      {
        path: "/challenge-result/:challenge_id",
        element: <ChallengeResult socket={socket} />,
      },
      {
        path: "/big-challenge-result/:challenge_id",
        element: <BigChallengeResult socket={socket} />,
      },
      {
        path: "/game-history",
        element: <GameHistory socket={socket} />,
      },
      {
        path: "/refferal-history",
        element: <RefferalHistory socket={socket} />,
      },
      {
        path: "/penalty-history",
        element: <PenaltyHistory socket={socket} />,
      },
      {
        path: "/kyc",
        element: <Kyc socket={socket} />,
      },
    ],
  },
]);
