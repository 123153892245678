/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react";
import AppBar from "../../components/AppBar/AppBar";
import WarningAlert from "../../components/Alert/WarningAlert";
import Game from "../../components/Game/Game";
//import { checkToken, appSettings } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useWallet } from "../../routes/AppRouter";
import { userProfile } from "../../services/user.service";
import ludolite from "../../assets/images/50-500.webp";
import ludorich from "../../assets/images/550-15000.webp";
// import lw500 from "../../assets/images/10-500.webp";
// import lw1000 from "../../assets/images/1000-5000.webp";

const Home = ({ socket }) => {
  const [kyc, setKyc] = useState(null);
  //const [appData, setAppData] = useState(null);
  // const [profileData, setProfileData] = useState(null);
  //const [loader, setLoader] = useState(true);
  const [walletBalance, setWalletBalance] = useState(0);
  const navigate = useNavigate();
  const data = useWallet();

  // const allowedNumber = [
  //   "9625916335", "9166371779", "9602429058", "6375466315",
  //   "6375447851", "9898699889", "6351989202", "9649662777",
  //   "9521960526", "6350124981", "9928281764"
  // ];

  const fetchData = useCallback(async () => {
    try {
      const [profileResults] = await Promise.all([
        userProfile(),
        // appSettings(),
        //checkToken(),
      ]);

      if (profileResults.status === 401) {
        localStorage.clear();
        navigate("/login");
        return;
      }

      // setProfileData(profileResults.data);
      // setAppData(appSettingsData.data);
      setKyc(profileResults.data?.is_kyc ?? "");

      socket.emit("wallet:fetchWalletBalance", {
        type: "WALLET_BALANCE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),
        },
      });
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      //setLoader(false);
    }
  }, [navigate, socket]);

  useEffect(() => {
    fetchData();

    socket.on("wallet:readWalletBalance", (data) => {
      if (data !== null) {
        setWalletBalance(data.wallet + data.win_wallet);
      }
    });

    return () => {
      socket.off("wallet:readWalletBalance");
    };
  }, [fetchData, socket]);

  // if (loader) {
  //   return (
  //     <div className="relative">
  //       <div className="w-[100vw] fixed top-0 md:w-[38%] z-10">
  //         <AppBar socket={socket} context={data} walletBalance={walletBalance} />
  //       </div>
  //       <div className="p-3 mx-auto bg-white mt-[75px]">
  //         <div>
  //           <div className="flex items-center justify-between mb-2">
  //             <h1 className="text-2xl font-medium">Games</h1>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // if (appData == null) {
  //   return null;
  // }

  return (
    <div className="w-[100vw] md:w-[38%] h-[100%] bg-white">
      <div className="relative h-full h-screen">
        <div className="w-[100vw] fixed top-0 md:w-[38%] z-10">
          <div className="bg-red-500 p-[0.4rem] text-white font-custom text-[0.85rem] text-center">
            <p>Exclusive Offer ! Get 20Rs On New Registrations 😍</p>
          </div>
          <AppBar socket={socket} context={data} walletBalance={walletBalance} />
        </div>
        <div className="p-3 mx-auto bg-white mt-[100px]">
          <div>
            {kyc === false && <WarningAlert />}
            <div className="flex items-center justify-between mb-4 mt-4">
              <h1 className="text-[1.2rem] font-medium">Games</h1>
            </div>
          </div>
          <center>
            <div className="mb-3 grid grid-cols-2 gap-2">
              <div className="h-auto">
                <Game path="/autoplay" image={ludolite} />
              </div>
              <div>
                <Game path="/auto-big" image={ludorich} />
              </div>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Home);
