import React from "react";
import { useNavigate } from "react-router-dom";
export default function BackButton() {
  const navigate = useNavigate();
  return (
    <button
      className="px-2 py-1 border rounded-md border-[#3b2c23] text-[0.8rem] text-[#3b2c23] hover:bg-[#3b2c23] hover:text-white flex items-center gap-2"
      // eslint-disable-next-line no-unused-vars
      onClick={(e) => {
        navigate(-1);
      }}
    >
      <i className="fa-solid fa-arrow-left text-xs text-red-600" /> Change Number
    </button>
  );
}
