import React from "react";
// import infoSign from "../../assets/images/info-sign.png";
import { useNavigate } from "react-router-dom";

export default function WarningAlert() {

  const navigate = useNavigate();
  return (
    <div className="flex rounded-md items-center justify-between bg-[#f8d7da] text-[#842029] p-4">
      <span>
        <b className="text-[0.9rem] flex gap-1 items-center font-custom">
          KYC Pending <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18" fill="red"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg>
        </b>
      </span>
      <button
        className="bg-[#DC3545] text-white px-2 py-1 text-[0.7rem] font-custom rounded-[0.2rem]"
        onClick={() => {
          navigate("/kyc");
        }}
      >
        Complete Here
      </button>
    </div>
  );
}
