// eslint-disable-next-line no-undef
module.exports.createChallenge = async (data) => {
  // Data to be sent in the POST request
  const postData = {
    entry_fee: data.entry_fee,
    game_id: data.game_id,
    game_type: data.game_type
  };
  const authToken = localStorage.getItem("token");

  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
    },
    body: JSON.stringify(postData), // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_CHALLENGE_API}`,
      requestOptions
    );

    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status == 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};

// eslint-disable-next-line no-undef
module.exports.submitGameChallenge = async (data) => {
  // Data to be sent in the POST request
  const postData = {
    "challenge_id": data.challenge_id,
    "type": data.type,  //"2token","4token"
    "creator_token": data.creator_token,
    "creator_username": data.creator_username,
    "opponent_token": data.opponent_token,
    "opponent_username": data.opponent_username,
    "room_code": data.room_code,
    "username": data.username,
    "entry_fee": data.entry_fee,
    "bot_play": data.bot_play, //if this player play with bot then true
    "is_bot": data.is_bot
  };
  const authToken = localStorage.getItem("token");

  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
    },
    body: JSON.stringify(postData), // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_LUDO_GAME}`,
      requestOptions
    );

    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status == 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};

// eslint-disable-next-line no-undef
module.exports.viewGameChallenge = async (data) => {
  // Data to be sent in the POST request
  const postData = {
    "challenge_id": data.challenge_id,

  };
  const authToken = localStorage.getItem("token");

  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
    },
    body: JSON.stringify(postData), // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_CHALLENGE_DETAIL_API}`,
      requestOptions
    );

    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status == 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};



// eslint-disable-next-line no-undef
module.exports.checkGameOnly = async () => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");

  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
    },
    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.
        REACT_APP_CHECK_GAME_ONLY}`,
      requestOptions
    );

    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status == 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};



// eslint-disable-next-line no-undef
module.exports.checkAllChallenges = async () => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");

  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
    },
    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.
        REACT_APP_CHECK_GAME_LISTING}`,
      requestOptions
    );

    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status == 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};







// eslint-disable-next-line no-undef
module.exports.checkAllBigChallenges = async () => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");

  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
    },
    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.

        REACT_APP_CHECK_GAME_BIG_LISTING}`,
      requestOptions
    );

    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status == 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status == 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};
