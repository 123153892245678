import { useEffect } from 'react';
import { useLocation } from 'react-router-dom/dist';
const RouteWatcher = () => {
    const history = useLocation();

    useEffect(() => {


        // Call unloadScript with the script source you want to unload

        console.log(history.pathname);

        if (history.pathname != "/play-game") {
            console.log("help");
            // window.removeScr()



        }
    }, [history]);

    return null; // RouteWatcher doesn't render anything in the DOM
};

export default RouteWatcher;
