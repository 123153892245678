import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";


import ReactPWAInstallProvider from "react-pwa-install";
import { appRouter } from "./routes/AppRouter";
import { RouterProvider } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });

};

clearCacheData()
root.render(
  <ReactPWAInstallProvider>
    <RouterProvider router={appRouter} />
  </ReactPWAInstallProvider>
);
