import React from "react";

// import { useState } from "react";
import AppBar from "../../components/AppBar/AppBar";
// import Loader from "../../components/Loader/Loader";
import RightContainer from "../../components/Common/RightContainer";
// import NoticeAlert from "../../components/Alert/NoticeAlert";
// import Game from "../../components/Game/Game";
// import lw500 from "../../assets/images/10-500.png";
// import lw1000 from "../../assets/images/1000-5000.png";
// import FullFilledButton from "../../components/Button/FullFilledButton";
// import { loginUser } from "../../services/auth.service";
import { Link } from "react-router-dom";
import landingNew from '../../assets/images/landingNew.webp'




export default function Login() {
  // const navigate = useNavigate();
  // const [mobile, setMobile] = useState("");
  // const [sendOtpVisible, setSendOtpVisible] = useState(false);
  // const [loginLoader, setLoginLoader] = useState(false);


  return (
    <>

      <div className="flex gap-2">
        <div className="w-[100vw] fixed top-0  md:w-[38%]">
          <div className="w-[100vw] fixed top-0  md:w-[38%]">
            <div className="bg-red-500 p-[0.4rem] text-white font-custom text-[0.85rem] text-center">
              <p>Exclusive Offer ! Get 20Rs On New Registrations 😍</p>
            </div>
            <AppBar></AppBar>
          </div>
          <div className="p-3 mx-auto bg-white mt-16 h-[100vh]">
            <div>
              {/* <NoticeAlert></NoticeAlert> */}
              {/* login*/}
              {/* <div className=" border rounded-md mb-3">
                <div className="text-center border-b-2 px-4 py-2  bg-[#F8F9FA]">
                  Login
                </div>
                <div className="p-4">
                  <div className="mb-4 flex flex-col items-start">
                    <label htmlFor="" className="mb-2">
                      Mobile Number
                    </label>
                    <div className="self-stretch flex items-center">
                      <input
                        type="text"
                        value=" +91"
                        disabled={true}
                        className="border 
                           text-[#212529] py-1 w-10 rounded-l-md"
                      />
                      <input
                        type="tel"
                        value={mobile}
                        onChange={(event) => {
                          if (/^\d*$/.test(event.target.value)) {
                            setMobile(event.target.value);
                          }
                        }}
                        maxLength={10}
                        pattern="\d"
                        className="border rounded-r-md
                           text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                      />
                    </div>
                  </div>

                  <p className="text-center text-[0.9rem]">
                    By Continuing, you agree to our{" "}
                    <Link to="/legal" className="text-[#0d6efd] underline">
                      Legal Terms
                    </Link>{" "}
                    and you are 18 years or older.
                  </p>
                  {loginLoader ? (
                    <>
                      {" "}
                      <center>
                        <div className="py-2">
                          <div
                            className="
                  w-full  px-3 py-[6px] "
                          >
                            <Loader></Loader>
                          </div>
                        </div>
                      </center>
                    </>
                  ) : (
                    <>
                      <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                        {sendOtpVisible ? (
                          <FullFilledButton
                            buttonCaption={"GET OTP"}
                            onClick={async () => {
                              let data = { mobile: mobile };
                              setLoginLoader(true);
                              const result = await loginUser(data);
                              setLoginLoader(false);
                              localStorage.setItem("mobile", data.mobile);
                              //  e.currentTarget.disabled = false;
                              if (result.status !== 200) notify(result.message);
                              else navigate("/verifyOtp");
                            }}
                          ></FullFilledButton>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  )}

                  <p className="mb-3 text-center text-[0.9rem]">
                    {"Don't have an account?"}
                    <Link to={"/register"} className="text-[#0d6efd] underline">
                      Register
                    </Link>
                  </p>
                </div>
              </div> */}
              {/* <div className="flex items-center justify-between mt-4 mb-6">
                <h1 className="text-5xl font-medium">Games</h1>
                <HollowButton buttonCaption={"Guide"} onClick={async () => {

                }}></HollowButton>
              </div> */}
            </div>
            <div className="mb-3 mt-10 lg:mt-6 gap-4">
              {/* <Game path={"/autoplay"} image={lw500}></Game>
              <Game path={"/auto-big"} image={lw1000}></Game> */}
              <div className="flex justify-center">
                <img src={landingNew} className="lg:w-10/12 w-full" />
              </div>
              <p className="font-custom text-sm text-center mt-4 italic text-gray-500">This Game involves an element of financial risk and may be addictive. Please Play responsibly and at your own risk.</p>
              <Link to={'/login'}><button className="bg-[#39535a] font-custom text-sm px-2 py-2 text-white w-full rounded mt-10">Play Now</button></Link>
            </div>
          </div>
        </div>
        <RightContainer></RightContainer>
      </div>
    </>
  );
}
