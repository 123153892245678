/* eslint-disable no-undef */
const routes = require("./routes");

module.exports.loginUser = async (data) => {
  // Data to be sent in the POST request
  const postData = {
    mobile: data.mobile,
  };

  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Include auth token in the Authorization header
    },

    body: JSON.stringify(postData), // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_AUTH_API}${routes.login}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};
module.exports.registerUser = async (data) => {
  // Data to be sent in the POST request
  const postData = {
    mobile: data.mobile,
    refer_by: data.refer_by,
    fullname: data.fullname


  };

  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Include auth token in the Authorization header
    },

    body: JSON.stringify(postData), // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_AUTH_API}${routes.register}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    } else if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};
module.exports.verifyUser = async (data) => {
  // Data to be sent in the POST request
  const postData = {
    mobile: data.mobile,
    otp: data.otp,
  };

  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Include auth token in the Authorization header
    },

    body: JSON.stringify(postData), // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH_API}${routes.verifyotp}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};

module.exports.userLogout = async () => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH_API}${routes.logout}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};

module.exports.appSettings = async () => {
  // Data to be sent in the POST request

  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH_API}${routes.appSettings}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};



module.exports.checkToken = async () => {
  // Data to be sent in the POST request

  const authToken = localStorage.getItem("token");
  // Configuration for the fetch request
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      // Include auth token in the Authorization header
    },

    // Convert data to JSON string
  };
  const apiRespone = {};
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API}${routes.checkToken}`,
      requestOptions
    );


    const data = await response.json();
    if (response.status === 401) {

      apiRespone["data"] = {};
      apiRespone["message"] = "";
      apiRespone["status"] = 401;

      return apiRespone;
    }
    if (response.status === 400) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 200) {
      apiRespone["data"] = data.data;
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
    if (response.status === 500) {
      apiRespone["data"] = {};
      apiRespone["message"] = data.message;
      apiRespone["status"] = response.status;

      return apiRespone;
    }
  } catch (error) {
    apiRespone["data"] = {};
    apiRespone["message"] = "Something Went Wrong";
    apiRespone["status"] = 300;
    return apiRespone;
  }
};

