import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

export default function NavItems({
  to,
  name,
  image,
  onClick,
  style = "text-start bg-slate-100 p-4 flex items-center justify-between ",

}) {
  return (
    <Link to={to} className={style} style={{ height: '100px' }}>
      <div className="flex items-center justify-start px-2 py-1" onClick={onClick}>
        <div className="gap-4 flex items-center">
          <img src={image} alt="" style={{ height: 30 }} />
          <p className="p-0 m-0 font-normal font-custom font-light text-[22px]">
            {name}
            {name === 'Wallet' && (
              <span className="bg-[#3b2c23] text-[0.7rem] px-4 py-2 rounded-2xl ml-10 text-white">Get Bonus!</span>
            )}
          </p>
        </div>
      </div>
      {/* <img src="../assets/next.png" alt="" style="height:32px;"> */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1.3em" height="1.3em" fill="currentColor" className="m-0 p-0 d-flex align-items-center justify-content-center"><path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path></svg>
    </Link>
  );
}

NavItems.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  style: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func
};
