import React, { useState } from "react";
import AppBar from "../../components/AppBar/AppBar";
import FilledButton from "../../components/Button/FilledButton";
import HollowButton from "../../components/Button/HollowButton";
import WarningAlert from "../../components/Alert/WarningAlert";
import InfoAlert from "../../components/Alert/InfoAlert";
import { Link } from "react-router-dom";
import FullFilledButton from "../../components/Button/FullFilledButton";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { useEffect } from "react";
import { useWallet } from "../../routes/AppRouter";
import { userProfile } from "../../services/user.service";

import { appSettings } from "../../services/auth.service";
import Offcanvas from "../../components/Alert/OffcanvasDialog";
export default function Wallet({ socket }) {
  const [kyc, setKyc] = useState(null)
  const [walletBalance, setWalletBalance] = useState(0)
  const [winWalletBalance, setWinWalletBalance] = useState(0)
  const [refferalWalletBalance, setRefferalWalletBalance] = useState(0)
  const [app_data, setAppData] = useState(false)
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const handleOpenOffcanvas = () => {
    setIsOffcanvasOpen(true);
  };

  const handleCloseOffcanvas = () => {
    setIsOffcanvasOpen(false);
  };


  const navigate = useNavigate();

  const data = useWallet()
  useEffect(() => {
    fetchProfile()
    if (socket != undefined) {


      socket.emit("wallet:fetchWalletBalance", {
        type: "WALLET_BALANCE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),
        },
      });
    }
  }, [])
  async function fetchProfile() {

    const results = await userProfile();
    const app_data = await appSettings()
    setAppData(app_data.data)
    //await getGameHistory(40);


    if (results.status == 401) {
      localStorage.clear();
      navigate("/login");
    }

    setKyc(results.data === null ? "" : results.data.is_kyc);
  }
  useEffect(() => {

    try {
      if (socket != undefined) {
        socket.emit("wallet:fetchWalletBalance", {
          type: "WALLET_BALANCE",
          params: "",
          data: {
            auth: localStorage.getItem("token"),
          },
        });
        socket.on("wallet:readWalletBalance", (data) => {

          if (data !== null)
            setWalletBalance(data.wallet);
          setRefferalWalletBalance(data.refferal_wallet);
          setWinWalletBalance(data.win_wallet);
          //setOpenGames([data]);
        });
      }
    } catch (error) { /* empty */ }

  }, []);
  if (app_data == null) {
    return <></>
  }
  return (

    <>
      <div className="w-[100vw] fixed top-0  md:w-[38%]">
        {/* nav bar */}
        <AppBar socket={socket} context={data}></AppBar>


      </div>

      <div className="p-3 mx-auto bg-white mt-12">

        <div>
          <div className="flex items-center justify-between mt-4 mb-3">

            <FilledButton
              buttonCaption={"Back"}
              bg={true}
              textColor={'text-white'}
              onClick={() => {
                navigate(-1);
              }}
            ></FilledButton>
            {/* <FilledButton
              buttonCaption={"Wallet History"}
              textColor={"text-['#3b2c23']"}
              bg={false}
              onClick={() => {
                navigate(-1);
              }}
            ></FilledButton> */}
            {app_data.stop_gateway == "0" ? <Link to={"/game-history"}>
              <HollowButton buttonCaption={"Wallet History"}></HollowButton>
            </Link> : <></>}

          </div>
          {/* alert */}
          {kyc == false ? <><WarningAlert></WarningAlert></> : <></>}
          {/* <div className="shadow-md border rounded-md mb-3">
            <div className="text-center border-b-2 px-4 py-2">
              Bonus
            </div>
            <div className="p-4">
              <div>
                <InfoAlert
                  message="Transfer win cash to deposit cash and get 5% additional"
                ></InfoAlert>

                <div className="pt-4">
                  {app_data.stop_gateway == "0" ? <FullFilledButton
                    onClick={() => { navigate("/send-bonus") }}
                    buttonCaption={"Get Bonus"}
                  ></FullFilledButton> : <></>}

                </div>
              </div>
            </div>
          </div> */}
          {/* Deposit Chips */}
          <div className="shadow-md border rounded-md mt-4 mb-3">
            <div className="text-center border-b-2 px-4 py-2 text-[0.9rem] font-custom">
              Deposit Chips
            </div>
            <div className="p-4">
              <div>
                <InfoAlert
                  message="यह चिप्स  Buy की गई चिप्स है इनसे सिर्फ़ गेम
                  खेले जा सकते है ॥ Bank या UPI में निकाला नहीं जा सकता है"
                ></InfoAlert>
                <div className="flex items-center justify-center px-2">
                  <div className="flex flex-col items-center justify-center">
                    <span className="text-[0.8rem] font-custom">
                      <b>Chips</b>
                    </span>
                    <span className="text-[1.2rem] text-[0.9rem]">{walletBalance}</span>
                  </div>
                </div>
                <div className="pt-4">



                  <FullFilledButton buttonCaption={"Add"} onClick={async () => {
                    const profile_results = await userProfile();
                    if (profile_results.data.mobile == "7777777777" || profile_results.data.mobile == "7777777771") {
                      navigate("/wallet-recharge")

                    }
                    else
                      navigate("/wallet-recharge")

                    //   navigate("/storeclosed")
                    // } else {
                    //   navigate("/wallet-recharge")
                    // }

                  }}></FullFilledButton>
                </div>
              </div>
            </div>
          </div>
          {/* Winning Chips */}
          <div className="shadow-md border rounded-md mb-3">
            <div className="text-center border-b-2 px-4 py-2 text-[0.9rem] font-custom">
              Winning Chips
            </div>
            <div className="p-4">
              <div>
                <InfoAlert
                  message="यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है इन्हें Bank
                  या UPI में निकाला जा सकता है ॥ इन चिप्स से गेम भी खेला जा सकता
                  है"
                ></InfoAlert>
                <div className="flex items-center justify-center px-2">
                  <div className="flex flex-col items-center justify-center">
                    <span className="text-[0.8rem] font-custom">
                      <b>Chips</b>
                    </span>
                    <span className="text-[1.2rem] font-custom">{winWalletBalance}</span>
                  </div>
                </div>
                <div className="pt-4">

                  <FullFilledButton
                    onClick={() => {
                      if (app_data.stop_gateway == "0") {
                        navigate("/storeclosed")
                      } else {
                        navigate("/withdraw-request")
                      }

                    }}
                    buttonCaption={"Withdraw"}
                  ></FullFilledButton>
                </div>
              </div>
            </div>
          </div>
          {/* Winning Chips */}
          <div className="shadow-md border rounded-md mb-3 mt-4">
            <div className="text-center border-b-2 px-4 py-2 font-custom text-[0.9rem]">
              Refferal Chips
            </div>
            <div className="p-4">
              <div>
                <InfoAlert
                  message="यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है इन्हें Bank
                  या UPI में निकाला जा सकता है ॥ इन चिप्स से गेम भी खेला जा सकता
                  है"
                ></InfoAlert>
                <div className="flex items-center justify-center px-2">
                  <div className="flex flex-col items-center justify-center">
                    <span className="text-[0.8rem] font-custom">
                      <b>Chips</b>
                    </span>
                    <span className="text-[1.2rem] font-custom">{refferalWalletBalance.toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false
                    })}</span>
                  </div>
                </div>
                <div className="pt-4">
                  <FullFilledButton
                    onClick={() => {
                      if (app_data.stop_gateway == "0") {
                        navigate("/storeclosed")
                      } else {
                        navigate("/withdraw-request")
                      }
                    }}
                    buttonCaption={"Reedem"}
                  ></FullFilledButton>

                </div>
              </div>
            </div>
          </div>
          <div className="p-5">
            {/* <button
              className="px-4 py-2 bg-blue-500 text-white rounded"
              onClick={handleOpenOffcanvas}
            >
              Open Offcanvas
            </button> */}
            <FullFilledButton
              onClick={handleOpenOffcanvas}
              buttonCaption={"Convert To Deposit"}
            ></FullFilledButton>
            <Offcanvas isOpen={isOffcanvasOpen} onClose={handleCloseOffcanvas} />
          </div>
        </div>
      </div>
    </>
  );
}

Wallet.propTypes = {
  socket: PropTypes.any
};
