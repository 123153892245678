import React from "react";
// import wallet from "../../assets/images/wallet.png";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

export default function IconButton({ value }) {
  return (
    <button className="border rounded-md px-[0.2rem]">
      <Link to="/wallet">
        <div className="py-[0.1rem] bg-white pl-1 pr-1 text-dark flex items-center rounded-md">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="green" className="me-2">
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
            <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
          </svg>
          <strong className="text-[0.9rem] font-custom">{value}</strong>
          {/* <i className="fa-solid fa-plus text-sm"></i> */}
        </div>
      </Link>
    </button>
  );
}

IconButton.propTypes = {
  value: PropTypes.string,
};
