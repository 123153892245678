import React from "react";
import diamond from "../../assets/images/diamond.webp";
// import dollar from "../../assets/images/dollar.png";

import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

export default function RefferaIconButton({ value }) {
    return (
        <button className="border rounded-md px-[0.2rem]">
            <Link to="/wallet">
                <div className="py-[0.1rem] bg-white pl-1 pr-1 text-dark flex items-center gap-2 rounded-md">
                    <img src={diamond} alt="" className="w-4" />
                    <strong className="text-[0.9rem] font-custom">{value}</strong>
                </div>
            </Link>
        </button>
    );
}

RefferaIconButton.propTypes = {
    value: PropTypes.string,
};
