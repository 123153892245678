import React, { useEffect, useState } from "react";
import AppBar from "../../components/AppBar/AppBar";
import PaginationComponent from "./Pagination";
import PropTypes from 'prop-types'
import OffCanvasStatement from "../../components/Alert/OffCanvasStatement";

export default function GameHistory({ socket }) {
  const [filter, setFilter] = useState('wallet');
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false)

  const handleOpenCanvas = () => {
    setIsOffcanvasOpen(true)
  }

  const handleCloseOffCanvas = () => {
    setIsOffcanvasOpen(false)
  }

  useEffect(() => {
    if (socket != undefined) {


      socket.emit("wallet:fetchWalletBalance", {
        type: "WALLET_BALANCE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),
        },
      });
    }
  }, [])

  return (
    <>
      <div className="w-[100vw] fixed top-0  md:w-[38%]">
        {/* nav bar */}
        <AppBar socket={socket}></AppBar>
      </div>
      <div className="p-3 mx-auto bg-white mt-16 h-screen">
        <div className="download-history flex justify-end">
          <button className="border px-3 py-1 rounded-md" onClick={handleOpenCanvas}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="14" height="14" fill="currentColor"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path></svg>
          </button>
        </div>
        <OffCanvasStatement isOpen={isOffcanvasOpen} onClose={handleCloseOffCanvas} />
        <div className="filter-btns flex justify-between gap-2 mb-3 mt-2">
          {/* <button
            className={`${filter === 'all' ? 'bg-[#3b2c23] text-white' : 'border text-[#3b2c23]'} font-semibold px-6 rounded-[25px] text-[0.6rem] py-1 font-custom`}
            onClick={() => setFilter('all')}
          >
            All
          </button> */}
          <button
            className={`${filter === 'wallet' ? 'bg-[#3b2c23] text-white' : 'border text-[#3b2c23]'} font-semibold px-5 rounded-[25px] text-[0.6rem] py-1 font-custom`}
            onClick={() => setFilter('wallet')}
          >
            Wallet
          </button>
          <button
            className={`${filter === 'game' ? 'bg-[#3b2c23] text-white' : 'border text-[#3b2c23]'} font-semibold px-5 rounded-[25px] text-[0.6rem] py-1 font-custom`}
            onClick={() => setFilter('game')}
          >
            Game
          </button>
          <button
            className={`${filter === 'penalty' ? 'bg-[#3b2c23] text-white' : 'border text-[#3b2c23]'} font-semibold px-5 rounded-[25px] text-[0.6rem] py-1 font-custom`}
            onClick={() => setFilter('penalty')}
          >
            Penalty
          </button>
          <button
            className={`${filter === 'referral' ? 'bg-[#3b2c23] text-white' : 'border text-[#3b2c23]'} font-semibold px-5 rounded-[25px] text-[0.6rem] py-1 font-custom`}
            onClick={() => setFilter('referral')}
          >
            Referral
          </button>
        </div>
        <div className="search-filter flex justify-between mb-2">
          <input type="text" className="border rounded-md w-[75%] font-custom text-[0.8rem] px-2 py-[0.4rem]" placeholder="Enter Room code or UTR number"></input>
          <button className="bg-[#3b2c23] rounded-md font-custom px-4 text-white text-[0.8rem]">Search</button>
        </div>
        {/* wallet button */}
        <PaginationComponent filter={filter}></PaginationComponent>
        <div id="walletList">

        </div>
        {/* All button */}
        <div id="allList" style={{ display: "none" }}>
          <div className="flex mx-auto">
            <input
              type="text"
              className="mr-2 rounded-md w-full px-3 py-1 focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
              placeholder="Enter Room code or UTR number"
              style={{ border: "1px solid #ced4da" }}
            />
            <button className="bg-[#0d6efd] text-white px-3 rounded-md">
              Search
            </button>
          </div>
          <div className="flex flex-col items-stretch px-0 py-3 overflow-auto">
            <ul className="mb-0 pl-0">
              <div
                className="px-1 py-2"
                style={{ borderBottom: "1px solid #d2d2d2ee" }}
              >
                <div className="flex items-stretch">
                  <div
                    className="flex flex-col items-start justify-center"
                    style={{ borderRight: "1px solid #d2d2d2ee", width: 80 }}
                  >
                    <img src="../assets/see.png" alt="" />
                    <span className="text-[0.8rem]">14 Dec</span>
                    <span className="text-[0.8rem]">1:56 pm</span>
                  </div>
                  <div className="flex flex-col items-stretch justify-center flex-1 w-full px-2">
                    <div className="flex items-stretch justify-between">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span className="font-semibold text-start mr-2 text-[0.9rem] w-[220px]">
                            Lost Against Takliya
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-center">
                        <span className="text-red-600 text-end font-bold my-1 text-[0.8rem]">
                          (-) 10
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-xs">Room Code: 01547172</span>
                      <span className="text-start text-xs">
                        Closing Balance: 4.75
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <div className="flex  border-b-2 py-2 ">
            <div className="flex flex-col items-start justify-center ">
              <img src="../assets/folder.png" alt="" />
              <span className="text-xs">11 Dec</span>
              <span className="text-xs">12:53 pm</span>
            </div>
            <div
              className="flex flex-col items-stretch justify-center flex-1 w-full px-2"
              style={{ borderLeft: "2px !important" }}
            >
              <div className="flex items-stretch justify-between">
                <div>
                  <span className="font-semibold text-[0.9rem]">
                    Chips Added Via UPI
                  </span>
                </div>
                <div>
                  <span className="my-1 font-bold text-[0.8rem] text-green-900">
                    (+) 10
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-xs">UTR: 334586345157</span>
                <span className="text-xs">Closing Balance: 10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
GameHistory.propTypes = {
  socket: PropTypes.any
};