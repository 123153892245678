import React, { useState, useEffect } from "react";
import AppBar from "../../components/AppBar/AppBar";
import { Link, useNavigate } from "react-router-dom";
import FullFilledButton from "../../components/Button/FullFilledButton";

import PropTypes from 'prop-types';
import { useWallet } from "../../routes/AppRouter";
import { Flip } from "react-toastify";

import { ToastContainer, toast } from "react-toastify";

import { verifyUPIService } from "../../services/withdrawrequest.service";
export default function UPIVerify({ socket }) {

    const notify = (message) =>
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Flip,
        });
    const notifySuccess = (message) =>
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Flip,
        });


    const [addharName, setAddharName] = useState("")
    const [upi_id, setUPI] = useState("")


    const navigate = useNavigate();
    const data = useWallet()
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [message, setMessage] = useState("");



    const fetchWithQueryParams = async (url, params) => {
        const query = new URLSearchParams(params).toString();
        const response = await fetch(`${url}?${query}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    };
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        }
    }, []);
    useEffect(() => {



        if (socket != undefined) {


            socket.emit("wallet:fetchWalletBalance", {
                type: "WALLET_BALANCE",
                params: "",
                data: {
                    auth: localStorage.getItem("token"),
                },
            });
        }
    }, [])

    return (
        <>
            <div className="w-[100vw] fixed top-0  md:w-[38%]">
                {/* nav bar */}
                <AppBar socket={socket} context={data}></AppBar>

            </div>
            <div className="p-3 mx-auto bg-white mt-16 h-[100vh]">
                <div>
                    <div className="flex items-center justify-between mt-4 mb-6">
                        <button
                            className="text-white bg-[#0d6efd] rounded-md  px-4 py-[6px]"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Back
                        </button>

                        <button
                            className="flex items-center border text-[#0d6efd]  rounded-md border-[#0d6efd]
                      px-3 py-[6px] hover:bg-[#0d6efd] hover:text-white"
                        >
                            <span>Guide</span>
                        </button>
                    </div>
                    {/* Deposit Chips */}
                    <div className="shadow-lg border rounded-md mb-3">
                        <ToastContainer></ToastContainer>
                        <div className="text-center border-b-2 px-4 py-2 font-bold bg-[#F8F9FA]">
                            UPI Verification
                        </div>
                        <div className="p-4">

                            <div className="mb-4 flex flex-col items-start">
                                <label htmlFor="" className="mb-2">
                                    Name in Addhar
                                </label>
                                <input
                                    type="text"
                                    placeholder="Name in Addhar"

                                    value={addharName}
                                    onChange={(event) => {
                                        setAddharName(event.target.value);

                                    }}
                                    className="border rounded-md
                           text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                                />
                            </div>

                            <div className="mb-4 flex flex-col items-start">
                                <label htmlFor="" className="mb-2">
                                    UPI ID
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter UPI ID"

                                    value={upi_id}
                                    onChange={(event) => {
                                        setUPI(event.target.value);

                                    }}
                                    className="border rounded-md
                           text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                                />
                            </div>

                            <p className="mb-3 text-center text-[0.9rem]">
                                {message}
                            </p>
                            <p className="mb-3 text-center text-[0.9rem]">
                                By Continuing, you agree to our{" "}
                                <Link to={localStorage.getItem('token') != null ? "/legal" : "/legaloffline"} className="text-[#0d6efd] underline">
                                    Legal Terms
                                </Link>{" "}
                                and you are 18 years or older.
                            </p>


                            <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                                <FullFilledButton onClick={async () => {




                                    if (upi_id.trim().length == 0) {
                                        notify("Write a valid UPI ID")
                                        return
                                    }

                                    if (position.latitude != null) {

                                        const url = 'https://us1.locationiq.com/v1/reverse';
                                        const queryParams = {
                                            lat: position.latitude,
                                            lon: position.longitude,
                                            key: 'pk.6620b3af79d70608c2dc6d5f1821ebbd',
                                            format: 'json'
                                        };
                                        const address = await fetchWithQueryParams(url, queryParams)
                                        const blockStates = ["Assam", "Odisha", "Nagaland", "Sikkim", "Meghalaya", "Andhra Pradesh", "Telangana"];

                                        if (blockStates.includes(address.address.state)) {
                                            notify("Blocked in your state")
                                            return
                                        }
                                        const data = {
                                            upi_id: upi_id,

                                            lat: position.latitude.toString(),
                                            lang: position.longitude.toString()

                                        }
                                        const response =

                                            await verifyUPIService(data)
                                        if (response.status == 200) {
                                            notifySuccess(response.message)


                                        }
                                        if (response.status == 400) {
                                            notify(response.message)
                                            setMessage(response.message)


                                        }

                                    } else {
                                        notify("Please Give location Permission")
                                    }

                                }}
                                    buttonCaption={"Withdraw Request "}
                                ></FullFilledButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
UPIVerify.propTypes = {
    socket: PropTypes.any
};

