import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FullFilledButton from '../Button/FullFilledButton';
import Datepicker from 'react-tailwindcss-datepicker';
import { userStatement } from '../../services/user.service';

const OffCanvasStatement = ({ isOpen, onClose }) => {
  const [activeDateFilter, setActiveDateFilter] = useState('1_months');
  const [customDate, setCustomDate] = useState({
    startDate: null,
    endDate: null
  });
  const [loading, setLoading] = useState(false);

  const getDateRange = (rangeType) => {
    const now = new Date();
    let startDate, endDate;

    switch (rangeType) {
      case '1 Month':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case '3 Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 2, 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case '6 Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 5, 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case '1 Year':
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = new Date(now.getFullYear(), 11, 31);
        break;
      default:
        throw new Error('Invalid range type');
    }

    // Set end date to the end of the day
    endDate.setHours(23, 59, 59, 999);
    setCustomDate({ startDate: startDate.toISOString(), endDate: endDate.toISOString() });
    console.log(customDate);
  };

  const handlevalueChange = (newvalue) => {
    if (newvalue.endDate != undefined) {
      let endDate = new Date(newvalue.endDate);
      endDate.setHours(23, 59, 59, 999);
      newvalue.endDate = endDate.toISOString();
    }

    if (newvalue.startDate != undefined) {
      newvalue.startDate = new Date(newvalue.startDate).toISOString();
    }

    setCustomDate(newvalue);
    console.log(newvalue);
  };

  const handleDownloadClick = async () => {
    if (customDate.startDate == null) {
      alert("Please Select Date");
    } else {
      setLoading(true); // Show loading overlay
      try {
        await userStatement(customDate.startDate, customDate.endDate);
      } catch (error) {
        console.error('Error downloading user statement:', error);
      } finally {
        setLoading(false); // Hide loading overlay
      }
    }
  };

  return (
    <div>
      {/* Loading Overlay */}
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="text-white font-semibold text-lg">Processing ...</div>
        </div>
      )}

      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 z-40 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        onClick={onClose}
      ></div>

      {/* Offcanvas Content */}
      <div
        className={`fixed bottom-0 left-0 right-0 transform transition-transform duration-300 bg-white shadow-lg p-5 z-50 ${isOpen ? 'translate-y-0' : 'translate-y-full'
          }`}
      >
        <div className='flex flex-row justify-between'>
          <h2 className='font-semibold'>Download History</h2>
          <button className="text-gray-600 font-custom" aria-label="Close" onClick={onClose}>
            X
          </button>
        </div>
        <div className="mt-5">

          <div className='summary'>
            <div className="border rounded-md mb-3 mt-4">
              <button
                className={`${activeDateFilter === '1_month' ? 'bg-[#0d6efd] text-white' : 'border border-blue-600 text-blue-600'} w-full py-2 rounded-md mb-2`}
                onClick={() => {
                  setActiveDateFilter('1_month');
                  getDateRange('1 Month');
                }}
              >
                Last 1 Month
              </button>
              <button
                className={`${activeDateFilter === '3_month' ? 'bg-[#0d6efd] text-white' : 'border border-blue-600 text-blue-600'} w-full py-2 rounded-md mb-2`}
                onClick={() => {
                  setActiveDateFilter('3_month');
                  getDateRange('3 Months');
                }}
              >
                Last 3 Month
              </button>
              <button
                className={`${activeDateFilter === '6_month' ? 'bg-[#0d6efd] text-white' : 'border border-blue-600 text-blue-600'} w-full py-2 rounded-md mb-2`}
                onClick={() => {
                  setActiveDateFilter('6_month');
                  getDateRange('6 Months');
                }}
              >
                Last 6 Month
              </button>
              <button
                className={`${activeDateFilter === '1_year' ? 'bg-[#0d6efd] text-white' : 'border border-blue-600 text-blue-600'} w-full py-2 rounded-md mb-2`}
                onClick={() => {
                  setActiveDateFilter('1_year');
                  getDateRange('1 Year');
                }}
              >
                Last 1 Year
              </button>
              <button
                className={`${activeDateFilter === 'custom' ? 'bg-[#0d6efd] text-white' : 'border border-blue-600 text-blue-600'} w-full py-2 rounded-md mb-2`}
                onClick={() => setActiveDateFilter('custom')}
              >
                Custom
              </button>
              {activeDateFilter === 'custom' && (
                <Datepicker value={customDate} onChange={handlevalueChange} popoverDirection='up' primaryColor={"blue"} />
              )}
            </div>
            <div className="pt-1">
              <FullFilledButton
                buttonCaption={"Download"} onClick={handleDownloadClick}
              ></FullFilledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OffCanvasStatement.propTypes = {
  isOpen: PropTypes.any,
  onClose: PropTypes.any
};

export default OffCanvasStatement;
