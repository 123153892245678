import React from "react";
import PropTypes from 'prop-types';

export default function FullFilledButton({ buttonCaption, onClick }) {
  return (
    <button
      onClick={onClick}
      className="w-full bg-[#3b2c23] text-white px-4 py-2 text-[0.8rem] font-custom rounded-md"
    >
      {buttonCaption}
    </button>
  );
}

FullFilledButton.propTypes = {
  buttonCaption: PropTypes.string,
  onClick: PropTypes.func,
};
