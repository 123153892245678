/* eslint-disable no-unused-vars */
import React from "react";
import AppBar from "../../components/AppBar/AppBar";
import { useEffect } from "react";
import { Flip } from "react-toastify";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import appstore from "../../assets/images/appstore.jpeg";
import playstore from '../../assets/images/googleplay.jpeg'
import cash from "../../assets/images/cash.jpeg";
import user2 from "../../assets/images/user_icon.png";
import vs from "../../assets/images/vs-icon.png";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { createRoom } from "../../services/result.service";
import { useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import Loader from "../../components/Loader/Loader";
import { checkResult } from "../../services/challengeresult.service";
import PropTypes from 'prop-types';
import { useWallet } from "../../routes/AppRouter";

export default function ChallengeResult({ socket }) {
  const [role, setRole] = useState("creator");
  const [roomcode, setRoomcode] = useState("");
  const [results, setResultAlready] = useState(false)
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingResult, setLoadingResult] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [prize, setPrize] = useState("");
  const [u_name, setUname] = useState("");
  const [o_name, setOname] = useState("");

  const [compressedFile, setCompressedFile] = useState(null);

  const { challenge_id } = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTagOption, setTagSelectedOption] = useState("");
  const data = useWallet()

  useEffect(() => {
    if (socket != undefined) {


      socket.emit("wallet:fetchWalletBalance", {
        type: "WALLET_BALANCE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),
        },
      });
    }
  }, [])

  const onFocus = () => {
    fetchResult()

    console.log("Tab is in focus");
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {

    console.log("Tab is blurred");
  };

  useEffect(() => {

    socket.emit("wallet:fetchWalletBalance", {
      type: "WALLET_BALANCE",
      params: "",
      data: {
        auth: localStorage.getItem("token"),
      },
    });
  }, [socket])
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    fetchResult()
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);




  const fetchResult = async () => {
    const response = await checkResult(challenge_id)

    if (response != null) {
      if (response.status == 200) {
        // setRoomcode(response.data.room_code == "0" ? "" : response.data.room_code)
        if (response.data.room_code != "0") {
          setReadOnly(true)
          setRoomcode(response.data.room_code)
        }
        if (response.data.result_uploaded == 1) {
          setResultAlready(true)
        }


      }
    }
  }


  useEffect(() => {
    try {
      socket.emit("challenge:joinRoom", {
        type: "CHALLENGE_REQUEST_DECLINE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),

          challenge_id: challenge_id,
        },
      });

      socket.emit("role:fetchRole", {
        type: "FETCH_ROLE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),

          challenge_id: challenge_id,
        },
      });

      socket.on("challenge:receiveRoomCode", (data) => {
        if (data !== null)
          setRoomcode(data.room_code);
      });

      socket.on("challenge:sendMessage", (data) => {
        if (data != null)
          notify(data);
      });
      socket.on("role:readRole", (data) => {

        if (data !== null) {
          setRole(data.role);
          setPrize(data.prize_money);
          setOname(data.o_name);
          setUname(data.u_name);

          if (data.o_name == null || data.o_name == undefined || data.o_name == "") {
            navigate(-1)
          }
        }

      });
    } catch (error) { /* empty */ }


  }, [socket, role, prize, o_name, u_name]);




  const navigate = useNavigate();
  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });
  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });

  const handleTagOptionChange = (event) => {
    setTagSelectedOption(event.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    let fileInfo = "File name: " + image.name + "\n";
    fileInfo += "File type: " + image.type + "\n";
    fileInfo += "File size: " + image.size + " bytes\n";
    fileInfo += "Last modified: " + image.lastModifiedDate;

    // Display the file properties in an alert dialog
    alert(fileInfo);
    setCompressedFile(image);
    // new Compressor(image, {
    //   quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
    //   success: (compressedResult) => {
    //     console.log(compressedResult);
    //     // compressedResult has the compressed file.
    //     // Use the compressed file to upload the images to your server.

    //     setCompressedFile(compressedResult);
    //   },
    // });
  };

  return (
    <>
      <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
        {/* nav bar */}
        <AppBar socket={socket} context={data}></AppBar>
      </div>
      <div className="p-3 mx-auto bg-[#fff6f0] mt-[64px] ">
        <ToastContainer />

        {/* vs card */}

        <div className="main-card rounded-t-2xl py-2">
          <div className="border bg-white rounded m-2 border-t-4 border-red-400">
            <div className="p-4 ">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="bg-dark rounded-full mr-2 w-8 h-8 ">
                    <img src={user2} alt="" />
                  </div>
                  {/* <span className="font-semibold">{u_name}</span> */}
                  <span className="font-semibold font-custom text-[0.8rem]">Rakesh</span>
                </div>
                <div>
                  <img src={vs} alt="" className="w-10" />
                </div>
                <div className="flex flex-row-reverse items-center">
                  <div className="bg-dark rounded-full ml-2 w-8 h-8 ">
                    <img src={user2} alt="" />
                  </div>
                  {/* <span className="font-semibold">{o_name}</span> */}
                  <span className="font-semibold font-custom text-[0.8rem]">Yogesh</span>
                </div>
              </div>
              <div className="flex items-center justify-center pt-1">
                <span className="text-xl text-[#3b2c23] font-bold font-custom text-[0.8rem]">
                  Rs.{prize}
                </span>
              </div>
            </div>
          </div>

          <div className="border bg-white rounded shadow-md border-red-400 text-center p-4 m-2">
            <p className="text-red-600 font-semibold text-[10px] mb-2 font-custom">
              Important Notification
            </p>
            <p className="text-red-600 text-[10px] mb-2 font-custom">
              रूम कोड केवल क्लासिक मोड में ही स्वीकार किया जाएगा।
            </p>
            <p></p>
            <p className="text-red-600 text-[10px] mb-2 font-custom">
              लुडोकिंग पे जा के रूम कोड क्रिएट कर के निचे दिए ऑप्शन में डाले।
            </p>
            <p className="text-sm mt-1 mb-2 font-semibold font-custom">Room Code</p>
            {loading ? (
              <center>
                <Loader></Loader>
              </center>
            ) : (
              <>
                {role == "creator" ? (
                  <>
                    <input
                      type="tel"
                      value={roomcode}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          setRoomcode(e.target.value);
                        }
                      }}
                      disabled={readOnly}
                      maxLength={8}
                      minLength={8}
                      className=" border border-e-gray-300 px-3 py-[3px] 
              rounded focus:outline-none focus:border-[#86b7fe] font-custom text-center text-[0.8rem] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                    />

                    <div className="my-2">
                      {readOnly == true ?
                        <>
                          <p className="text-[0.8rem] font-custom text-green-600">Code Set</p>
                        </> :
                        <button
                          className=" mb-3 text-white px-3 py-1 text-[0.7rem] font-custom rounded-sm"
                          style={{ backgroundColor: "#3b2c23" }}
                          disabled={readOnly}
                          // eslint-disable-next-line no-unused-vars
                          onClick={async (e) => {

                            if (roomcode.startsWith("0")) {
                              setLoading(true);
                              const data = {
                                challenge_id: challenge_id,
                                room_code: roomcode.toString(),
                              };

                              const result = await createRoom(data);
                              setLoading(false);

                              if (result.status == 401) {
                                localStorage.clear();
                                navigate("/login");
                              }
                              if (result.status != 200) {
                                notify(result.message);
                              }

                              if (result.status == 200) {
                                setReadOnly(true);
                                //notify(result.message);
                                socket.emit("challenge:sendRoomCode", result.data);
                              }
                            } else {
                              notify("Incorrect Room Code");
                            }

                            // setReadOnly(true);
                          }}
                        >

                          Set Room Code

                        </button>
                      }



                    </div>
                  </>
                ) : (
                  <>
                    <div className="my-2">
                      <p
                        className=" mb-3 text-white px-4 py-2 rounded-md font-custom text-[0.8rem]"
                        style={{ backgroundColor: "#3b2c23" }}
                      >
                        {roomcode == "" ? "Waiting for Room Code   " : roomcode + "  "}
                        {roomcode != "" ? <span>
                          <i onClick={async () => {
                            notifySuccess("Refferal Code Copied")
                            await navigator.clipboard.writeText(roomcode);
                          }}
                            className="fa-regular fa-clipboard"
                            aria-hidden="true"
                          />
                        </span> : <></>}

                      </p>

                    </div>
                  </>
                )}
              </>
            )}

            <p className="font-medium font-custom text-[0.9rem]">Play Ludo Game Ludo King App</p>
            <div className="flex items-center justify-center gap-2 my-2">
              <img src={appstore} alt="" style={{ width: "30%" }} />
              <img src={playstore} alt="" style={{ width: "30%" }} />
            </div>
          </div>


          {results ? <><p className="my-2">
            Result is declared or Uploaded by you
          </p></> : <> <div className="shadow-md border bg-white border-t-4 border-red-400 rounded-md m-2">
            <div className="text-center px-4 py-2 bg-red-400 text-white font-custom text-[0.9rem]">Match Status</div>
            <div className="p-4">
              <div>
                <div className="flex items-center  px-2">
                  {loadingResult ? <center><Loader></Loader></center> : <div className="flex flex-col  ">
                    <p className="my-2 font-custom text-[0.8rem]">
                      After completetion of your game , select the status of the
                      game and post your screenshot below.
                    </p>
                    <div>
                      <div className="flex justify-start items-center gap-2 my-2">

                        {/* <button className="w-full bg-green-6000">
                          I WON THE GAME
                        </button> */}

                        <input
                          type="radio"
                          id="won"
                          name="result"
                          value={"won"}
                          onChange={handleOptionChange}
                          checked={selectedOption === "won"}
                          data-gtm-form-interact-field-id={1}
                        />
                        <label htmlFor="won" className="font-custom text-sm">I Won</label>
                        <br />
                        <input
                          type="radio"
                          name="result"
                          value={"lost"}
                          onChange={handleOptionChange}
                          checked={selectedOption === "lost"}
                          data-gtm-form-interact-field-id={1}
                        />
                        <label htmlFor="lost" className="font-custom text-sm">I Lost</label>
                        <br />
                        <input
                          type="radio"
                          id="cancel"
                          name="result"
                          value={"cancel"}
                          onChange={handleOptionChange}
                          checked={selectedOption === "cancel"}
                          data-gtm-form-interact-field-id={1}
                        />
                        <label htmlFor="cancel" className="font-custom text-sm">Cancel</label>
                      </div>
                      <div
                        id="uploadDiv"
                        style={{
                          display: selectedOption == "won" ? "block" : "none",
                        }}
                        className="my-2"
                      >
                        <label htmlFor="file" hidden="" className="font-custom text-[0.7rem]">
                          Upload Image:
                        </label>
                        <input
                          className="block mt-2 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                          aria-describedby="file_input_help"
                          id="file"
                          onChange={(e) => handleCompressedUpload(e)}
                          type="file"
                          name="file"
                        />
                        <p className="mt-1 text-[0.5rem] text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG or GIF (MAX. 800x400px).</p>
                        {/* <input
                          type="file"
                          id="file"
                          name="file"

                          className="mt-2"
                        /> */}
                      </div>
                      <div
                        id="reasondiv"
                        className="my-2"
                        style={{
                          display: selectedOption == "cancel" ? "block" : "none",
                        }}
                      >
                        {/* <label for="reason">Please Select Any Reason</label> */}
                        <select
                          name="reason"
                          id="reason"
                          className="border"
                          value={selectedTagOption}
                          onChange={handleTagOptionChange}
                        >
                          <option value="Please Select Any Reason">
                            Please Select Any Reason
                          </option>
                          <option value="No Room Code">No Room Code</option>
                          <option value="Room Code in Popular Mode">
                            Room Code in Popular Mode
                          </option>
                          <option value="Game Not Started">
                            Game Not Started
                          </option>
                          <option value="Not Joined">Not Joined</option>
                          <option value="Not Playing">Not Playing</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <button
                        className=" mb-3 text-white px-4 py-2 text-[0.9rem] font-custom rounded-sm my-2 w-full"
                        style={{ backgroundColor: "#3b2c23" }}
                        // eslint-disable-next-line no-unused-vars
                        onClick={async (e) => {
                          setLoadingResult(true);
                          const authToken = localStorage.getItem("token");

                          var formData = null;
                          if (selectedOption == "cancel") {

                            setCompressedFile(null);
                            if (selectedTagOption == "Please Select Any Reason") {
                              notify("Please Select Any Reason");
                              setLoadingResult(false);
                              return;
                            }

                            formData = new FormData();

                            formData.append("challenge_id", challenge_id);
                            formData.append("response", selectedOption);
                            formData.append("reason", selectedTagOption);

                          } else if (selectedOption == "lost") {
                            setCompressedFile(null);

                            formData = new FormData();
                            formData.append("challenge_id", challenge_id);
                            formData.append("response", selectedOption);

                          } else if (selectedOption == "won") {
                            formData = new FormData();
                            formData.append("challenge_id", challenge_id);
                            formData.append("response", selectedOption);
                            if (compressedFile == null) {
                              notify("Please Select Image if your is Won")
                              setLoadingResult(false);
                              return
                            }
                            formData.append("file", compressedFile);
                          }

                          try {
                            if (selectedOption == "won" && compressedFile == null) {
                              notify("Please Select Image if you are winner")
                              setLoadingResult(false);
                              return
                            }
                            console.log(formData)

                            const response = await fetch(
                              // eslint-disable-next-line no-undef
                              `${process.env.REACT_APP_CHALLENGE_CHECK_API}`,
                              {
                                method: "POST",
                                headers: {
                                  Authorization: `Bearer ${authToken}`, // Include auth token in the Authorization header
                                },
                                body: formData,
                              }
                            );
                            if (response.status == 200) {
                              socket.emit("view:removeChallenge", {
                                type: "REMOVE_CHALLENGE_VIEW",
                                params: "",
                                data: {
                                  auth: localStorage.getItem("token"),
                                  challenge_id: challenge_id
                                },
                              });
                              setReadOnly(true)
                            }
                            console.log(formData.get('file'))
                            let fileInfo = "File name: " + formData.get('file').name + "\n";
                            fileInfo += "File type: " + formData.get('file').type + "\n";
                            fileInfo += "File size: " + formData.get('file').size + " bytes\n";
                            fileInfo += "Last modified: " + formData.get('file').lastModifiedDate;
                            let formDataString = 'FormData:\n';
                            for (let pair of formData.entries()) {
                              formDataString += pair[0] + ': ' + pair[1] + '\n';
                            }

                            // Alert the FormData representation
                            alert(formDataString);
                            alert(fileInfo)
                          } catch (e) { /* empty */ }
                          setLoadingResult(false);
                          const response = await checkResult(challenge_id)

                          if (response != null) {
                            if (response.status == 200) {
                              socket.emit("wallet:fetchWalletBalance", {
                                type: "WALLET_BALANCE",
                                params: "",
                                data: {
                                  auth: localStorage.getItem("token"),
                                },
                              });
                              navigate("/challenges")
                              // setRoomcode(response.data.room_code == "0" ? "" : response.data.room_code)
                              if (response.data.room_code != "0") {
                                setReadOnly(true)
                                setRoomcode(response.data.room_code)
                              }
                              if (response.data.result_uploaded == 1) {
                                setResultAlready(true)
                              }

                              notifySuccess(response.message)
                            }
                            if (response.status == 400) {
                              notify("Invalid Room Code")
                            }
                          }
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>}

                </div>
              </div>
            </div>
          </div></>}
        </div>


      </div>
    </>
  );
}
ChallengeResult.propTypes = {
  socket: PropTypes.any
};
