import React from "react";
import AppBar from "../../components/AppBar/AppBar";


import PropTypes from 'prop-types';

import { useWallet } from "../../routes/AppRouter";

import { useNavigate } from "react-router-dom";

import { checkGameOnly } from "../../services/challenge.service";
import { ToastContainer } from "react-toastify"
import { useEffect } from "react";
import BigRoomList from "../../components/Room List/BigRoomList";





export default function AutoPlayBig({ socket }) {
    const navigate = useNavigate()
    const allOpenChallenges = async () => {



        const response = await checkGameOnly()

        if (response.status == 401) {
            localStorage.clear()
            navigate("/login")
        }
        if (response.status == 200) {
            console.log(response.data);





        }
    }

    useEffect(() => {
        allOpenChallenges()

    }, [])

    useEffect(() => {

        socket.emit("wallet:fetchWalletBalance", {
            type: "WALLET_BALANCE",
            params: "",
            data: {
                auth: localStorage.getItem("token"),
            },
        });

    }, [socket])


    const data = useWallet();










    return (
        <>
            <ToastContainer></ToastContainer>
            <div className="w-[100vw] fixed top-0 z-20  md:w-[38%]">
                {/* nav bar */}
                <AppBar socket={socket} context={data}></AppBar>
            </div>



            <div className="p-3 mx-auto bg-white mt-[64px] h-full min-h-[100vh] ">

                {/* card 1 */}


                <BigRoomList entry={1000} available={true} socket={socket} ></BigRoomList>
                <BigRoomList entry={2000} available={false} socket={socket} ></BigRoomList>
                <BigRoomList entry={3000} available={false} socket={socket} ></BigRoomList>
                <BigRoomList entry={4000} available={false} socket={socket} ></BigRoomList>
                <BigRoomList entry={5000} available={false} socket={socket} ></BigRoomList>




            </div>



        </>
    );
}

AutoPlayBig.propTypes = {
    socket: PropTypes.any
};
