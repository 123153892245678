import React from "react";
import PropTypes from 'prop-types';

export default function HollowButton({ buttonCaption, onClick }) {
  return (
    <button
      onClick={onClick}
      className="px-2 py-1 border rounded-2xl border-gray-200 font-custom text-xs px-4 py-[6px] text-['#3b2c23'] hover:bg-[#0d6efd] hover:text-white"
    >
      {buttonCaption}
    </button>
  );
}

HollowButton.propTypes = {
  buttonCaption: PropTypes.string,
  onClick: PropTypes.func,
};
