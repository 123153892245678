/* eslint-disable no-undef */
const react_jwt = require("react-jwt");

module.exports.isJWT = (token) => {
  try {
    // Attempt to decode the token
    const decodedToken = react_jwt.decodeToken(token);

    // Check if the decoded token is not null and contains necessary fields
    if (decodedToken) {
      // If all necessary fields are present, the token is likely a JWT
      return true;
    }

    // If any of the required fields are missing, it's not a valid JWT
    return false;
  } catch (error) {
    // If an error occurs during decoding, the token is not a valid JWT
    return false;
  }
};
