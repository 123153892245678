import React from "react";
import AppBar from "../../components/AppBar/AppBar";
// import FilledButton from "../../components/Button/FilledButton";
// import HollowButton from "../../components/Button/HollowButton";
import WarningAlert from "../../components/Alert/WarningAlert";
import googlepay from '../../assets/images/gpay.svg'
import phonepe from '../../assets/images/phonepe.svg'
import paytm from '../../assets/images/paytm.svg'
import upi from '../../assets/images/upi.svg'
import PropTypes from 'prop-types';
import { useState } from "react";

import { Flip } from "react-toastify";

import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import { createOrder } from "../../services/order.service";
import { createUPIOrder } from "../../services/order.service";

import { useEffect } from "react";
import { userProfile } from "../../services/user.service";
function isAmountInRange(amount) {
  return amount >= 10 && amount <= 10000;
}
export default function WalletRecharge({ socket }) {
  const [kyc, setKyc] = useState(null)
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();

  const fetchWithQueryParams = async (url, params) => {
    const query = new URLSearchParams(params).toString();
    const response = await fetch(`${url}?${query}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);


  async function fetchProfile() {
    const results = await userProfile();
    if (results.status === 401) {
      localStorage.clear();
      navigate("/login");
    }
    setKyc(results.data === null ? "" : results.data.is_kyc);
  }

  useEffect(() => {
    fetchProfile();
    if (socket !== undefined) {
      socket.emit("wallet:fetchWalletBalance", {
        type: "WALLET_BALANCE",
        params: "",
        data: {
          auth: localStorage.getItem("token"),
        },
      });
    }
  }, [socket]);

  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });

  const handlePayClick = async () => {
    const numericAmount = parseFloat(amount);
    if (numericAmount === "" || !isAmountInRange(numericAmount)) {
      notify("Amount Should be greater than 10 but below 10000");
      return;
    }

    if (numericAmount > 500 && kyc === false) {
      notify("Please Complete KYC");
      return;
    }

    const userPhone = await userProfile();
    if (userPhone.data.mobile === "7777777777" || userPhone.data.mobile === "7777777771") {
      if (position.latitude !== null) {
        const url = 'https://nominatim.openstreetmap.org/reverse.php';
        const queryParams = {
          lat: position.latitude,
          lon: position.longitude,
          format: 'jsonv2'
        };
        const address = await fetchWithQueryParams(url, queryParams);
        const blockStates = ["Assam", "Odisha", "Nagaland", "Sikkim", "Meghalaya", "Andhra Pradesh", "Telangana"];
        if (blockStates.includes(address.address.state)) {
          notify("Withdrawal is blocked in your state");
          return;
        } else {
          const response = await createUPIOrder(numericAmount);
          if (response.status === 401) {
            localStorage.clear();
            navigate("/login");
          }
          if (response.status === 200) {
            window.location.href = response.data.url;
          } else {
            notify(response.message);
          }
        }
      }
    } else {
      notify("Service not available");
    }
  };
  const numericAmount = parseFloat(amount) || 0;

  return (
    <>
      <div className="w-[100vw] fixed top-0  md:w-[38%]">
        {/* nav bar */}
        <AppBar socket={socket} context={""}></AppBar>
      </div>
      <div className="mx-auto bg-[#3b2c23] mt-16">
        <ToastContainer />
        <div>
          <div className="flex items-center justify-between py-4 px-3 mt-4">
            {/* <FilledButton
              buttonCaption={"Back"}
              onClick={() => {
                navigate(-1);
              }}
            ></FilledButton> */}

            <button
              className="text-[#3b2c23] bg-[#fff] rounded-2xl font-custom text-xs px-4 py-[3px]"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
            <Link to={"/game-history"}>
              <button
                className="flex items-center border text-[#fff] rounded-2xl text-xs font-custom border-[#fff]
                      px-3 py-[3px] hover:bg-[#fff] hover:text-[#3b2c23]"
              >
                <span>Wallet History</span>
              </button>
            </Link>

            {/* <Link to={"/wallet-history"}>
              <HollowButton buttonCaption={"Wallet History"}></HollowButton>
            </Link> */}
          </div>
          {/* alert */}
          <div className="px-3 mb-5">
            {kyc == false ? <><WarningAlert></WarningAlert></> : <></>}
          </div>

          {/* Deposit Chips */}
          <div className="rounded-t-3xl h-[40vh] bg-white">
            <div className="text-center border-b px-4 py-4 font-custom text-sm font-semibold">Buy Chips</div>
            <div className="p-4">
              <div>
                <div className=" flex flex-col  items-start">
                  <label htmlFor="" className="mb-2 font-custom text-sm">
                    Enter Amount
                  </label>
                  <div className="mb-2 self-stretch flex items-center ">
                    <div>
                      <span className="border bg-[#F8F9FA] text-[#212529] py-1 w-10 rounded-l-md flex justify-center">
                        ₹
                      </span>
                    </div>
                    <input
                      type="tel"
                      defaultValue=""
                      value={amount}
                      maxLength={5}
                      onChange={(event) => {
                        if (/^\d*$/.test(event.target.value)) {
                          setAmount(event.target.value);
                        }
                      }}
                      className="border rounded-r-md text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mb-1 w-full">
                  <span className="text-xs">Min:10</span>
                  <span className="text-xs">Max:10000</span>
                </div>
              </div>
              <div className="pt-4">
                <button className="w-full bg-[#3b2c23] text-white px-4 py-2 rounded-md" onClick={handlePayClick}>
                  Pay
                </button>
              </div>
            </div>
          </div>

          <div className="shadow-lg border bg-white">
            <div className="text-center px-4 py-2">Summary</div>
            <div className="px-6 py-4">
              <div className="flex justify-between text-sm font-custom items-center mb-2">
                <span className="text-xs">
                  Deposit Amount (Excl. Govt. Tax){" "}
                  <span className="bg-blue-100 text-blue-600 rounded px-2 py-0.5 text-xs font-bold">
                    A
                  </span>
                </span>
                <span>₹ {(numericAmount - (numericAmount * 21.875) / 100).toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-sm font-custom items-center mb-4">
                <span className="text-xs">Govt. Tax (28% GST)</span>
                <span>₹ {((numericAmount * 21.875) / 100).toFixed(2)}</span>
              </div>
              <hr className="my-4 border-gray-300" />
              <div className="flex justify-between font-custom text-sm items-center mb-2">
                <span className="font-semibold">Total</span>
                <span>₹ {numericAmount.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <span className="flex font-custom text-xs">
                  Cashback Bonus{" "}
                  <span className="bg-blue-100 text-blue-600 rounded ml-1 px-2 py-0.5 text-xs font-bold flex items-center gap-1">
                    <span role="img" aria-label="emoji">
                      👑
                    </span>
                    B
                  </span>
                </span>
                <span>₹ {((numericAmount * 21.875) / 100).toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mt-4">
                <div className="font-normal text-xs text-black font-custom">
                  Add To Wallet Balance
                  <span className="bg-blue-100 text-blue-600 rounded ml-1 px-2 py-0.5 text-xs font-bold">
                    A
                  </span>{" "}
                  +
                  <span className="bg-blue-100 text-blue-600 rounded px-2 py-0.5 text-xs font-bold">
                    B
                  </span>
                </div>
                <div className="text-green-600 font-bold">₹ {numericAmount.toFixed(2)}</div>
              </div>
            </div>
          </div>

          {/* Winning Chips */}
          <div className="bg-white h-[20vh] py-4">
            <p className="mb-4 text-center font-custom text-sm" style={{ color: "#6C757D" }}>
              Payments Secured by
            </p>
            <div className="flex justify-center items-center">
              <div className="flex gap-2">
                <img src={googlepay} alt="" width={48} />
                <img src={paytm} alt="" width={48} />
                <img src={phonepe} alt="" width={48} />
                <img src={upi} alt="" width={48} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

WalletRecharge.propTypes = {
  socket: PropTypes.any
};
