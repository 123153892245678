import React from "react";
import appLogo from "../../assets/images/khel-dost-logo.webp";
export default function RightContainer() {
  return (
    <div className=" hidden  md: bg-white  md:flex flex-col items-center justify-center w-[61%] h-[100vh] fixed right-0 ">
      <img src={appLogo} alt="" className="w-48 mb-10" />
      <div>
        <h1 className="text-3xl font-custom uppercase">
          Khel Dost & <span className="font-black text-[#333]">Win Real Cash!</span>
        </h1>
      </div>
    </div>
  );
}
