import React from "react";
import handWave from "../../assets/images/helllo.png";
import user from "../../assets/images/user.svg";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div
      className=" bg-white h-[100vh] w-96 fixed z-20 max-w-full left-[-400px] top-0 "
      id="sidemenu"
    >
      <div className="bg-[#212529] flex items-center justify-between p-4">
        <div className="text-white">
          <h5 className="text-2xl font-semibold">Khel Dost</h5>
        </div>
        <button type="button" onClick={() => { }}>
          <i className="fa-solid fa-xmark text-white" />
        </button>
      </div>
      <div className="flex flex-col items-stretch justify-start p-0 grow overflow-y-auto">
        <div className="flex items-center justify-between p-4">
          <div className="flex">
            <h1 className="text-4xl text-[#212529] font-bold">Hey,&nbsp;</h1>
            <img src={handWave} alt="" className="w-9" />
          </div>
          <Link to={"/profile"}>
            <img src={user} alt="" className="w-10" />
          </Link>
        </div>
      </div>
    </div>
  );
}
