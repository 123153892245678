/* eslint-disable no-undef */
import React from "react";
import user from "../../assets/images/user.svg";
import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom";

import { fetchWallet } from "../../services/user.service";
import { Flip } from "react-toastify";

import { toast } from "react-toastify";
export default function BigChallengesCard({
  challenge_id,
  entry,
  username,
  socket,
}) {
  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });
  const navigate = useNavigate()
  return (
    <div className=" border rounded-md mb-3 ">
      <div className="border-b-2 px-4 pb-2 bg-[#F7F7F7] flex justify-between items-center ">
        <p className="mt-2">Challenge set by</p>
        <p className="text-green-700 text-xl font-bold">Rs.{entry}</p>
      </div>
      <div className="p-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <img src={user} alt="" className="w-6" />
          <span className=" font-semibold">{username}</span>
        </div>
        <button
          className="px-2 py-1 border rounded-md  text-white bg-[#0d6efd] "
          // eslint-disable-next-line no-unused-vars
          onClick={async (e) => {
            const wallet = await fetchWallet()
            if (wallet.status == 401) {
              navigate("/login")
              localStorage.clear()

            }
            if (wallet.status == 200) {

              console.log(wallet.data.wallet_balance)
              if (wallet.data.wallet_balance >= entry) {
                console.log("hhh")
                socket.emit(`big:challenge:request`, {
                  type: "CHALLENGE_REQUESTED",
                  params: "",
                  data: {
                    auth: localStorage.getItem("token"),

                    challenge_id: challenge_id,
                  },
                });
              } else {
                notify("Insufficient Funds")
              }
            }
          }
          }
        >
          Play
        </button>
      </div>
    </div>
  );
}

BigChallengesCard.propTypes = {
  challenge_id: PropTypes.string,
  entry: PropTypes.string,
  username: PropTypes.string,
  socket: PropTypes.any,
};