import React, { useState } from "react";
import AppBar from "../../components/AppBar/AppBar";
import FullFilledButton from "../../components/Button/FullFilledButton";
import RightContainer from "../../components/Common/RightContainer";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../../services/auth.service";
import { Flip } from "react-toastify";

import { ToastContainer, toast } from "react-toastify";
export default function Register() {

  const [mobile, setMobile] = useState("");
  const [fullname, setFullName] = useState("");
  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });
  const [refer_by, setReferBy] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <div className="flex gap-2">
        <ToastContainer />
        <div className="w-[100vw] fixed top-0  md:w-[38%]">
          <div className="w-[100vw] fixed top-0  md:w-[38%]">
            {/* nav bar */}
            <AppBar></AppBar>
          </div>
          <div className="p-3 mx-auto bg-white mt-16 h-[100vh]">
            <div className="rounded bg-[#fff3cd] p-[0.9rem] text-center mb-3">
              <p className="font-bold text-[0.85rem] text-[#664d03] font-custom">Register Now & Get ₹20 Free Signup Bonus ⭐️</p>
            </div>
            <div>
              {/* login*/}
              <div className=" border rounded-md mb-3">
                <div className="text-center border-b-2 px-4 py-2 bg-[#F8F9FA] rounded font-custom text-[0.9rem]">
                  Register
                </div>
                <div className="p-4">
                  <form action="">
                    <div className="mb-4 flex flex-col items-start">
                      <label htmlFor="" className="mb-2 font-custom text-[0.9rem]">
                        Full Name
                      </label>
                      <input
                        type="text"
                        value={fullname}
                        onChange={(event) => {

                          setFullName(event.target.value);

                        }}
                        className="border rounded-md
                           text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                      />
                    </div>
                    <div className="mb-4 flex flex-col items-start">
                      <label htmlFor="" className="mb-2 font-custom text-[0.9rem]">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        value={mobile}
                        onChange={(event) => {
                          if (/^\d*$/.test(event.target.value)) {
                            setMobile(event.target.value);
                          }
                        }}
                        maxLength={10}

                        className="border rounded-md
                           text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                      />
                    </div>
                    <div className="mb-4 flex flex-col items-start">
                      <label htmlFor="" className="mb-2 font-custom text-[0.9rem]">
                        Refer Code (Optional)
                      </label>
                      <input
                        type="text"
                        value={refer_by}
                        onChange={(event) => {

                          setReferBy(event.target.value);

                        }}
                        className="border rounded-md
                           text-[#212529] px-3 py-1 w-full focus:outline-none focus:border-[#86b7fe] focus:ring-4 focus:ring-[rgba(13,110,253,.25)]"
                      />
                    </div>
                  </form>
                  <p className="text-center font-custom text-[0.7rem]">
                    By Continuing, you agree to our{" "}
                    <Link to={localStorage.getItem('token') != null ? "/legal" : "/legaloffline"} className="text-[#0d6efd] underline">
                      Legal Terms
                    </Link>{" "}
                    and you are 18 years or older.
                  </p>
                  <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                    <FullFilledButton onClick={async () => {
                      const data = {
                        mobile: mobile,
                        fullname: fullname,

                        refer_by: refer_by
                      }
                      if (fullname.trim().length == 0) {
                        notify("First name Cannot be Empty")
                        return
                      }
                      if (mobile.trim().length == 0 || mobile.trim().length < 10) {
                        notify("Mobile should be 10 digits")
                        return
                      }
                      const respone = await registerUser(data)
                      if (respone.status == 200) {
                        navigate("/login")
                      }
                      notify(respone.message)

                    }}
                      buttonCaption={"Submit"}
                    ></FullFilledButton>
                  </div>
                  <p className="mb-3 text-center text-[0.7rem] font-custom">
                    Already have an account?
                    <Link to="/login" className="text-[#0d6efd] underline">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightContainer></RightContainer>
      </div>
    </>
  );
}
