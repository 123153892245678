import React from "react";
import user from "../../assets/images/user.svg";
import user2 from "../../assets/images/user2.svg";
import vswep from "../../assets/images/vs.webp";

import PropTypes from 'prop-types';

export default function WebVScard({

    prize,
    o_name,
    username,
}) {

    return (
        <div className="border rounded my-2">
            <div className="p-4 pb-2">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div className="bg-dark rounded-full mr-2 w-6 h-6 ">
                            <img src={user2} alt="" />
                        </div>
                        <span className="font-semibold">{username}</span>
                    </div>
                    <div>
                        <img src={vswep} alt="" className="w-8" />
                    </div>
                    <div className="flex flex-row-reverse items-center">
                        <div className="bg-dark rounded-full ml-2 w-6 h-6 ">
                            <img src={user} alt="" />
                        </div>
                        <span className="font-semibold">{o_name}</span>
                    </div>
                </div>
                <div className="flex items-center justify-center pt-3">
                    <span className="text-xl text-green-700 font-bold">Rs.{prize}</span>
                </div>
            </div>

        </div>
    );
}

WebVScard.propTypes = {

    prize: PropTypes.any,
    o_name: PropTypes.string,
    username: PropTypes.string,
};
