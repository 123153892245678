import React, { useState, useEffect } from 'react';
import { getGameHistory, getPenaltyHistory, getRefferalHistory, getWalletHistory, userBiil } from '../../services/user.service';
import { useNavigate } from 'react-router-dom';
import see from '../../assets/images/see.png'
import PropTypes from 'prop-types'

const PaginationComponent = ({ filter }) => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Reset currentPage to 1 when filter changes
        setCurrentPage(1);
    }, [filter]);

    useEffect(() => {
        fetchData();
    }, [currentPage, filter]); // Fetch data when currentPage or filter changes

    const fetchData = async () => {
        try {
            let response;
            if (filter === 'game') {
                response = await getGameHistory(currentPage);
            } else if (filter === 'wallet') {
                response = await getWalletHistory(currentPage);
            } else if (filter === 'penalty') {
                response = await getPenaltyHistory(currentPage);
            } else if (filter === 'referral') {
                response = await getRefferalHistory(currentPage);
            }

            if (response.status === 401) {
                localStorage.clear();
                navigate("/login");
            }
            if (response.status === 200) {
                setData(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const goToNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    return (
        <div>
            {loading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                    <div className="text-white font-semibold text-lg">Processing ...</div>
                </div>
            )}
            {data.length == 0 ? <center className='font-custom text-sm mt-4'> No Records</center> :
                <>
                    {/* Render data */}
                    <ul>
                        {data.map(item => (

                            <li key={item._id}><div className="flex  border-b-2 py-2 ">
                                <div className="flex flex-col items-start justify-center ">
                                    <img src={see} className='w-5 h-5' alt="" />
                                    <span className="text-[0.7rem]">{`${new Date(item.createdAt).toLocaleString().split(",")[0]}`}</span>
                                    <span className="text-[0.7rem]">{`${new Date(item.createdAt).toLocaleString().split(",")[1]}`}</span>
                                </div>
                                <div className='vertical-separator w-[1px] ml-2 bg-gray-300'></div>
                                <div
                                    className="flex flex-col items-stretch justify-center flex-1 w-full px-2"
                                    style={{ borderLeft: "2px !important" }}
                                >
                                    <div className="flex items-stretch justify-between">
                                        <div>
                                            <span className="font-semibold text-[0.7rem] font-custom">
                                                {item.remark} {filter === 'wallet' && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ml-2 bi bi-eye" viewBox="0 0 16 16" onClick={async () => {
                                                        setLoading(true); // Show loading overlay
                                                        try {
                                                            await userBiil(item.order_id);
                                                        } catch (error) {
                                                            console.error('Error downloading user statement:', error);
                                                        } finally {
                                                            setLoading(false); // Hide loading overlay
                                                        }
                                                    }}>
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                    </svg>
                                                )}
                                            </span>
                                            <div className="flex items-center justify-between">
                                                <span className="text-[0.6rem] font-custom">Room Code: {item.room_code}</span>
                                            </div>
                                        </div>

                                        <div>
                                            {item.type == 1 ? <span className="my-1 font-bold font-custom text-[0.6rem] block text-right text-red-600">
                                                {`(-) ${item.amount}`}
                                            </span> : <span className="my-1 font-bold font-custom block text-right text-[0.6rem] text-green-600">
                                                {item.remark.split(" ")[0] == "Cancel" ? `` : `(+) ${item.amount}`}
                                            </span>}
                                            <div className="flex items-center justify-between">

                                                <span className="text-[0.6rem] font-custom">Closing Balance: {item.close_balance}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div></li>
                        ))}
                    </ul>

                    {/* Pagination controls */}
                    <div className="flex items-center justify-center">
                        {currentPage == 1 ? <></> :
                            <button className='px-2 py-1 w-[40%] mt-3 rounded-l text-white bg-[#3b2c23] text-sm' onClick={goToPreviousPage} disabled={currentPage === 1}>Previous</button>
                        }
                        {/* <button className='px-2 py-1 w-[40%] mt-3 text-white bg-[#554135] text-sm'>1</button> */}

                        {data.length === 0 || data.length < 10 ? null : (
                            <button className={`px-2 py-1 w-[40%] mt-3 rounded-r ${currentPage === 1 && ('rounded-l')} disabled text-white bg-[#3b2c23] text-sm`} onClick={goToNextPage}>{"Next"}</button>
                        )}
                    </div>
                </>
            }
        </div>
    );
};

PaginationComponent.propTypes = {
    filter: PropTypes.any
};

export default PaginationComponent;
