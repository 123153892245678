import React from "react";
import user from "../../assets/images/user.svg";
import user2 from "../../assets/images/user2.svg";
import vswep from "../../assets/images/vs.webp";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { checkToken } from "../../services/auth.service";
import { submitGameChallenge, viewGameChallenge } from "../../services/challenge.service";
import Cookies from "universal-cookie";
import { checkGameStatus } from "../../services/user.service";

export default function WebOnevsOneCard({
  socket,
  challenge_id,
  prize,
  o_name,
  username,
}) {
  const navigate = useNavigate()
  return (
    <div className="border rounded my-2">
      <div className="p-4 pb-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="bg-dark rounded-full mr-2 w-6 h-6 ">
              <img src={user2} alt="" />
            </div>
            <span className="font-semibold">{username}</span>
          </div>
          <div>
            <img src={vswep} alt="" className="w-8" />
          </div>
          <div className="flex flex-row-reverse items-center">
            <div className="bg-dark rounded-full ml-2 w-6 h-6 ">
              <img src={user} alt="" />
            </div>
            <span className="font-semibold">{o_name}</span>
          </div>
        </div>
        <div className="flex items-center justify-center pt-3">
          <span className="text-xl text-green-700 font-bold">Rs.{prize}</span>
        </div>
      </div>
      <div className="flex justify-end p-2 gap-2">
        <button
          className="px-2 py-1 border rounded-md  text-white bg-green-500 "
          // eslint-disable-next-line no-unused-vars
          onClick={async (e) => {

            const response = await checkToken();
            if (response.status == 401) {
              navigate("/login")
              localStorage.clear()

            }
            if (response.status == 200) {
              socket.emit("gameChallenge:requestAccept", {
                type: "CHALLENGE_REQUEST_ACCEPT",
                params: "",
                data: {
                  auth: localStorage.getItem("token"),

                  challenge_id: challenge_id,
                },
              });
              socket.emit("wallet:fetchWalletBalance", {
                type: "WALLET_BALANCE",
                params: "",
                data: {
                  auth: localStorage.getItem("token"),
                },
              });
              socket.emit("gameChallenge:joinRoom", {
                type: "CHALLENGE_REQUEST_DECLINE",
                params: "",
                data: {
                  auth: localStorage.getItem("token"),

                  challenge_id: challenge_id,
                },
              });
              localStorage.removeItem("opponent_token")
              localStorage.setItem("creator_token", localStorage.getItem("token"))
              localStorage.setItem("challenge_id", challenge_id)
              const cookies = new Cookies(null, { path: '/' });

              cookies.set('game_data', JSON.stringify({ creator_token: localStorage.getItem("token"), challenge_id: challenge_id }));
              console.log(cookies.get('game_data')); // Pacman
              try {
                const challenge_data = { challenge_id: challenge_id }
                const challenge = await viewGameChallenge(challenge_data)
                const checkbot = await checkGameStatus()
                console.log(challenge.data)

                const submitGameData = {
                  challenge_id: challenge_id,
                  type: "2token",
                  creator_token: localStorage.getItem("token"),
                  creator_username: challenge.data.u_name,
                  opponent_token: "",
                  opponent_username: "",
                  room_code: challenge.data.room_code,
                  entry_fee: challenge.data.entry_fee,
                  bot_play: challenge.data.play,
                  is_bot: checkbot.data.game,





                }
                try {
                  const response = await submitGameChallenge(submitGameData)
                  console.log(response)
                  navigate("/play-game")
                } catch (e) {
                  console.log(e)

                }
                //             {
                //               "_id": "662895e012ef1ef3d43e740d",
                //     "user_id": "6617c02dc9b3477c9914acea",
                //     "u_name": "Yogesh",
                //     "lat": "N/A",
                //     "lang": "N/A",
                //     "o_name": "Testuser",
                //     "entry_fee": 500,
                //     "prize_money": 950,
                //     "game_status": 3,
                //     "status": "accepted",
                //     "opponent_id": "6618cb5d4734b0c3d3524c18",
                //     "game_id": "65bf89584628069304705ddc",
                //     "room_code": "0",
                //     "r_upload": 0,
                //     "c_upload": 0,
                //     "o_upload": 0,
                //     "game_type": "web",
                //     "challenge_tag": "small",
                //     "createdAt": "2024-04-24T05:17:20.507Z",
                //     "updatedAt": "2024-04-24T05:17:34.544Z",
                //     "__v": 0
                // }
              }
              catch (e) {
                console.log(e)
              }
            }
          }
          }
        >
          Start
        </button>
        <button
          className="px-2 py-1 border rounded-md  text-white bg-red-500 "
          // eslint-disable-next-line no-unused-vars
          onClick={(e) => {
            socket.emit("gameChallenge:requestDecline", {
              type: "CHALLENGE_REQUEST_DECLINE",
              params: "",
              data: {
                auth: localStorage.getItem("token"),

                challenge_id: challenge_id,
              },
            });
          }}
        >
          Decline
        </button>
      </div>
    </div>
  );
}

WebOnevsOneCard.propTypes = {
  socket: PropTypes.any,
  challenge_id: PropTypes.string,
  prize: PropTypes.any,
  o_name: PropTypes.string,
  username: PropTypes.string,
};
