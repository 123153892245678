import React, { useState, useEffect } from 'react';
import { isJWT } from '../utils/checkJWT';
import Landing from "../pages/Landing Page/Landing";
import Home from '../pages/Home/Home';
import PropTypes from 'prop-types';

// Helper function to check if a string is a JWT
// const isJWT = (token) => {
//   const parts = token.split('.');
//   return parts.length === 3;
// };

const AuthWrapper = ({ socket }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem('token'));
    };

    // Listen for changes in localStorage (for example, token changes)
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  if (token && isJWT(token)) {
    return <Home socket={socket} />;
  } else {
    return <Landing />;
  }
};

AuthWrapper.propTypes = {
  socket: PropTypes.any
};

export default AuthWrapper;
