import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import Header from "../parts/Headers/Header";
import RightContainer from "../components/Common/RightContainer";

import RouteWatcher from "../components/RouteWatcher";

const ProtectedRoutes = () => {
  // TODO: Use authentication token

  const localStorageToken = localStorage.getItem("token");

  return localStorageToken ? (
    <>
      <Header></Header>{" "}
      <RouteWatcher></RouteWatcher>
      <div className="flex gap-2">
        <div className="w-[100vw] md:w-[38%] h-[100%] bg-white">

          <Outlet />{" "}

        </div>

        <RightContainer></RightContainer>
      </div>
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export default ProtectedRoutes;
