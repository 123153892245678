import PropTypes from 'prop-types';
import { Flip } from "react-toastify";
import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchWallet, userProfile } from '../../services/user.service';
import cup from "../../assets/images/cup.png";
import { appSettings } from '../../services/auth.service';

const notify = (message) =>
    toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
    });

export default function AppRoomList({ socket, entry, available, gameCount }) {
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const timeoutRef = useRef(null);

    const handleClick = () => {
        // Your handleClick logic
    };

    const handleCancelClick = () => {

        socket.emit("smallCancelChallenge", {
            type: "WALLET_BALANCE",
            params: "",
            data: {
                auth: localStorage.getItem("token"),
                amount: 50
            },
        });
        setIsDisabled(false);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    const handleButtonClick = async () => {
        setIsDisabled(true);
        console.log("Clicked");

        const appSettingsData = await appSettings();
        const allowed_number = ["9166371779"];
        let answer = false;

        if (appSettingsData.data.game_card_app == 1) {
            handleClick();
            const userProfileData = await userProfile();
            answer = allowed_number.includes(userProfileData.data.mobile);
        }

        if ((available && appSettingsData.data.game_card_app == 1 && answer) || (available && appSettingsData.data.game_card_app == 0)) {
            handleClick();


            const wallet = await fetchWallet();
            if (wallet.status === 401) {
                navigate("/login");
                localStorage.clear();
            } else if (wallet.status === 200) {
                if (wallet.data.wallet_balance >= 50) {
                    socket.emit("smallCreateOrJoinChallenge", {
                        type: "CHALLENGE_REQUESTED",
                        params: "",
                        data: { amount: parseInt(entry), auth: localStorage.getItem("token") },
                    });
                } else {
                    notify("Insufficient Funds");
                    setIsDisabled(false);
                }
            } else {
                notify("Error fetching wallet");
                setIsDisabled(false);
            }
        } else {
            setIsDisabled(false);
            notify("Under Maintenance");
        }

        // Reset the state after 30 seconds if request is still pending
        timeoutRef.current = setTimeout(() => {
            setIsDisabled(false);
        }, 30000);
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            handleCancelClick();
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        return () => {
            handleCancelClick();
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [location]);

    return (
        <div className="border rounded mb-3">
            <div className="px-4 pb-2 bg-[#F7F7F7] flex justify-between items-center">
                <p className="mt-2 font-custom text-sm">Currently Playing: {gameCount}</p>
            </div>
            <div className="p-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <img src={cup} alt="" className="w-6" />
                    <span className="text-green-700 font-custom font-semibold text-md">Rs {entry}</span>
                </div>
                <button
                    className={`px-3 py-1 border text-xs font-custom rounded-md text-white ${isDisabled ? 'bg-[#82624f]' : 'bg-[#3b2c23]'}`}
                    onClick={isDisabled ? handleCancelClick : handleButtonClick}
                >
                    {isDisabled && entry == 50 ? 'Requested' : 'Play'}
                </button>
            </div>
        </div>
    );
}

AppRoomList.propTypes = {
    socket: PropTypes.any.isRequired,
    entry: PropTypes.any.isRequired,
    gameCount: PropTypes.any.isRequired,
    available: PropTypes.any.isRequired
};
